import { flattenToAppURL } from '@plone/volto/helpers';

export const NEWSLETTER_SUBSCRIBE = 'NEWSLETTER_SUBSCRIBE';
export const NEWSLETTER_CONFIRM = 'NEWSLETTER_CONFIRM';
export const NEWSLETTER_UNSUBSCRIBE = 'NEWSLETTER_UNSUBSCRIBE';
export const NEWSLETTER_SEND = 'NEWSLETTER_SEND';
export const NEWSLETTER_HISTORY = 'NEWSLETTER_HISTORY';

export function subscribe(newsletterUrl, data) {
  return {
    type: NEWSLETTER_SUBSCRIBE,
    request: {
      op: 'post',
      path: `${flattenToAppURL(newsletterUrl)}/@subscriptions`,
      data,
    },
  };
}

export function confirm(newsletterUrl, token) {
  return {
    type: NEWSLETTER_CONFIRM,
    request: {
      op: 'put',
      path: `${flattenToAppURL(newsletterUrl)}/@subscriptions`,
      data: { token },
    },
  };
}

export function unsubscribe(newsletterUrl, sub_uuid) {
  return {
    type: NEWSLETTER_UNSUBSCRIBE,
    request: {
      op: 'del',
      path: `${flattenToAppURL(newsletterUrl)}/@subscriptions`,
      data: { sub_uuid },
    },
  };
}

// unsubscribe using old uuid from CXXL
export function legacyUnsubscribe(sub_uuid) {
  return {
    type: NEWSLETTER_UNSUBSCRIBE,
    request: {
      op: 'del',
      path: '/@cxxl-subscriptions',
      data: { sub_uuid },
    },
  };
}

export function send(newsletterUrl, data) {
  return {
    type: NEWSLETTER_SEND,
    request: {
      op: 'post',
      path: `${flattenToAppURL(newsletterUrl)}/@mailings`,
      data,
    },
  };
}

export function listMailings(contentUrl) {
  return {
    type: NEWSLETTER_HISTORY,
    request: {
      op: 'get',
      path: `${flattenToAppURL(
        contentUrl,
      )}/@mailings?based_on=${flattenToAppURL(contentUrl)}`,
    },
  };
}

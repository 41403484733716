/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import config from '@plone/volto/registry';
import PortalFooter from 'volto-dlr/components/Footer/PortalFooter';
import PortalFooterBottom from 'volto-dlr/components/Footer/PortalFooterBottom';
import PreFooterNewsItem from 'volto-dlr/components/Footer/PreFooterNewsItem';
import { isEmpty } from 'lodash';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

const NonContentRoutesCheck = (route, nonContentRoutes) => {
  for (const item of nonContentRoutes) {
    if (typeof item === 'string' && item === route) {
      return true;
    }
    const regex = new RegExp(item);
    if (regex.test(route)) {
      return true;
    }
  }

  return false;
};
const Footer = (props) => {
  const content = useSelector((state) => state.content.data);
  const location = useLocation();
  const { settings } = config;
  const isNonContentRoute = NonContentRoutesCheck(
    location.pathname,
    settings.nonContentRoutes,
  );
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot);
  const isSubsite = !isEmpty(iNavRootInfo.subsite);

  return (
    <footer id="footer">
      {!isNonContentRoute &&
      !isSubsite &&
      content?.['@type'] === 'News Item' ? (
        <PreFooterNewsItem content={content} />
      ) : null}

      <PortalFooter />
      <PortalFooterBottom />
    </footer>
  );
};

export default Footer;

import React from 'react';
import { Message, Grid } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import ContactBody from './Body';
import { withBlockExtensions } from '@plone/volto/helpers';
import cx from 'classnames';

const messages = defineMessages({
  PleaseChooseContact: {
    id: 'Please choose an existing contact as source for this element',
    defaultMessage:
      'Please choose an existing contact as source for this element',
  },
});

const ContactListView = (props) => {
  const { className, data, content, isEditMode } = props;
  const intl = useIntl();
  return (
    <div className={cx('block contact-list', className)}>
      <div className="block-container">
        {(data.hrefList?.length === 0 || !data.hrefList) && isEditMode && (
          <Message>
            <div className="teaser-item default">
              <p>{intl.formatMessage(messages.PleaseChooseContact)}</p>
            </div>
          </Message>
        )}
        {data.hrefList?.length > 0 && (
          <>
            {data.headline && <h2 className="headline">{data.headline}</h2>}
            <Grid stackable columns="2">
              <Grid.Row>
                {data.hrefList &&
                  data.hrefList.map((item, index) => (
                    <Grid.Column key={index}>
                      <ContactBody
                        key={item.id}
                        data={item}
                        content={content}
                        isEditMode={isEditMode}
                      />
                    </Grid.Column>
                  ))}
              </Grid.Row>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};

export default withBlockExtensions(ContactListView);

import { getContent } from '@plone/volto/actions';

const repeatField = (n, field, subfield) =>
  [...Array(n).keys()].map((n) => field + '_' + n + '_' + subfield);

const ProjectFactsheetDataAdapter = ({
  block,
  data,
  id,
  onChangeBlock,
  value,
  dispatch,
}) => {
  let dataSaved = { ...data };

  if (id === 'href' && data[id] !== value) {
    // Retrieve project details via the API after a project is selected.
    if (value && value[0]?.['@id']) {
      const href = value[0]['@id'];
      dispatch(getContent(href, null, block)).then((resp) => {
        const project = {};
        [
          'title',
          'project_goal',
          'start',
          'mission_start_date',
          'end',
          'logo',
          'remoteUrl',
          ...repeatField(15, 'participant', 'text'),
          ...repeatField(15, 'participant', 'logo'),
          ...repeatField(15, 'participant', 'href'),
          ...repeatField(10, 'sponsor', 'text'),
          ...repeatField(10, 'sponsor', 'text'),
          ...repeatField(10, 'sponsor', 'text'),
          'funding_type',
        ].map((n) => (project[n] = resp[n]));
        onChangeBlock(block, {
          ...data,
          href: [{ '@id': href, title: resp.title }],
          project,
        });
      });
    } else {
      // href cleared; clear project data
      delete dataSaved.href;
      delete dataSaved.project;
    }
  } else {
    dataSaved[id] = value;
  }
  onChangeBlock(block, dataSaved);
};

export default ProjectFactsheetDataAdapter;

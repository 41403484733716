import React from 'react';
import { Link } from 'react-router-dom';
import ResultItemContentTypeInGroup from './helpers/ResultItemContentTypeInGroup';

const ImageResultItem = ({ item, groupSelect }) => (
  <article className="tileItem">
    <ResultItemContentTypeInGroup item={item} groupSelect={groupSelect} />
    <h2 className="tileHeadline">
      <Link to={item['@id']} className="summary url" title={item['@type']}>
        {item.title}
      </Link>
    </h2>
    <Link to={item['@id']}>
      <img
        className="previewImage"
        src={`${item['@id']}/@@images/image/preview`}
        alt={item.title}
      />
    </Link>
    {item?.highlighting && item.highlighting.length > 0 ? (
      <div className="tileBody">
        <span
          className="description"
          dangerouslySetInnerHTML={{
            __html: item.highlighting,
          }}
        />
        {' ...'}
      </div>
    ) : (
      <div className="tileBody">
        <span className="description">
          {item.description
            ? item.description.length > 200
              ? item.description.slice(0, 199) + '...'
              : item.description
            : ''}
        </span>
      </div>
    )}
    <div className="visualClear" />
    {/* END CUSTOMIZATION */}
  </article>
);

export default ImageResultItem;

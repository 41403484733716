import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import PropTypes from 'prop-types';

/**
 * TagView view component class.
 * @function TagView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const TagView = ({ content }) => {
  return (
    <div id="page-document" className="ui container view-wrapper tag-view">
      <RenderBlocks content={content} />
      <div className="blog-footer">
        <div className="footer-wrapper">
          <RenderBlocks
            content={{
              blocks: {
                'dcdf1f42-645d-48f6-9531-357bdc2e1881': {
                  '@type': 'search',
                  listingBodyTemplate: 'blogListing',
                  query: {
                    query: [
                      {
                        i: 'blog_tags',
                        o: 'plone.app.querystring.operation.selection.any',
                        v: [`${content?.UID}`],
                      },
                      {
                        i: 'portal_type',
                        o: 'plone.app.querystring.operation.selection.any',
                        v: ['Post'],
                      },
                    ],
                    sort_on: 'effective',
                    sort_order: 'descending',
                  },
                  showSearchInput: true,
                },
              },
              blocks_layout: {
                items: ['dcdf1f42-645d-48f6-9531-357bdc2e1881'],
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TagView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default TagView;

import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  FurtherPages: {
    // Sonstige Seite
    id: 'Further Pages',
    defaultMessage: 'Further Pages',
  },
  NewsItem: {
    // Nachricht
    id: 'News Item',
    defaultMessage: 'News Item',
  },
  Event: {
    // Termin
    id: 'Event',
    defaultMessage: 'Event',
  },
  ProjectMission: {
    // Projekt / Mission
    id: 'Project / Mission',
    defaultMessage: 'Project / Mission',
  },
  ResearchTopic: {
    // Thema
    id: 'Research Topic',
    defaultMessage: 'Featured Topic',
  },
  ResearchInfrastructure: {
    // Forschungsinfrastruktur
    id: 'Research Infrastructure',
    defaultMessage: 'Research Infrastructure',
  },
  Location: {
    // Standort
    id: 'Location',
    defaultMessage: 'Location',
  },
  Video: {
    // Video
    id: 'Video',
    defaultMessage: 'Video',
  },
  Audio: {
    // Audio
    id: 'Audio',
    defaultMessage: 'Audio',
  },
  Image: {
    // Bild
    id: 'Image',
    defaultMessage: 'Image',
  },
  Download: {
    // Download
    id: 'Download',
    defaultMessage: 'Download',
  },
});

const getContentTypeMessage = (contentType) => {
  switch (contentType) {
    case 'Page':
      return messages.FurtherPages;
    case 'News Item':
      return messages.NewsItem;
    case 'Event':
      return messages.Event;
    case 'Research Project':
      return messages.ProjectMission;
    case 'Research Topic':
      return messages.ResearchTopic;
    case 'Research Infrastructure':
      return messages.ResearchInfrastructure;
    case 'Location':
      return messages.Location;
    case 'Video':
      return messages.Video;
    case 'Audio':
      return messages.Audio;
    case 'Image':
      return messages.Image;
    case 'File':
      return messages.Download;
    default:
      return { id: contentType, defaultMessage: contentType };
  }
};

const ResultItemContentType = ({ item }) => {
  const intl = useIntl();
  const contentTypeMessage = getContentTypeMessage(item['@type']);
  return contentTypeMessage ? (
    <span className="contentTypeInlineLabel">
      {intl.formatMessage(contentTypeMessage)}
    </span>
  ) : null;
};

export default ResultItemContentType;

/**
 * OVERRIDE ObjectBrowserBody.jsx
 * REASON: Implement DAMS
 * FILE: https://github.com/plone/volto/blob/611201d76cdb83ced86d22e38bb5fdfd848a739c/src/components/manage/Sidebar/ObjectBrowserBody.jsx
 * FILE VERSION: Volto 16.0.0-alpha.48
 * DATE: 2022-11-07
 * DEVELOPER: @robgietema

 * OVERRIDE Volto ObjectBrowserBody.jsx
 * REASON: Solarize sidebar search
 * FILE: https://github.com/plone/volto/blob/master/src/components/manage/Sidebar/ObjectBrowserBody.jsx
 * FILE VERSION: Volto 15.11.1
 * PULL REQUEST: https://github.com/kitconcept/fzj-internet/pull/1340
 * TICKET: https://jugit.fz-juelich.de/fzj-internet/dlr/-/issues/1046
 * DATE: 2022-05-30
 * DEVELOPER: @reebalazs
 * CHANGELOG:
 *  - Solarize sidebar search (#1046) @reebalazs
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
// START CUSTOMIZATION
import { Radio, Input, Segment, Breadcrumb } from 'semantic-ui-react';
// END CUSTOMIZATION

import { join } from 'lodash';

// These absolute imports (without using the corresponding centralized index.js) are required
// to cut circular import problems, this file should never use them. This is because of
// the very nature of the functionality of the component and its relationship with others
import {
  searchContent,
  resetSearchContent,
} from '@plone/volto/actions/search/search';
// START CUSTOMIZATION
import {
  solrSearchContent,
  resetSolrSearchContent,
} from '../../../../../actions';
// END CUSTOMIZATION
import Icon from '@plone/volto/components/theme/Icon/Icon';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers/Url/Url';
import config from '@plone/volto/registry';

import backSVG from '@plone/volto/icons/back.svg';
import folderSVG from '@plone/volto/icons/folder.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import searchSVG from '@plone/volto/icons/zoom.svg';
import linkSVG from '@plone/volto/icons/link.svg';
import homeSVG from '@plone/volto/icons/home.svg';
// START CUSTOMIZATION
import iconsSVG from '@plone/volto/icons/apps.svg';
import listSVG from '@plone/volto/icons/list-bullet.svg';
// END CUSTOMIZATION

import ObjectBrowserNav from '@plone/volto/components/manage/Sidebar/ObjectBrowserNav';

const messages = defineMessages({
  SearchInputPlaceholder: {
    id: 'Search content',
    defaultMessage: 'Search content',
  },
  SelectedItems: {
    id: 'Selected items',
    defaultMessage: 'Selected items',
  },
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  search: {
    id: 'Search SVG',
    defaultMessage: 'Search SVG',
  },
  of: { id: 'Selected items - x of y', defaultMessage: 'of' },
  // START CUSTOMIZATION
  allImages: {
    id: 'Show / search all images (global)',
    defaultMessage: 'Show / search all images (global)',
  },
  // END CUSTOMIZATION
});

// START CUSTOMIZATION
const solrSearchAction = solrSearchContent;
const getSolrSearchReducer = (state) => state.solrsearch;
// END CUSTOMIZATION

function getParentURL(url) {
  return flattenToAppURL(`${join(url.split('/').slice(0, -1), '/')}`) || '/';
}

/**
 * ObjectBrowserBody container class.
 * @class ObjectBrowserBody
 * @extends Component
 */
class ObjectBrowserBody extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    block: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    data: PropTypes.any.isRequired,
    searchSubrequests: PropTypes.objectOf(PropTypes.any).isRequired,
    searchContent: PropTypes.func.isRequired,
    closeObjectBrowser: PropTypes.func.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func,
    dataName: PropTypes.string,
    maximumSelectionSize: PropTypes.number,
    contextURL: PropTypes.string,
    searchableTypes: PropTypes.arrayOf(PropTypes.string),
    view: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    image: '',
    href: '',
    onSelectItem: null,
    dataName: null,
    selectableTypes: [],
    searchableTypes: null,
    maximumSelectionSize: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      currentFolder:
        this.props.mode === 'multiple' ? '/' : this.props.contextURL || '/',
      currentImageFolder:
        this.props.mode === 'multiple'
          ? '/'
          : this.props.mode === 'image' && this.props.data?.url
          ? getParentURL(this.props.data.url)
          : '/',
      currentLinkFolder:
        this.props.mode === 'multiple'
          ? '/'
          : this.props.mode === 'link' && this.props.data?.href
          ? getParentURL(this.props.data.href)
          : '/',
      parentFolder: '',
      selectedImage:
        this.props.mode === 'multiple'
          ? ''
          : this.props.mode === 'image' && this.props.data?.url
          ? flattenToAppURL(this.props.data.url)
          : '',
      selectedHref:
        this.props.mode === 'multiple'
          ? ''
          : this.props.mode === 'link' && this.props.data?.href
          ? flattenToAppURL(this.props.data.href)
          : '',
      showSearchInput: false,
      // In image mode, the searchable types default to the image types which
      // can be overridden with the property if specified.
      searchableTypes:
        this.props.mode === 'image'
          ? this.props.searchableTypes || config.settings.imageObjects
          : this.props.searchableTypes || this.props.selectableTypes,
      // START CUSTOMIZATION
      view: this.props.view || (this.props.mode === 'image' ? 'icons' : 'list'),
      allImages: false,
      serial: 0,
      isSolrSearch: false,
      searchTerm: '',
      // END CUSTOMIZATION
    };
    this.searchInputRef = React.createRef();
  }

  // START CUSTOMIZATION
  toggleAllImages = () =>
    this.setState(
      {
        allImages: !this.state.allImages,
      },
      () => this.doSearch(),
    );
  // END CUSTOMIZATION

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.initialSearch(this.props.mode);
  }

  initialSearch = (mode) => {
    const currentSelected =
      mode === 'multiple'
        ? ''
        : mode === 'image'
        ? this.state.selectedImage
        : this.state.selectedHref;
    if (currentSelected && isInternalURL(currentSelected)) {
      this.props.searchContent(
        getParentURL(currentSelected),
        {
          'path.depth': 1,
          sort_on: this.props.sortOn || 'getObjPositionInParent',
          metadata_fields: '_all',
          b_size: 1000,
        },
        `${this.props.block}-${mode}-${this.state.serial}`,
      );
      // START CUSTOMIZATION
      this.setState({ isSolrSearch: false });
      // END CUSTOMIZATION
    } else {
      this.props.searchContent(
        this.pathPrefix(),
        {
          'path.depth': 1,
          sort_on: this.props.sortOn || 'getObjPositionInParent',
          metadata_fields: '_all',
          b_size: 1000,
        },
        `${this.props.block}-${mode}-${this.state.serial}`,
      );
      // START CUSTOMIZATION
      this.setState({ isSolrSearch: false });
      // END CUSTOMIZATION
    }
    // START CUSTOMIZATION
    this.cleanupOldSubrequests();
    // END CUSTOMIZATION
  };

  // START CUSTOMIZATION
  KEEP_RESULTS = 30 * 1000;

  _cleanupOldSearchSubrequests = (searchSubrequests, resetSearchContent) => {
    const now = new Date().valueOf();
    const keys = Object.keys(searchSubrequests || {});
    for (const key of keys) {
      const ts = parseInt(key.match(/-([0-9]+)$/)?.[1]);
      if (!isNaN(ts) && now - ts > this.KEEP_RESULTS) {
        resetSearchContent(key);
      }
    }
  };

  cleanupOldSubrequests = () => {
    this._cleanupOldSearchSubrequests(
      this.props.searchSubrequests,
      this.props.resetSearchContent,
    );
    this._cleanupOldSearchSubrequests(
      this.props.solrSearchSubrequests,
      this.props.resetSolrSearchContent,
    );
  };

  // END CUSTOMIZATION

  navigateTo = (id) => {
    this.props.searchContent(
      id,
      {
        'path.depth': 1,
        sort_on: this.props.sortOn || 'getObjPositionInParent',
        metadata_fields: '_all',
        b_size: 1000,
      },
      `${this.props.block}-${this.props.mode}-${this.state.serial}`,
    );
    const parent = `${join(id.split('/').slice(0, -1), '/')}` || '/';
    this.setState(() => ({
      parentFolder: parent,
      currentFolder: id || '/',
      // START CUSTOMIZATION
      isSolrSearch: false,
      // END CUSTOMIZATION
    }));
  };

  // START CUSTOMIZATION
  toggleSearchInput = () =>
    this.setState(
      (prevState) => ({
        showSearchInput: !prevState.showSearchInput,
      }),
      () => {
        if (this.searchInputRef?.current) {
          this.searchInputRef.current.focus();
        } else {
          this.setState({ searchTerm: '' }, () => this.doSearch());
        }
      },
    );
  // END CUSTOMIZATION

  // START CUSTOMIZATION
  toggleView = () =>
    this.setState((prevState) => ({
      view: prevState.view === 'icons' ? 'list' : 'icons',
    }));
  // END CUSTOMIZATION

  onSearch = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm }, () => this.doSearch());
  };

  // Path prefix is applied differently for a term search
  // and a term-less (initial) search. This might be inconsistent,
  // but it follows the behavior of the current system.

  pathPrefixForTermSearch = () =>
    // When the user has entered a search term > 3 characters,
    // we always search in the language root folder
    // Except: when there is an image search,
    // and a local image search is selected
    // (the radio box is shown and allImages = false)
    // in which case we search in the current context folder.
    (this.props.view || this.props.mode === 'image') & !this.state.allImages
      ? this.state.currentFolder
      : `/${this.props.lang}`;

  pathPrefix = () =>
    // When there is no search term (or a term < 3 characters)
    // we show the contents of the current context folder.
    // Except: when there is an image search,
    // and a global image search is selected
    // (the radio box is shown and allImages = true)
    // in which case we search in the language root folder
    (this.props.view || this.props.mode === 'image') & this.state.allImages
      ? `/${this.props.lang}`
      : this.state.currentFolder;

  doSearch = () => {
    const value = this.state.searchTerm;
    // Searches of length 0 and 1 will give all results from catalog
    if (value.length <= 2) {
      // if we delete back, we fall back to the initial search
      return this.initialSearch(this.props.mode);
    }
    const text = flattenToAppURL(value);
    const serial = new Date().valueOf();
    this.setState({ serial });
    if (text.startsWith('/')) {
      this.setState({ currentFolder: text });
      this.props.searchContent(
        text,
        {
          'path.depth': 1,
          sort_on: this.props.sortOn || 'getObjPositionInParent',
          metadata_fields: '_all',
          portal_type: this.state.searchableTypes,
        },
        `${this.props.block}-${this.props.mode}-${serial}`,
      );
      // START CUSTOMIZATION
      this.setState({ isSolrSearch: false });
      // END CUSTOMIZATION
    } else {
      /// START CUSTOMIZATION
      text.length > 1
        ? this.props.solrSearchContent(
            this.pathPrefixForTermSearch(),
            {
              SearchableText: text,
              metadata_fields: '_all',
              portal_type: this.state.searchableTypes,
              // Search only in the language root folder,
              // and switch off including translation folders
              // Exception when allImages is false, in which case
              // use the currentFolder prefix.
              path_prefix: this.pathPrefixForTermSearch(),
              local: `true`,
              is_multilingual: 'false',
              sort_on: this.props.sortOn || undefined,
              // title only search
              in_title: 'true',
              context: 'sidebar',
            },
            `${this.props.block}-${this.props.mode}-${serial}`,
          )
        : this.props.searchContent(
            this.pathPrefix(),
            {
              'path.depth': 1,
              sort_on: this.props.sortOn || 'getObjPositionInParent',
              metadata_fields: '_all',
              portal_type: this.state.searchableTypes,
            },
            `${this.props.block}-${this.props.mode}-${serial}`,
          );
      this.setState({ isSolrSearch: text.length > 1 });
      // END CUSTOMIZATION
    }
  };

  onSelectItem = (item) => {
    const url = item['@id'];
    const { block, data, mode, dataName, onChangeBlock } = this.props;

    const updateState = (mode) => {
      switch (mode) {
        case 'image':
          this.setState({
            selectedImage: url,
            currentImageFolder: getParentURL(url),
          });
          break;
        case 'link':
          this.setState({
            selectedHref: url,
            currentLinkFolder: getParentURL(url),
          });
          break;
        default:
          break;
      }
    };

    // Convert from solr data as needed
    const imageScales = item.extras?.image_scales;
    if (typeof imageScales === 'string') {
      try {
        item['image_scales'] = JSON.parse(imageScales);
      } catch (e) {
        // XXX Temporary hack, can be removed when the JSON
        // is properly fixed.
        // eslint-disable-next-line no-console
        console.warn(
          `Bad JSON syntax at image_scales, trying to fix [${imageScales}])`,
        );
        const fixedImageScales = imageScales.replace(/'/g, '"');
        try {
          item['image_scales'] = JSON.parse(fixedImageScales);
        } catch (e2) {
          // Just do nothing, if the image scales payload is broken
          // eslint-disable-next-line no-console
          console.warn(
            `Bad JSON syntax at image_scales, ignoring it [${fixedImageScales}])`,
          );
        }
      }
    }
    if (!item.hasOwnProperty('Title')) {
      item.Title = item.title;
    }
    if (!item.hasOwnProperty('Description')) {
      item.Description = item.description;
    }
    if (!item.hasOwnProperty('mime_type')) {
      item.mime_type = item.extras.mime_type;
    }
    if (!item.hasOwnProperty('getObjSize')) {
      item.getObjSize = item.extras.getObjSize;
    }
    if (!item.hasOwnProperty('getRemoteUrl')) {
      item.getRemoteUrl = item.extras.getRemoteUrl;
    }
    if (dataName) {
      onChangeBlock(block, {
        ...data,
        [dataName]: url,
      });
    } else if (this.props.onSelectItem) {
      this.props.onSelectItem(url, item);
    } else if (mode === 'image') {
      onChangeBlock(block, {
        ...data,
        url: flattenToAppURL(item.getURL),
        alt: '',
      });
    } else if (mode === 'link') {
      onChangeBlock(block, {
        ...data,
        href: flattenToAppURL(url),
      });
    }
    updateState(mode);
  };

  onChangeBlockData = (key, value) => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      [key]: value,
    });
  };

  isSelectable = (item) => {
    return this.props.selectableTypes.length > 0
      ? this.props.selectableTypes.indexOf(item['@type']) >= 0
      : true;
  };

  handleClickOnItem = (item) => {
    if (this.props.mode === 'image') {
      if (item.is_folderish) {
        this.navigateTo(item['@id']);
      }
      if (config.settings.imageObjects.includes(item['@type'])) {
        this.onSelectItem(item);
      }
    } else {
      if (this.isSelectable(item)) {
        if (
          !this.props.maximumSelectionSize ||
          this.props.mode === 'multiple' ||
          !this.props.data ||
          this.props.data.length < this.props.maximumSelectionSize
        ) {
          this.onSelectItem(item);
          let length = this.props.data ? this.props.data.length : 0;

          let stopSelecting =
            this.props.mode !== 'multiple' ||
            (this.props.maximumSelectionSize > 0 &&
              length + 1 >= this.props.maximumSelectionSize);

          if (stopSelecting) {
            this.props.closeObjectBrowser();
          }
        } else {
          this.props.closeObjectBrowser();
        }
      } else {
        this.navigateTo(item['@id']);
      }
    }
  };

  handleDoubleClickOnItem = (item) => {
    if (this.props.mode === 'image') {
      if (item.is_folderish) {
        this.navigateTo(item['@id']);
      }
      if (config.settings.imageObjects.includes(item['@type'])) {
        this.onSelectItem(item);
        this.props.closeObjectBrowser();
      }
    } else {
      if (this.isSelectable(item)) {
        if (this.props.data.length < this.props.maximumSelectionSize) {
          this.onSelectItem(item);
        }
        this.props.closeObjectBrowser();
      } else {
        this.navigateTo(item['@id']);
      }
    }
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      // START CUSTOMIZATION
      <Segment.Group raised className="object-browser">
        {/* END CUSTOMIZATION */}
        <header className="header pulled">
          <div className="vertical divider" />
          {/* START CUSTOMIZATION */}
          {this.state.showSearchInput ? (
            <Input
              className="search"
              ref={this.searchInputRef}
              onChange={this.onSearch}
              placeholder={this.props.intl.formatMessage(
                messages.SearchInputPlaceholder,
              )}
            />
          ) : (
            <>
              {this.state.currentFolder === '/' ? (
                <>
                  {this.props.mode === 'image' ? (
                    <Icon name={folderSVG} size="24px" />
                  ) : (
                    <Icon name={linkSVG} size="24px" />
                  )}
                </>
              ) : (
                <button
                  aria-label={this.props.intl.formatMessage(messages.back)}
                  onClick={() => this.navigateTo(this.state.parentFolder)}
                >
                  <Icon name={backSVG} size="24px" />
                </button>
              )}
              {this.props.mode === 'image' ? (
                <h2>
                  <FormattedMessage
                    id="Choose Image"
                    defaultMessage="Choose Image"
                  />
                </h2>
              ) : (
                <h2>
                  <FormattedMessage
                    id="Choose Target"
                    defaultMessage="Choose Target"
                  />
                </h2>
              )}
            </>
          )}
          {/* END CUSTOMIZATION */}

          <button
            aria-label={this.props.intl.formatMessage(messages.search)}
            onClick={this.toggleSearchInput}
          >
            <Icon name={searchSVG} size="24px" />
          </button>
          <button className="clearSVG" onClick={this.props.closeObjectBrowser}>
            <Icon name={clearSVG} size="24px" />
          </button>
        </header>
        <Segment secondary className="breadcrumbs" vertical>
          {/* START CUSTOMIZATION */}
          {this.props.view || this.props.mode === 'image' ? (
            <>
              <div>
                <Radio
                  value="global"
                  name="allImages"
                  checked={this.state.allImages}
                  onChange={this.toggleAllImages}
                  label={this.props.intl.formatMessage(messages.allImages)}
                />
              </div>
              <div className="all-images-local">
                <Icon
                  name={this.state.view === 'list' ? iconsSVG : listSVG}
                  size="24px"
                  className="mode-switch"
                  onClick={this.toggleView}
                />
                <Radio
                  value="local"
                  name="allImages"
                  checked={!this.state.allImages}
                  onChange={this.toggleAllImages}
                />
                <Breadcrumb className="images">
                  <div className="inner">
                    {this.state.currentFolder !== '/' ? (
                      this.state.currentFolder
                        .split('/')
                        .map((item, index, items) => {
                          return (
                            <React.Fragment key={`divider-${item}-${index}`}>
                              {index === 0 ? (
                                <Breadcrumb.Section
                                  onClick={() => this.navigateTo('/')}
                                >
                                  <Icon
                                    className="home-icon"
                                    name={homeSVG}
                                    size="18px"
                                  />
                                </Breadcrumb.Section>
                              ) : (
                                <>
                                  <Breadcrumb.Divider
                                    key={`divider-${item.url}`}
                                  />
                                  <Breadcrumb.Section
                                    onClick={() =>
                                      this.navigateTo(
                                        items.slice(0, index + 1).join('/'),
                                      )
                                    }
                                  >
                                    {item}
                                  </Breadcrumb.Section>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })
                    ) : (
                      <Breadcrumb.Section onClick={() => this.navigateTo('/')}>
                        <Icon
                          className="home-icon"
                          name={homeSVG}
                          size="18px"
                        />
                      </Breadcrumb.Section>
                    )}
                  </div>
                </Breadcrumb>
              </div>
            </>
          ) : (
            <Breadcrumb>
              <div className="inner">
                {this.state.currentFolder !== '/' ? (
                  this.state.currentFolder
                    .split('/')
                    .map((item, index, items) => {
                      return (
                        <React.Fragment key={`divider-${item}-${index}`}>
                          {index === 0 ? (
                            <Breadcrumb.Section
                              onClick={() => this.navigateTo('/')}
                            >
                              <Icon
                                className="home-icon"
                                name={homeSVG}
                                size="18px"
                              />
                            </Breadcrumb.Section>
                          ) : (
                            <>
                              <Breadcrumb.Divider key={`divider-${item.url}`} />
                              <Breadcrumb.Section
                                onClick={() =>
                                  this.navigateTo(
                                    items.slice(0, index + 1).join('/'),
                                  )
                                }
                              >
                                {item}
                              </Breadcrumb.Section>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })
                ) : (
                  <Breadcrumb.Section onClick={() => this.navigateTo('/')}>
                    <Icon className="home-icon" name={homeSVG} size="18px" />
                  </Breadcrumb.Section>
                )}
              </div>
            </Breadcrumb>
          )}
          {/* END CUSTOMIZATION */}
        </Segment>
        {this.props.mode === 'multiple' && (
          <Segment className="infos">
            {this.props.intl.formatMessage(messages.SelectedItems)}:{' '}
            {this.props.data?.length}
            {this.props.maximumSelectionSize > 0 && (
              <>
                {' '}
                {this.props.intl.formatMessage(messages.of)}{' '}
                {this.props.maximumSelectionSize}
              </>
            )}
          </Segment>
        )}
        <ObjectBrowserNav
          currentSearchResults={
            // START CUSTOMIZATION
            (this.state.isSolrSearch
              ? this.props.solrSearchSubrequests
              : this.props.searchSubrequests)[
              // END CUSTOMIZATION
              `${this.props.block}-${this.props.mode}-${this.state.serial}`
            ]
          }
          selected={
            this.props.mode === 'multiple'
              ? this.props.data
              : [
                  {
                    '@id':
                      this.props.mode === 'image'
                        ? this.state.selectedImage
                        : this.state.selectedHref,
                  },
                ]
          }
          handleClickOnItem={this.handleClickOnItem}
          handleDoubleClickOnItem={this.handleDoubleClickOnItem}
          mode={this.props.mode}
          // START CUSTOMIZATION
          view={this.state.view}
          // END CUSTOMIZATION
          navigateTo={this.navigateTo}
          isSelectable={this.isSelectable}
        />
      </Segment.Group>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      searchSubrequests: state.search.subrequests,
      // START CUSTOMIZATION
      lang: state.intl.locale,
      solrSearchSubrequests: getSolrSearchReducer(state).subrequests,
    }),
    (dispatch) => ({
      searchContent: (...args) => dispatch(searchContent(...args)),
      resetSearchContent,
      solrSearchContent: (...args) => dispatch(solrSearchAction(...args)),
      resetSolrSearchContent,
      // END CUSTOMIZATION
    }),
  ),
)(ObjectBrowserBody);

import { compact, isEmpty } from 'lodash';
import { difference, replaceItemOfArray } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';

export const ContactListDataAdapter = ({
  block,
  data,
  id,
  onChangeBlock,
  value,
  dispatch,
}) => {
  let dataSaved = {
    ...data,
    [id]: value,
  };

  // This code is for sync the value coming from the selected content
  // into the block's data (if the title and description are empty)
  if (id === 'hrefList' && !isEmpty(value)) {
    const diff = difference(value, data[id]);
    // If we are moving two items in the array, the changeset is > 1
    // then we do not do any sync
    const isReordering = compact(diff).length > 1;
    if (!isReordering) {
      const index = diff.findIndex((val) => val);
      const href = diff[index]?.href?.[0]?.['@id'];
      if (href) {
        dispatch(getContent(href, null, block)).then((resp) => {
          const itemData = { '@id': href };
          [
            'title',
            'academic',
            'salutation',
            'first_name',
            'last_name',
            'position',
            'company',
            'department',
            'institute',
            'is_official_press_contact',
            'house_number',
            'road',
            'city',
            'zipcode',
            'country',
            'phone',
          ].map((n) => (itemData[n] = resp[n]));
          itemData.has_email = !!resp.email;
          onChangeBlock(block, {
            ...data,
            hrefList: replaceItemOfArray(data.hrefList, index, {
              ...data.hrefList[index],
              href: [itemData],
            }),
          });
        });
      }
    }
  }
  onChangeBlock(block, dataSaved);
};

import { flattenToAppURL } from '@plone/volto/helpers';

export const restrictedExceptForSubsites = (subsites) => {
  const isRestricted = ({ properties, navRoot, contentType }) => {
    let restricted = true;
    const path =
      (flattenToAppURL(properties?.['@id']) ||
        flattenToAppURL(properties?.parent?.['@id'])) + '/';

    subsites.forEach((subsite) => {
      if (path.startsWith(subsite)) {
        restricted = false;
      }
    });

    return restricted;
  };

  return isRestricted;
};

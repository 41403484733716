import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Message, Button, Dimmer } from 'semantic-ui-react';
import { Helmet, getParentUrl } from '@plone/volto/helpers';
import { getFormTicket, submitSchoollabContactForm } from 'volto-dlr/actions';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { getContent } from '@plone/volto/actions';

const messages = defineMessages({
  helmet: {
    id: 'DLR - DLR_School_Lab - Visit request {location}',
    defaultMessage: 'DLR - DLR_School_Lab - Visit request {location}',
  },
  heading2: {
    id: 'Visit request DLR_School_Lab {location}',
    defaultMessage: 'Visit request DLR_School_Lab {location}',
  },
  contextText1: {
    id:
      'We are pleased that you are interested in visiting our DLR_School_Lab {location}.',
    defaultMessage:
      'We are pleased that you are interested in visiting our DLR_School_Lab {location}.',
  },
  contextText2: {
    id:
      'Please fill in the following fields to request a visit. As soon as we have checked the requested date, you will receive an email confirmation at the address provided.',
    defaultMessage:
      'Please fill in the following fields to request a visit. As soon as we have checked the requested date, you will receive an email confirmation at the address provided.',
  },
  privacy: {
    id: 'Privacy Policy',
    defaultMessage: 'Privacy Policy',
  },
  mandatory: {
    id: 'Mandatory fields',
    defaultMessage: 'Mandatory fields',
  },
  nameOftheSchool: {
    id: 'Name of the school',
    defaultMessage: 'Name of the school',
  },
  schoolOfType: {
    id: 'Type of school',
    defaultMessage: 'Type of school',
  },
  street: {
    id: 'Street',
    defaultMessage: 'Street',
  },
  houseOfNumber: {
    id: 'House number',
    defaultMessage: 'House number',
  },
  postCode: {
    id: 'Postcode',
    defaultMessage: 'Postcode',
  },
  city: {
    id: 'City',
    defaultMessage: 'City',
  },
  contactPerson: {
    id: 'Contact Person (surname, first name)',
    defaultMessage: 'Contact Person (surname, first name)',
  },
  email: {
    id: 'E-mail',
    defaultMessage: 'E-mail',
  },
  telephone: {
    id: 'Telephone number (optional):',
    defaultMessage: 'Telephone number (optional):',
  },
  dateOfrequested: {
    id: 'Date of requested visit (period)',
    defaultMessage: 'Date of requested visit (period)',
  },
  arrival: {
    id: 'Arrival time',
    defaultMessage: 'Arrival time',
  },
  departure: {
    id: 'Departure time',
    defaultMessage: 'Departure time',
  },
  schoolGrade: {
    id: 'School grade',
    defaultMessage: 'School grade',
  },
  course: {
    id: 'Class/Course',
    defaultMessage: 'Class/Course',
  },
  numberOfPupils: {
    id: 'Number of pupils',
    defaultMessage: 'Number of pupils',
  },
  comments: {
    id: 'Comments',
    defaultMessage: 'Comments:',
  },
  FirstCheckbox: {
    id: 'I would like to receive news about special events at DLR_School_Lab.',
    defaultMessage:
      'I would like to receive news about special events at DLR_School_Lab.',
  },
  h4: {
    id: 'Data protection regulations',
    defaultMessage: 'Data protection regulations',
  },
  DataProtection: {
    id:
      'In order to provide this service, collected data is processed at the site. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
    defaultMessage:
      'In order to provide this service, collected data is processed at the site. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
  },
  DataProcessing: {
    id: 'Purpose of data processing',
    defaultMessage: 'Purpose of data processing',
  },
  DataProcessingText: {
    id:
      'The processing of personal data entered into this form is required for visitor management at the DLR_School_Lab',
    defaultMessage:
      'The processing of personal data entered into this form is required for visitor management at the DLR_School_Lab',
  },
  DurationOfStorage: {
    id: 'Duration of storage',
    defaultMessage: 'Duration of storage',
  },
  DurationOfStorageText: {
    id:
      'The personal data of the data subject will be deleted or made inaccessible as soon as the purpose of storage no longer applies. In addition, storage only takes place if authorised by European Union or Member State directives or laws, or other regulations to which the data controller is subject.',
    defaultMessage:
      'The personal data of the data subject will be deleted or made inaccessible as soon as the purpose of storage no longer applies. In addition, storage only takes place if authorised by European Union or Member State directives or laws, or other regulations to which the data controller is subject.',
  },
  FinalCheckbox: {
    id: 'I have carefully read, understood and agree to the privacy policy.',
    defaultMessage:
      'I have carefully read, understood and agree to the privacy policy.',
  },
  Submit: {
    id: 'Submit',
    defaultMessage: 'Submit',
  },
  messageSent: {
    id: 'Email sent',
    defaultMessage: 'Email sent',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  confirmationH1: {
    id: 'Message sent',
    defaultMessage: 'Message sent',
  },
  confirmation: {
    id: 'Thank you for your inquiry!',
    defaultMessage: 'Thank you for your inquiry!',
  },
  selectPlaceholder: {
    id: 'Choose the location where you want to visit',
    defaultMessage: 'Choose the location where you want to visit',
  },
});

const SchoolLabContactForm = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location.pathname;
  const query = qs.parse(location.search);

  let history = useHistory();

  const content = useSelector((state) => state.content.data);
  const categories = content?.booking_form_categories?.items;

  const { loading, loaded, serverError, ticket } = useSelector((state) => ({
    ticket: state.contactform.ticket,
    loading: state.contactform.loading,
    loaded: state.contactform.loaded,
    serverError: state.contactform.error,
  }));

  // scroll to top after form submission
  useEffect(() => {
    if (loaded) {
      window.scrollTo(0, 0);
    }
  }, [loaded]);

  useEffect(() => {
    dispatch(getFormTicket(getParentUrl(pathname)));
    dispatch(getContent(getParentUrl(pathname)));
  }, [dispatch, pathname]);

  const [state, setState] = React.useState({
    School: '',
    SchoolType: '',
    Street: '',
    HouseNumber: '',
    PosteCode: '',
    City: '',
    ContactPerson: '',
    Email: '',
    Telephone: '',
    DateOfRequested: '',
    Arrival: '',
    Departure: '',
    SchoolGrade: '',
    Course: '',
    NumberOfPupils: '',
    Comments: '',
    Checkbox: false,
    CheckboxFinal: false,
  });

  const [schoolLabLocation, setSchoolLabLocation] = useState(query.location);

  const onChangeHandler = (event) => {
    if (event.target.name === 'Checkbox') {
      setState({ ...state, [event.target.name]: !state[event.target.name] });
      return;
    }
    if (event.target.name === 'CheckboxFinal') {
      setState({ ...state, [event.target.name]: !state[event.target.name] });
      return;
    }

    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const checkRequiredField = () => {
    if (state.FinalCheckbox === false) {
      return true;
    }

    return false;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (loading) return;
    const error = checkRequiredField();
    if (error) {
      return;
    }

    dispatch(
      submitSchoollabContactForm(content['@id'], {
        school: state.School,
        school_type: state.SchoolType,
        street: state.Street,
        house_number: state.HouseNumber,
        poste_code: state.PosteCode,
        city: state.City,
        telephone: state.Telephone,
        date_of_requested: state.DateOfRequested,
        arrival: state.Arrival,
        departure: state.Departure,
        school_grade: state.SchoolGrade,
        course: state.Course,
        number_of_pupils: state.NumberOfPupils,
        email: state.Email,
        name: state.ContactPerson,
        comments: state.Comments,
        location: schoolLabLocation,
        origin: content['@id'],
        receive_event_news: state.Checkbox,
        ticket: ticket,
      }),
    ).then((resp) => {
      // Reset the form after submission
      setState({
        School: '',
        SchoolType: '',
        Street: '',
        HouseNumber: '',
        PosteCode: '',
        City: '',
        ContactPerson: '',
        Email: '',
        Telephone: '',
        DateOfRequested: '',
        Arrival: '',
        Departure: '',
        SchoolGrade: '',
        Course: '',
        NumberOfPupils: '',
        Comments: '',
        Checkbox: '',
        CheckboxFinal: '',
      });

      setSchoolLabLocation('');
      history.push({
        pathname,
        search: '?confirmation=success',
      });
    });
  };

  return (
    <div id="contact-form" className="schoollab-form">
      <Container id="view">
        <Helmet
          title={intl.formatMessage(messages.helmet, {
            location: schoolLabLocation,
          })}
        />
        <Dimmer active={loading} page></Dimmer>
        {query.confirmation === 'success' ? (
          <>
            <h1>{intl.formatMessage(messages.confirmationH1)}</h1>
            <p className="confirmation-message">
              {intl.formatMessage(messages.confirmation)}
            </p>
          </>
        ) : (
          <>
            <div className="heading-h2">
              <h2>
                {intl.formatMessage(messages.heading2, {
                  location: schoolLabLocation,
                })}
              </h2>
            </div>
            <div className="context-text">
              <p>
                {intl.formatMessage(messages.contextText1, {
                  location: schoolLabLocation,
                })}
              </p>
              <p>{intl.formatMessage(messages.contextText2)}</p>
              <p>{intl.formatMessage(messages.mandatory)}</p>
            </div>
            <div>
              <div className="form-container">
                <form onSubmit={onSubmitHandler}>
                  <div className="filter-group-select">
                    <label htmlFor="location">
                      {intl.formatMessage(messages.selectPlaceholder)}*:
                    </label>
                    <select
                      required
                      id="location"
                      name="Location"
                      aria-label={intl.formatMessage(
                        messages.selectPlaceholder,
                      )}
                      onBlur={(e) => setSchoolLabLocation(e.target.value)}
                      onChange={(e) => setSchoolLabLocation(e.target.value)}
                      value={schoolLabLocation || null}
                      placeholder={intl.formatMessage(
                        messages.selectPlaceholder,
                      )}
                    >
                      <option value="" disabled selected hidden>
                        {intl.formatMessage(messages.selectPlaceholder)}
                      </option>
                      {categories?.map((category) => (
                        <option key={category['@id']} value={category.title}>
                          {category.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="custom-input-group">
                    <label htmlFor="school">
                      {intl.formatMessage(messages.nameOftheSchool)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="school"
                      name="School"
                      onChange={onChangeHandler}
                      value={state.School}
                      title={intl.formatMessage(messages.nameOftheSchool)}
                      type="text"
                    />
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="school-type">
                      {intl.formatMessage(messages.schoolOfType)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="school-type"
                      name="SchoolType"
                      value={state.SchoolType}
                      onChange={onChangeHandler}
                      title={intl.formatMessage(messages.schoolOfType)}
                    />
                  </div>
                  <br />
                  <div className="flex-container">
                    <div className="custom-input-group half">
                      <label htmlFor="street">
                        {intl.formatMessage(messages.street)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="street"
                        name="Street"
                        onChange={onChangeHandler}
                        value={state.Street}
                        title="Street"
                        type="text"
                      ></input>
                    </div>

                    <div className="custom-input-group half">
                      <label htmlFor="house-no">
                        {intl.formatMessage(messages.houseOfNumber)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="house-no"
                        name="HouseNumber"
                        onChange={onChangeHandler}
                        value={state.HouseNumber}
                        title="HouseNumber"
                        type="text"
                      ></input>
                    </div>
                  </div>

                  <div className="flex-container">
                    <div className="custom-input-group half">
                      <label htmlFor="post-code">
                        {intl.formatMessage(messages.postCode)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="post-code"
                        name="PosteCode"
                        value={state.PosteCode}
                        onChange={onChangeHandler}
                        title="Poste Code"
                        type="text"
                      />
                    </div>

                    <div className="custom-input-group half">
                      <label htmlFor="city">
                        {intl.formatMessage(messages.city)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="city"
                        name="City"
                        value={state.City}
                        onChange={onChangeHandler}
                        title="city"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="contact-person">
                      {intl.formatMessage(messages.contactPerson)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="contact-person"
                      name="ContactPerson"
                      value={state.ContactPerson}
                      onChange={onChangeHandler}
                      title="Contact Person"
                      type="text"
                    />
                  </div>

                  <div className="flex-container">
                    <div className="custom-input-group half">
                      <label htmlFor="email">
                        {intl.formatMessage(messages.email)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="email"
                        name="Email"
                        value={state.Email}
                        onChange={onChangeHandler}
                        title="E-Mail"
                        type="email"
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        size="60"
                        maxLength="60"
                      />
                    </div>

                    <div className="custom-input-group half">
                      <label htmlFor="telephone-no">
                        {intl.formatMessage(messages.telephone)}
                      </label>
                      <input
                        id="telephone-no"
                        name="Telephone"
                        value={state.Telephone}
                        onChange={onChangeHandler}
                        title="Telephone Number"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="date-of-requested">
                      {intl.formatMessage(messages.dateOfrequested)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="date-of-requested"
                      name="DateOfRequested"
                      value={state.DateOfRequested}
                      onChange={onChangeHandler}
                      title="Date of Requested"
                      type="text"
                    />
                  </div>

                  <div className="flex-container">
                    <div className="custom-input-group half">
                      <label htmlFor="arrival-time">
                        {intl.formatMessage(messages.arrival)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="arrival-time"
                        name="Arrival"
                        value={state.Arrival}
                        onChange={onChangeHandler}
                        title="Arrival Time"
                        type="text"
                      />
                    </div>

                    <div className="custom-input-group half">
                      <label htmlFor="departure-time">
                        {intl.formatMessage(messages.departure)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="departure-time"
                        name="Departure"
                        value={state.Departure}
                        onChange={onChangeHandler}
                        title="Departure Time"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="school-grade">
                      {intl.formatMessage(messages.schoolGrade)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="school-grade"
                      name="SchoolGrade"
                      value={state.SchoolGrade}
                      onChange={onChangeHandler}
                      title="SchoolGrade"
                      type="text"
                    />
                  </div>

                  <div className="flex-container">
                    <div className="custom-input-group half">
                      <label htmlFor="course">
                        {intl.formatMessage(messages.course)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="course"
                        name="Course"
                        value={state.Course}
                        onChange={onChangeHandler}
                        title="Course"
                        type="text"
                      />
                    </div>

                    <div className="custom-input-group half">
                      <label htmlFor="number-of-pupils">
                        {intl.formatMessage(messages.numberOfPupils)}
                        <dfn title="Mandatory">*</dfn>
                      </label>
                      <input
                        required
                        id="number-of-pupils"
                        name="NumberOfPupils"
                        value={state.NumberOfPupils}
                        onChange={onChangeHandler}
                        title="Number Of Pupils"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="comments">
                      {intl.formatMessage(messages.comments)}
                    </label>
                    <textarea
                      id="comments"
                      name="Comments"
                      value={state.Comments}
                      onChange={onChangeHandler}
                      title="Comments"
                      type="text"
                      rows={4}
                      cols={20}
                    />
                  </div>

                  <div className="checkbox-wrapper first">
                    <input
                      id="checkbox"
                      name="Checkbox"
                      type="checkbox"
                      onChange={onChangeHandler}
                    />
                    <label className="first-checkbox" htmlFor="checkbox">
                      {intl.formatMessage(messages.FirstCheckbox)}
                    </label>
                  </div>

                  <div className="wrapper">
                    <p>{intl.formatMessage(messages.DataProtection)}</p>
                    <p>
                      <strong>
                        {intl.formatMessage(messages.DataProcessing)}
                      </strong>
                    </p>
                    <p>{intl.formatMessage(messages.DataProcessingText)}</p>
                    <p>
                      <strong>
                        {intl.formatMessage(messages.DurationOfStorage)}
                      </strong>
                    </p>
                    <p>{intl.formatMessage(messages.DurationOfStorageText)}</p>
                    <p className="privacy-policy">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          currentLang === 'de'
                            ? '/de/service/datenschutz'
                            : '/en/service/privacypolicy'
                        }
                      >
                        {intl.formatMessage(messages.privacy)}
                      </a>
                    </p>
                  </div>

                  <div className="checkbox-wrapper">
                    <input
                      id="checkboxFinal"
                      name="CheckboxFinal"
                      type="checkbox"
                      onChange={onChangeHandler}
                      required
                    />
                    <label htmlFor="checkboxFinal">
                      {intl.formatMessage(messages.FinalCheckbox)}*
                    </label>
                  </div>

                  {serverError && (
                    <Message
                      icon="warning"
                      negative
                      header={intl.formatMessage(messages.error)}
                      content={serverError.response.body.message}
                    />
                  )}
                  <div className="button-container">
                    <Button
                      name="submit"
                      type="submit"
                      title={intl.formatMessage(messages.Submit)}
                      loading={loading}
                    >
                      {intl.formatMessage(messages.Submit)}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default SchoolLabContactForm;

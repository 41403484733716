import React from 'react';
import { filter, find, isEmpty, map } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import { flattenToAppURL, langmap } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import config from '@plone/volto/registry';

const MultilingualManageContentMenu = (props) => {
  const { content, pathname } = props;
  const currentLanguage = useSelector((state) => state.intl.locale);
  const { settings } = config;

  const translationsLeft = filter(
    settings.supportedLanguages,
    (lang) =>
      !Boolean(
        content['@components'].translations &&
          find(content['@components'].translations.items, {
            language: lang,
          }),
      ) && currentLanguage !== lang,
  );

  return (
    <>
      {settings.isMultilingual && content['@components'].translations && (
        <Plug pluggable="toolbar-more-manage-content" id="translateTo">
          {!isEmpty(translationsLeft) &&
            map(translationsLeft, (lang) => (
              <li key={lang}>
                <Link
                  to={{
                    pathname: `${pathname}/create-translation`,
                    state: {
                      type: content['@type'],
                      translationOf: flattenToAppURL(content['@id']),
                      language: lang,
                    },
                  }}
                  className="item"
                >
                  <FormattedMessage
                    id="Translate to {lang}"
                    defaultMessage="Translate to {lang}"
                    values={{
                      lang: langmap[lang].nativeName.toLowerCase(),
                    }}
                  />
                </Link>
              </li>
            ))}
        </Plug>
      )}
    </>
  );
};

export default MultilingualManageContentMenu;

import { defineMessages } from 'react-intl';

// Put messages here if we need to override the translation from an addon.
// They cannot go in volto-dlr, since it is also an addon,
// and the ordering of translations from addons is not well-defined.

defineMessages({
  ButtonBlock: {
    id: 'Button Block',
    defaultMessage: 'Button',
  },
  separatorBlock: {
    id: 'Separator Block',
    defaultMessage: 'Separator',
  },
  innerAlign: {
    id: ' Inner Alignment',
    defaultMessage: 'Inner Alignment',
  },
});

const applyConfig = (config) => {
  // a change
  return config;
};

export default applyConfig;

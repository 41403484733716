import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import deCountry from './DeCountry';
import enCountry from './EnCountry';

import { Container, Button, Message } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { getFormTicket, emailNotification } from 'volto-dlr/actions';

const messages = defineMessages({
  subscribeForm: {
    id: 'Subscribe Form',
    defaultMessage: 'Subscribe Form',
  },
  heading: {
    id: 'DLRmagazine: subscribe / unsubscribe',
    defaultMessage: 'DLRmagazine: subscribe / unsubscribe',
  },
  language: {
    id: 'Language',
    defaultMessage: 'Language',
  },
  germanCopy: {
    id: 'German Copy',
    defaultMessage: 'German Copy',
  },
  englishCopy: {
    id: 'English Copy',
    defaultMessage: 'English Copy',
  },
  iWantTo: {
    id: 'I want to',
    defaultMessage: 'I want to',
  },
  subscribe: {
    id: 'Subscribe',
    defaultMessage: 'Subscribe',
  },
  unSubscribe: {
    id: 'Unsubscribe',
    defaultMessage: 'Unsubscribe',
  },
  salutation: {
    id: 'Salutation',
    defaultMessage: 'Salutation',
  },
  Mr: {
    id: 'Mr',
    defaultMessage: 'Mr',
  },
  Mrs: {
    id: 'Mrs',
    defaultMessage: 'Mrs',
  },
  notSpecified: {
    id: 'Not specified',
    defaultMessage: 'Not specified',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  lastName: {
    id: 'Last Name',
    defaultMessage: 'Last Name',
  },
  firstName: {
    id: 'First Name',
    defaultMessage: 'First Name',
  },
  email: {
    id: 'E-Mail',
    defaultMessage: 'E-mail',
  },
  homeAddress: {
    id: 'Home address',
    defaultMessage: 'Home address',
  },
  companyAddress: {
    id: 'Company address',
    defaultMessage: 'Company address',
  },
  address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  zipCode: {
    id: 'Zip code',
    defaultMessage: 'Zip code',
  },
  city: {
    id: 'City',
    defaultMessage: 'City',
  },
  additionToAddress: {
    id: 'Addition to address',
    defaultMessage: 'Addition to address',
  },
  noteOnChange: {
    id: 'Note on change of address',
    defaultMessage: 'Note on change of address',
  },
  changeAddress: {
    id:
      'To change your address, you must first cancel your current subscription. You must then resubscribe, stating your new address. Thank you very much.',
    defaultMessage:
      'To change your address, you must first cancel your current subscription. You must then resubscribe, stating your new address. Thank you very much.',
  },
  purposeOfData: {
    id: 'Purpose of data processing',
    defaultMessage: 'Purpose of data processing ',
  },
  personalData: {
    id:
      'Personal data from the input screen ist processed exclusively for the purpose of making contact. Where contact is made by email, this represents our necessary, legitimate interest in processing the data.',
    defaultMessage:
      'Personal data from the input screen ist processed exclusively for the purpose of making contact. Where contact is made by email, this represents our necessary, legitimate interest in processing the data.',
  },
  anyOther: {
    id:
      'Any other personal data processed during the sending procedure is used to prevent misuse of the contact form and to ensure the security of our Information Technology systems.',
    defaultMessage:
      'Any other personal data processed during the sending procedure is used to prevent misuse of the contact form and to ensure the security of our Information Technology systems.',
  },
  storageDuration: {
    id: 'Storage duration',
    defaultMessage: 'Storage duration',
  },
  dataDelted: {
    id:
      'The data is deleted as soon as it is no longe required for the purpose for which it was collected. For personal data entered in the input screen of the contact form and for personal data sent to us by email, this is the case when the relevant coorrespondence with the user has come to an end. A conversation has come to an end when the circumstances indicate that the relevant matter has been dealt with definitvely.',
    defaultMessage:
      'The data is deleted as soon as it is no longe required for the purpose for which it was collected. For personal data entered in the input screen of the contact form and for personal data sent to us by email, this is the case when the relevant coorrespondence with the user has come to an end. A conversation has come to an end when the circumstances indicate that the relevant matter has been dealt with definitvely.',
  },
  dataProtection: {
    id: 'Data protection regulations',
    defaultMessage: 'Data protection regulations',
  },
  weStore: {
    id:
      'We store the data entered by you in order to provide you with services. It will only be used for this purpose and will not be transferred to third parties. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
    defaultMessage:
      'We store the data entered by you in order to provide you with services. It will only be used for this purpose and will not be transferred to third parties. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
  },
  firstCheckbox: {
    id:
      'I have read the data protection regulations carefully, I have understood the procedure described and hereby consent to the processing of my data for the purpose of contacting me.*',
    defaultMessage:
      'I have read the data protection regulations carefully, I have understood the procedure described and hereby consent to the processing of my data for the purpose of contacting me.*',
  },
  secondCheckbox: {
    id:
      'Our offer is generally aimed at persons aged 16 years or older. Individuals under the age of 16 are not permitted to submit personal data to us without the consent of their parents or legal guardian.*',
    defaultMessage:
      'Our offer is generally aimed at persons aged 16 years or older. Individuals under the age of 16 are not permitted to submit personal data to us without the consent of their parents or legal guardian.*',
  },
  submit: {
    id: 'Submit',
    defaultMessage: 'Submit',
  },
  messageSent: {
    id: 'Email sent',
    defaultMessage: 'Email sent',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  here: {
    id:
      'Here you can subscribe to the DLRmagazine free of charge and also let us know if you want to unsubscribe.',
    defaultMessage:
      'Here you can subscribe to the DLRmagazine free of charge and also let us know if you want to unsubscribe.',
  },
  thankYou: {
    id:
      'Thank you very much. Your request has been sent successfully and is being processed.',
    defaultMessage:
      'Thank you very much. Your request has been sent successfully and is being processed.',
  },
  moreAbout: {
    id: 'For more about DLR, follow us on',
    defaultMessage: 'For more about DLR, follow us on',
  },
  and: {
    id: 'and',
    defaultMessage: 'and',
  },
  newsLetter: {
    id: 'or subscribe to our newsletter.',
    defaultMessage: 'or subscribe to our newsletter.',
  },
  back: {
    id: 'back to page DLRmagazine',
    defaultMessage: 'back to page DLRmagazine',
  },
});

const SubscribeForm = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.intl.locale);

  // Get form ticket after component is mounted
  useEffect(() => {
    dispatch(getFormTicket('/'));
  }, [dispatch]);

  const { loading, loaded, serverError, ticket } = useSelector((state) => ({
    loading: state.emailNotification.loading,
    loaded: state.emailNotification.loaded,
    serverError: state.emailNotification.error,
    ticket: state.contactform.ticket,
  }));
  const isLoading = usePrevious(loading);

  // scroll to top after form submission
  useEffect(() => {
    if (loaded) {
      window.scrollTo(0, 0);
    }
  }, [loaded]);

  const land = currentLang === 'de' ? 'Deutschland' : 'Germany';
  const [error, setError] = React.useState({});
  const [state, setState] = React.useState({
    magazineLang: intl.formatMessage(messages.germanCopy),
    orderOption: intl.formatMessage(messages.subscribe),
    salutation: intl.formatMessage(messages.Mr),
    title: '',
    lastName: '',
    firstName: '',
    organisation: '',
    abteilung: '',
    position: '',
    email: '',
    addressType: intl.formatMessage(messages.homeAddress),
    aboAddress: '',
    postalCode: '',
    ort: '',
    adresszusatz: '',
    land: land,
    firstCheckbox: false,
    secondCheckbox: false,
  });
  const onChangeHandler = (event) => {
    if (
      event.target.name === 'firstCheckbox' ||
      event.target.name === 'secondCheckbox'
    ) {
      setState({ ...state, [event.target.name]: !state[event.target.name] });
      setError({ ...error, [event.target.name]: false });
      return;
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const checkRequiredField = () => {
    if (state.firstCheckbox === false) {
      setError((error) => {
        return { ...error, firstCheckbox: true };
      });
      return true;
    }
    if (state.secondCheckbox === false) {
      setError((error) => {
        return { ...error, secondCheckbox: true };
      });
      return true;
    }

    return false;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (loading) return;
    const error = checkRequiredField();
    if (error) {
      return;
    }
    let message = `Magazin: ${state.magazineLang}\nThema: ${state.orderOption}\nAnrede: ${state.salutation}\nTitle: ${state.title}\nNachname: ${state.lastName}\nVorname: ${state.firstName}\nOrganisation: ${state.organisation}\nAbteilung: ${state.abteilung}\nPosition: ${state.position}\nEmail: ${state.email}\nPrivatadresse: ${state.addressType}\nPostadresse: ${state.aboAddress}\nPostleitzahl: ${state.postalCode}\nOrt: ${state.ort}\nAdresszusatz: ${state.adresszusatz}\nLand: ${state.land}\n`;
    dispatch(
      emailNotification(
        ticket,
        state.email,
        message,
        state.firstName,
        'Subscription form',
        state.orderOption,
      ),
    );
  };

  return (
    <div id="subscribe-form">
      <Container id="view">
        <Helmet title={intl.formatMessage(messages.subscribeForm)} />
        {loaded && isLoading ? (
          <div class="success-body">
            <article>
              <p>{intl.formatMessage(messages.thankYou)}</p>
              <p>
                {intl.formatMessage(messages.moreAbout)}{' '}
                <a
                  href={
                    currentLang === 'de'
                      ? 'https://www.facebook.com/DLRde'
                      : 'https://www.facebook.com/DLRen'
                  }
                  title="External link Facebook website"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>{' '}
                {intl.formatMessage(messages.and)}{' '}
                <a
                  href={
                    currentLang === 'de'
                      ? 'https://twitter.com/DLR_de'
                      : 'https://twitter.com/DLR_en'
                  }
                  title="External link Twitter website"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>{' '}
                {intl.formatMessage(messages.newsLetter)}
              </p>
            </article>

            <Button
              as={Link}
              to={
                currentLang === 'de'
                  ? '/de/medien/publikationen/magazine/dlrmagazin'
                  : '/en/media/publications/magazines/dlrmagazine'
              }
              className="back-btn"
            >
              {intl.formatMessage(messages.back)}
            </Button>
          </div>
        ) : (
          <>
            <div>
              <h1>{intl.formatMessage(messages.heading)}</h1>
              <p>{intl.formatMessage(messages.here)}</p>
            </div>
            <div>
              <div className="form-container">
                <form onSubmit={onSubmitHandler}>
                  <div>
                    <div className="csstable">
                      <div className="csstable Xcustom-radio">
                        <ul>
                          <li>{intl.formatMessage(messages.language)}</li>
                          <li>
                            <label htmlFor="German">
                              <input
                                className="radio"
                                type="radio"
                                id="German"
                                name="magazineLang"
                                value={intl.formatMessage(messages.germanCopy)}
                                title={intl.formatMessage(messages.germanCopy)}
                                onChange={onChangeHandler}
                                checked={
                                  intl.formatMessage(messages.germanCopy) ===
                                  state.magazineLang
                                }
                              />{' '}
                              {intl.formatMessage(messages.germanCopy)}
                            </label>
                          </li>
                          <li>
                            <label htmlFor="English">
                              <input
                                className="radio"
                                type="radio"
                                id="English"
                                name="magazineLang"
                                value={intl.formatMessage(messages.englishCopy)}
                                title={intl.formatMessage(messages.englishCopy)}
                                onChange={onChangeHandler}
                              />{' '}
                              {intl.formatMessage(messages.englishCopy)}
                            </label>
                          </li>
                        </ul>
                        <ul>
                          <li>{intl.formatMessage(messages.iWantTo)}</li>
                          <li>
                            <label htmlFor="subscribe">
                              <input
                                className="radio"
                                type="radio"
                                id="subscribe"
                                name="orderOption"
                                value={intl.formatMessage(messages.subscribe)}
                                title={intl.formatMessage(messages.subscribe)}
                                onChange={onChangeHandler}
                                checked={
                                  intl.formatMessage(messages.subscribe) ===
                                  state.orderOption
                                }
                              />{' '}
                              {intl.formatMessage(messages.subscribe)}
                            </label>
                          </li>
                          <li>
                            <label htmlFor="unSubscribe">
                              <input
                                className="radio"
                                type="radio"
                                id="unSubscribe"
                                name="orderOption"
                                value={intl.formatMessage(messages.unSubscribe)}
                                title={intl.formatMessage(messages.unSubscribe)}
                                onChange={onChangeHandler}
                              />{' '}
                              {intl.formatMessage(messages.unSubscribe)}
                            </label>
                          </li>
                        </ul>

                        <ul>
                          <li>{intl.formatMessage(messages.salutation)}</li>
                          <li>
                            <label htmlFor="Mr">
                              <input
                                className="radio"
                                type="radio"
                                id="Mr"
                                name="salutation"
                                value={intl.formatMessage(messages.Mr)}
                                title={intl.formatMessage(messages.Mr)}
                                onChange={onChangeHandler}
                                checked={
                                  intl.formatMessage(messages.Mr) ===
                                  state.salutation
                                }
                              />{' '}
                              {intl.formatMessage(messages.Mr)}
                            </label>
                          </li>
                          <li>
                            <label htmlFor="Mrs">
                              <input
                                className="radio"
                                type="radio"
                                id="Mrs"
                                name="salutation"
                                value={intl.formatMessage(messages.Mrs)}
                                title={intl.formatMessage(messages.Mrs)}
                                onChange={onChangeHandler}
                              />{' '}
                              {intl.formatMessage(messages.Mrs)}
                            </label>
                          </li>
                          <li>
                            <label htmlFor="Not specified">
                              <input
                                className="radio"
                                type="radio"
                                id="Not specified"
                                name="salutation"
                                value={intl.formatMessage(
                                  messages.notSpecified,
                                )}
                                title={intl.formatMessage(
                                  messages.notSpecified,
                                )}
                                onChange={onChangeHandler}
                              />{' '}
                              {intl.formatMessage(messages.notSpecified)}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="custom-input-group">
                      <label htmlFor="title">
                        {intl.formatMessage(messages.title)}
                      </label>
                      <input
                        id="title"
                        name="title"
                        value={state.title}
                        title={intl.formatMessage(messages.title)}
                        type="text"
                        size="50"
                        maxLength="50"
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label htmlFor="Lastname">
                        {intl.formatMessage(messages.lastName)}
                        <dfn title="Pflichtangabe">*</dfn>
                      </label>
                      <input
                        required="required"
                        id="Lastname"
                        name="lastName"
                        value={state.lastName}
                        title={intl.formatMessage(messages.lastName)}
                        type="text"
                        size="50"
                        maxLength="50"
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label htmlFor="Firstname">
                        {intl.formatMessage(messages.firstName)}
                        <dfn title="Pflichtangabe">*</dfn>
                      </label>
                      <input
                        required
                        id="Firstname"
                        name="firstName"
                        value={state.firstName}
                        title={intl.formatMessage(messages.firstName)}
                        type="text"
                        size="50"
                        maxLength="50"
                        onChange={onChangeHandler}
                      />
                    </div>
                    {currentLang === 'de' && (
                      <>
                        <div className="custom-input-group">
                          <label htmlFor="Organisation">Organisation</label>
                          <input
                            id="Organisation"
                            name="organisation"
                            value={state.organisation}
                            title="Organisation"
                            type="text"
                            maxLength="50"
                            onChange={onChangeHandler}
                          />
                        </div>
                        <div className="custom-input-group">
                          <label htmlFor="Abteilung">Abteilung</label>
                          <input
                            id="Abteilung"
                            name="abteilung"
                            value={state.abteilung}
                            title="Abteilung"
                            type="text"
                            maxLength="50"
                            onChange={onChangeHandler}
                          />
                        </div>
                        <div className="custom-input-group">
                          <label htmlFor="Position">Position</label>
                          <input
                            id="Position"
                            name="position"
                            value={state.position}
                            title="Position"
                            type="text"
                            maxLength="50"
                            onChange={onChangeHandler}
                          />
                        </div>
                      </>
                    )}

                    <div className="custom-input-group">
                      <label htmlFor="Email">
                        {intl.formatMessage(messages.email)}
                        <dfn title="Pflichtangabe">*</dfn>
                      </label>
                      <input
                        required="required"
                        id="Email"
                        name="email"
                        value={state.email}
                        title={intl.formatMessage(messages.email)}
                        type="email"
                        size="60"
                        maxLength="60"
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="custom-radio">
                      <span className="formField">
                        <span>
                          <input
                            type="radio"
                            id="Homeaddress"
                            name="addressType"
                            value={intl.formatMessage(messages.homeAddress)}
                            title={intl.formatMessage(messages.homeAddress)}
                            checked={
                              intl.formatMessage(messages.homeAddress) ===
                              state.addressType
                            }
                            onChange={onChangeHandler}
                          />
                          <label htmlFor="Homeaddress">
                            {intl.formatMessage(messages.homeAddress)}
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            id="companyAddress"
                            name="addressType"
                            value={intl.formatMessage(messages.companyAddress)}
                            title={intl.formatMessage(messages.companyAddress)}
                            onChange={onChangeHandler}
                          />
                          <label htmlFor="companyAddress">
                            {intl.formatMessage(messages.companyAddress)}
                          </label>
                        </span>
                      </span>
                    </div>

                    <div className="custom-input-group">
                      <label htmlFor="Address">
                        {intl.formatMessage(messages.address)}
                        <dfn title="Pflichtangabe">*</dfn>
                      </label>
                      <input
                        required="required"
                        id="Address"
                        name="aboAddress"
                        value={state.aboAddress}
                        title={intl.formatMessage(messages.address)}
                        type="text"
                        size="50"
                        maxLength="255"
                        onChange={onChangeHandler}
                      />
                    </div>

                    <div className="custom-input-group">
                      <label htmlFor="Zipcode">
                        {intl.formatMessage(messages.zipCode)}
                        <dfn title="Pflichtangabe">*</dfn>
                      </label>
                      <input
                        required="required"
                        id="Zipcode"
                        name="postalCode"
                        value={state.postalCode}
                        title={intl.formatMessage(messages.zipCode)}
                        type="text"
                        size="10"
                        maxLength="10"
                        onChange={onChangeHandler}
                      />
                    </div>

                    <div className="custom-input-group">
                      <label htmlFor="City">
                        {intl.formatMessage(messages.city)}
                        <dfn title="Pflichtangabe">*</dfn>
                      </label>
                      <input
                        required="required"
                        id="City"
                        name="ort"
                        value={state.ort}
                        title={intl.formatMessage(messages.city)}
                        type="text"
                        size="50"
                        maxLength="255"
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label htmlFor="Addition Address">
                        {intl.formatMessage(messages.additionToAddress)}
                      </label>
                      <input
                        id="Addition Address"
                        name="adresszusatz"
                        value={state.adresszusatz}
                        title={intl.formatMessage(messages.additionToAddress)}
                        type="text"
                        size="50"
                        maxLength="255"
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="filter-group-select">
                      <span className="formField">
                        <select
                          id="land"
                          name="land"
                          aria-label="Select a country"
                          onBlur={onChangeHandler}
                          onChange={onChangeHandler}
                          value={state.land}
                        >
                          {currentLang === 'de'
                            ? map(deCountry, (item, index) => (
                                <option key={index} title={item} value={item}>
                                  {item}
                                </option>
                              ))
                            : map(enCountry, (item, index) => (
                                <option key={index} title={item} value={item}>
                                  {item}
                                </option>
                              ))}
                        </select>
                        <input type="hidden" name="land.GROUP" value="1" />
                      </span>
                    </div>

                    <h4 className="">
                      {intl.formatMessage(messages.noteOnChange)}
                    </h4>
                    <p>{intl.formatMessage(messages.changeAddress)}</p>
                    <h4 className="">
                      {intl.formatMessage(messages.purposeOfData)}
                    </h4>
                    <p>
                      <span>
                        {intl.formatMessage(messages.personalData)}
                        <br />
                      </span>
                      {intl.formatMessage(messages.anyOther)}
                    </p>
                    <h4 className="">
                      {intl.formatMessage(messages.storageDuration)}
                    </h4>
                    <p>
                      <span>{intl.formatMessage(messages.dataDelted)}</span>
                    </p>

                    <div className="wrapper">
                      <h4>{intl.formatMessage(messages.dataProtection)}:</h4>
                      <p>
                        {intl.formatMessage(messages.weStore)}{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            currentLang === 'de'
                              ? '/de/service/datenschutz'
                              : '/en/service/privacypolicy'
                          }
                        >
                          {intl.formatMessage(messages.dataProtection)}
                        </a>
                      </p>
                      <br />
                      <div className="checkbox-wrapper">
                        <input
                          id="first-checkbox"
                          name="firstCheckbox"
                          type="checkbox"
                          onChange={onChangeHandler}
                        />
                        <label
                          className={error.firstCheckbox ? 'error' : ''}
                          htmlFor="first-checkbox"
                        >
                          {intl.formatMessage(messages.firstCheckbox)}*
                        </label>
                      </div>
                      <br />
                      <div className="checkbox-wrapper">
                        <input
                          id="second-checkbox"
                          name="secondCheckbox"
                          type="checkbox"
                          onChange={onChangeHandler}
                        />
                        <label
                          className={error.secondCheckbox ? 'error' : ''}
                          htmlFor="second-checkbox"
                        >
                          {intl.formatMessage(messages.secondCheckbox)}*
                        </label>
                      </div>
                    </div>
                    {serverError && (
                      <Message
                        icon="warning"
                        negative
                        attached
                        header={intl.formatMessage(messages.error)}
                        content={serverError.response.body.message}
                      />
                    )}
                    <div className="button-container">
                      <Button
                        name="submit"
                        type="submit"
                        title={intl.formatMessage(messages.submit)}
                        loading={loading}
                      >
                        {intl.formatMessage(messages.submit)}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default SubscribeForm;

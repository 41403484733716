import BannerBlockSidebar from './Data';
import React, { useState } from 'react';
import { SidebarPortal } from '@plone/volto/components';
import View from './View';
import { flattenToAppURL } from '@plone/volto/helpers';

const Edit = (props) => {
  const { data, block, onChangeBlock, selected } = props;

  const [url, setUrl] = useState('');

  const resetSubmitUrl = () => {
    setUrl('');
  };

  const onChangeUrl = ({ target }) => {
    setUrl(target.value);
  };

  const onSelectItem = (url, item) => {
    onChangeBlock(block, {
      ...data,
      url: flattenToAppURL(url),
      image_scales: item.image_scales,
      credit: { data: item.credit },
    });
  };

  return (
    <div className="block banner" role="presentation">
      <View
        {...props}
        url={url}
        onSelectItem={onSelectItem}
        onChangeUrl={onChangeUrl}
        resetSubmitUrl={resetSubmitUrl}
        isEditMode
      />
      <SidebarPortal selected={selected}>
        <BannerBlockSidebar
          {...props}
          onSelectItem={onSelectItem}
          resetSubmitUrl={resetSubmitUrl}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </div>
  );
};

export default Edit;

import { defineMessages, useIntl } from 'react-intl';
import { ObjectListWidget } from '@plone/volto/components';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
});

const ContactFormCategoriesWidget = (props) => {
  const { value } = props;
  const intl = useIntl();

  function onChange(id, value) {
    props.onChange(id, { items: value });
  }

  function schema(props) {
    return {
      title: intl.formatMessage(messages.item),
      addMessage: intl.formatMessage(messages.addItem),
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['title', 'email'],
        },
      ],

      properties: {
        title: {
          title: intl.formatMessage(messages.title),
        },
        email: {
          title: intl.formatMessage(messages.email),
        },
      },
      required: ['title', 'email'],
    };
  }

  return (
    <ObjectListWidget
      {...props}
      value={value?.items}
      schema={schema}
      onChange={onChange}
    />
  );
};

export default ContactFormCategoriesWidget;

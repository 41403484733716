/**
 * OVERRIDE DefaultBody.jsx
 * REASON: Use class="headline" instead of h2 for rendering head_title
 * FILE: https://raw.githubusercontent.com/kitconcept/volto-blocks-grid/7d35dd52aad3d730b40b64c8549dd92de605809b/src/components/Teaser/DefaultBody.jsx
 * FILE VERSION: Volto 16.0.0-alpha.19
 * DATE: 2022-07-29
 * DEVELOPER: @davisagli
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { MaybeWrap } from '@plone/volto/components';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { PreviewImg } from 'volto-dlr/helpers';
import { isInternalURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserDefaultTemplate = (props) => {
  const { className, data, isEditMode, insideGridBlock = false } = props;
  const intl = useIntl();
  // START CUSTOMIZATION
  let href = { ...data.href?.[0] };
  const image = data.preview_image?.[0];

  if (href?.anchor) {
    href['@id'] = `${href['@id']}#${href.anchor}`;
  }
  // END CUSTOMIZATION

  const { openExternalLinkInNewTab } = config.settings;
  return (
    <div className={cx('block teaser', className)}>
      <>
        {!href && isEditMode && (
          <Message>
            <div className="grid-teaser-item placeholder">
              <img src={imageBlockSVG} alt="" />
              <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
            </div>
          </Message>
        )}
        {href && (
          <MaybeWrap
            condition={!isEditMode}
            as={UniversalLink}
            item={href}
            target={
              data.openLinkInNewTab ||
              (openExternalLinkInNewTab && !isInternalURL(href['@id']))
                ? '_blank'
                : null
            }
          >
            <div
              // START CUSTOMIZATION
              className={`grid-teaser-item ${data.variation ?? 'default'} ${
                data.hideImage ? 'hideImage' : ''
              }`}
              //END CUSTOMIZATION
            >
              {(href.hasPreviewImage || href.image_field || image) &&
                !data.hideImage && (
                  <div className="grid-image-wrapper">
                    {/* START CUSTOMIZATION */}
                    <PreviewImg
                      previewImage={image}
                      href={href}
                      scaleName="teaser"
                      targetWidth={
                        !insideGridBlock && data.styles?.align === 'center'
                          ? 'default'
                          : 'half'
                      }
                    />
                    {/* END CUSTOMIZATION */}
                  </div>
                )}
              <div className="content">
                {/* START CUSTOMIZATION */}
                {data?.head_title && (
                  <div className="headline">{data.head_title}</div>
                )}
                {/* END CUSTOMIZATION */}

                <h2>{data?.title}</h2>
                {!data.hide_description && <p>{data?.description}</p>}
              </div>
            </div>
          </MaybeWrap>
        )}
      </>
    </div>
  );
};

TeaserDefaultTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserDefaultTemplate;

/**
 * Routes.
 * @module routes
 */

import { App, Edit, NotFound } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';

import withClientSideContent from 'volto-dlr/components/Manage/withClientSideContent';

/**
 *
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      {
        path: ['/edit', '/**/edit'],
        component: withClientSideContent(Edit),
      },
      {
        path: `/(${config.settings?.supportedLanguages.join('|')})/search`,
        component: config.widgets.SolrSearch,
      },
      {
        // Use @@ prefix to discriminate from content.
        path: `/@@search`,
        component: config.widgets.SolrSearch,
      },
      {
        // Use @@ prefix to discriminate from content.
        path: `/**/@@search`,
        component: config.widgets.SolrSearch,
      },
      {
        path: `/search`,
        component: config.widgets.SolrSearch,
      },
      {
        path: '/DE/Service/ueber-relaunch/erklaerung.html',
        component: NotFound,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;

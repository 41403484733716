import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Table: {
    id: 'Table',
    defaultMessage: 'Table',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
});

export function slateTableBlockSchemaEnhancer(props) {
  const { intl } = props;
  return {
    title: intl.formatMessage(messages.Table),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title'],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(messages.title),
      },
    },

    required: [],
  };
}

export default slateTableBlockSchemaEnhancer;

import nearestInavRoot from './reducers/nearestInavRoot/nearestInavRoot';

const applyConfig = (config) => {
  config.settings.apiExpanders.push({
    match: '/',
    GET_CONTENT: ['nearest-inav-root'],
  });

  config.addonReducers = {
    ...config.addonReducers,
    nearestInavRoot,
  };

  return config;
};

export default applyConfig;

export const VIDEO_METADATA = 'VIDEO_METADATA';

/**
 * Use the video-metadata service to get metadata about a video from a 3rd-party provider
 * @function getVideoMetadata
 * @param {string} mediaID media id.
 * @returns {Object} Action.
 */
export function getVideoMetadata(pathname, url) {
  return {
    type: VIDEO_METADATA,
    request: {
      op: 'post',
      path: `${pathname}/@video-metadata`,
      data: { url },
    },
  };
}

import React from 'react';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import {
  Option,
  DropdownIndicator,
} from '@plone/volto/components/manage/Widgets/SelectStyling';
import {
  selectTheme,
  customSelectStyles,
} from '@plone/volto/components/manage/Blocks/Search/components/SelectStyling';
import { selectFacetSchemaEnhancer } from '@plone/volto/components/manage/Blocks/Search/components/base';

import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  yes: {
    id: 'Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'No',
    defaultMessage: 'No',
  },
});

const BooleanSelectFacet = (props) => {
  const { facet, reactSelect, onChange, value, isEditMode } = props;
  const Select = reactSelect.default;
  const v = Array.isArray(value) && value.length === 0 ? null : value;
  const intl = useIntl();

  return (
    <Select
      placeholder={facet?.title ?? (facet?.field?.label || 'select...')}
      className="react-select-container"
      classNamePrefix="react-select"
      options={[
        { value: 'yes', label: intl.formatMessage(messages.yes) },
        { value: 'no', label: intl.formatMessage(messages.no) },
      ]}
      styles={customSelectStyles}
      theme={selectTheme}
      components={{ DropdownIndicator, Option }}
      isDisabled={isEditMode}
      onChange={(data) => {
        if (data) {
          onChange(facet.field.value, [data]);
        } else {
          // data has been removed
          onChange(facet.field.value, undefined);
        }
      }}
      isMulti={false}
      isClearable
      value={v}
    />
  );
};

BooleanSelectFacet.schemaEnhancer = selectFacetSchemaEnhancer;

BooleanSelectFacet.stateToValue = ({ facetSettings, index, selectedValue }) => {
  return selectedValue || typeof selectedValue === 'string';
};

BooleanSelectFacet.valueToQuery = ({ value, facet }) => {
  if (value && value.length === 1 && value[0].value === 'yes') {
    return {
      i: facet.field.value,
      o: 'plone.app.querystring.operation.boolean.isTrue',
      v: '',
    };
  }
  if (value && value.length === 1 && value[0].value === 'no') {
    return {
      i: facet.field.value,
      o: 'plone.app.querystring.operation.boolean.isFalse',
      v: '',
    };
  }
  return null;
};

export default injectLazyLibs('reactSelect')(BooleanSelectFacet);

/**
 * OVERRIDE Edit.jsx
 * REASON: DLR's video block uses an object browser to select a Video content item
 * FILE: https://github.com/plone/volto/blob/02399daf90700e4bb1c42093f7611db9532b1293/src/components/manage/Blocks/Video/Edit.jsx
 * FILE VERSION: Volto 16.0.0-alpha.15
 * PULL REQUEST: https://github.com/kitconcept/dlr-internet/pull/192
 * TICKET: https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/14
 * DATE: 2022-06-21
 * DEVELOPER: @davisagli
 */
/**
 * Edit video block.
 * @module components/manage/Blocks/Title/Edit
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Input, Message } from 'semantic-ui-react';
import cx from 'classnames';
import { isEqual, isEmpty } from 'lodash';
import { getContent } from '@plone/volto/actions';
import { Icon, SidebarPortal, VideoSidebar } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import videoBlockSVG from '@plone/volto/components/manage/Blocks/Video/block-video.svg';
import Video from '@plone/volto/components/manage/Blocks/Video/Body';
import {
  flattenToAppURL,
  isInternalURL,
  withBlockExtensions,
} from '@plone/volto/helpers';
import { compose } from 'redux';
import Caption from 'volto-dlr/components/Image/Caption';
import { toast } from 'react-toastify';
import Toast from '@plone/volto/components/manage/Toast/Toast';

const messages = defineMessages({
  VideoBlockInputPlaceholder: {
    id: 'Browse the site',
    defaultMessage: 'Browse the site',
  },
  IncorrectUrl: {
    id: 'Only Internal Video content type url is allowed.',
    defaultMessage: 'Only Internal Video content type url is allowed.',
  },
  IncorrectContentType: {
    id: 'Only Video content type url is allowed.',
    defaultMessage: 'Only Video content type url is allowed.',
  },
  IncorrectLink: {
    id: 'Please enter a valid link.',
    defaultMessage: 'Please enter a valid link.',
  },
  Error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
});

/**
 * Edit video block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onSubmitUrl = this.onSubmitUrl.bind(this);
    this.onKeyDownVariantMenuForm = this.onKeyDownVariantMenuForm.bind(this);
    this.state = {
      url: '',
    };
  }

  /**
   * Change url handler
   * @method onChangeUrl
   * @param {Object} target Target object
   * @returns {undefined}
   */
  onChangeUrl({ target }) {
    this.setState({
      url: target.value,
    });
  }

  /**
   * @param {*} nextProps
   * @returns {boolean}
   * @memberof Edit
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.selected ||
      nextProps.selected ||
      !isEqual(this.props.data, nextProps.data)
    );
  }

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @returns {undefined}
   */

  onSubmitUrl() {
    const url = flattenToAppURL(this.state.url);
    if (isInternalURL(url)) {
      this.props
        .getContent(url, null, this.props.block)
        .then((resp) => {
          if (resp['@type'] === 'Video') {
            this.props.onChangeBlock(this.props.block, {
              ...this.props.data,
              url: url,
              target_url: resp.target_url,
              title: resp.title,
              description: resp.description,
              credit: { data: resp.credit },
              image_scales: resp.image_scales,
            });
          } else {
            toast.error(
              <Toast
                error
                title={this.props.intl.formatMessage(messages.Error)}
                content={this.props.intl.formatMessage(
                  messages.IncorrectContentType,
                )}
              />,
            );
          }
        })
        .catch((err) => {
          toast.error(
            <Toast
              error
              title={this.props.intl.formatMessage(messages.Error)}
              content={this.props.intl.formatMessage(messages.IncorrectLink)}
            />,
          );
        });
    } else {
      toast.error(
        <Toast
          error
          title={this.props.intl.formatMessage(messages.Error)}
          content={this.props.intl.formatMessage(messages.IncorrectUrl)}
        />,
      );
    }
  }

  resetSubmitUrl = () => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      url: '',
      target_url: null,
    });
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  }

  openObjectBrowser() {
    let currentPath =
      this.props.intl.locale === 'de'
        ? '/de/medien/videos'
        : '/en/media/videos';
    const iNavRootInfo = this.props.iNavRootInfo;
    const subsite = iNavRootInfo.subsite;
    const isSubsite = !isEmpty(subsite);
    if (isSubsite) {
      currentPath =
        this.props.intl.locale === 'de'
          ? `${subsite['@id']}/medien/videos`
          : `${subsite['@id']}/media/videos`;
    }
    this.props.openObjectBrowser({
      mode: 'link',
      selectableTypes: ['Video'],
      currentPath: currentPath,
      onSelectItem: (url, item) => {
        this.props.onChangeBlock(this.props.block, {
          ...this.props.data,
          url: flattenToAppURL(url),
          title: item.Title,
          description: item.Description,
          target_url: item.getRemoteUrl,
          credit: { data: item.credit },
          image_scales: item.image_scales,
        });
      },
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { data } = this.props;
    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.VideoBlockInputPlaceholder);
    return (
      <div
        className={cx(
          'block video align',
          {
            selected: this.props.selected,
            center: !Boolean(this.props.data.align),
          },
          this.props.data.align,
        )}
      >
        {data.url ? (
          <figure className="video-inner">
            <Video data={data} />
            <Caption
              title={data.title}
              description={data.description}
              credit={data.credit?.data}
              shows_people={data.shows_people}
            />
          </figure>
        ) : (
          <div
            className={cx('video-inner', {
              'full-width': this.props.data.align === 'full',
            })}
          >
            <Message>
              <center>
                <img src={videoBlockSVG} alt="" />
                <div className="toolbar-inner">
                  <Button.Group>
                    <Button
                      basic
                      icon
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.openObjectBrowser();
                      }}
                    >
                      <Icon name={navTreeSVG} size="24px" />
                    </Button>
                  </Button.Group>
                  <Input
                    onKeyDown={this.onKeyDownVariantMenuForm}
                    onChange={this.onChangeUrl}
                    placeholder={placeholder}
                    value={this.state.url}
                    onClick={(e) => {
                      // Prevents propagation to the Dropzone and the opening
                      // of the upload browser dialog
                      e.stopPropagation();
                    }}
                  />
                  {this.state.url && (
                    <Button.Group>
                      <Button
                        basic
                        className="cancel"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ url: '' });
                        }}
                      >
                        <Icon name={clearSVG} size="30px" />
                      </Button>
                    </Button.Group>
                  )}
                  <Button.Group>
                    <Button
                      basic
                      primary
                      onClick={(e) => {
                        e.stopPropagation();
                        this.onSubmitUrl();
                      }}
                    >
                      <Icon name={aheadSVG} size="30px" />
                    </Button>
                  </Button.Group>
                </div>
              </center>
            </Message>
          </div>
        )}
        <SidebarPortal selected={this.props.selected}>
          <VideoSidebar {...this.props} resetSubmitUrl={this.resetSubmitUrl} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withBlockExtensions,
  connect(
    (state, ownProps) => ({
      iNavRootInfo: state.nearestInavRoot,
    }),
    { getContent },
  ),
)(Edit);

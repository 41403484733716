import { defineMessages } from 'react-intl';
import { defaultStylingSchema } from '../schema';

const messages = defineMessages({
  noLine: {
    id: 'Hide Separator Line',
    defaultMessage: 'Hide Separator Line',
  },
  align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
});

export const SeparatorStylingSchema = ({ schema, formData, intl }) => {
  defaultStylingSchema({ schema, formData, intl });

  schema.properties.styles.schema.fieldsets[0].fields = [
    'align',
    'noLine',
    'backgroundColor',
  ];
  schema.properties.styles.schema.properties.align = {
    widget: 'align',
    title: intl.formatMessage(messages.align),
    actions: ['left', 'full'],
    default: 'full',
  };

  schema.properties.styles.schema.properties.noLine = {
    title: intl.formatMessage(messages.noLine),
    type: 'boolean',
  };
  return schema;
};

import React from 'react';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { withBlockExtensions } from '@plone/volto/helpers';
import ImageGallery from '@plone/volto/components/manage/Blocks/Listing/ImageGallery';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const ImageSliderView = (props) => {
  const { className, data, isEditMode, slideIndex, setSlideIndex } = props;
  const intl = useIntl();
  const hasSlides = data.slides?.length > 0;
  const slides = data.slides?.length ? data.slides : [{}];
  const ref = React.useRef();

  // keep current slide in sync with sidebar
  React.useEffect(() => {
    if (ref.current && isEditMode) {
      ref.current.slideToIndex(slideIndex);
    }
  }, [slideIndex, isEditMode]);

  return (
    <div className={cx('block imageslider', className)}>
      {(hasSlides || isEditMode) && (
        <ImageGallery
          items={slides
            .map((slide) =>
              slide.href?.[0]?.['@id']
                ? {
                    ...slide.href[0],
                    title: slide.title,
                    description: slide.description,
                  }
                : isEditMode
                ? {
                    '@type': 'Image',
                    title: intl.formatMessage(messages.PleaseChooseContent),
                  }
                : null,
            )
            .filter(Boolean)}
          ref={ref}
          startIndex={slideIndex}
          onBeforeSlide={(index) => isEditMode && setSlideIndex(index)}
        />
      )}
    </div>
  );
};

export default withBlockExtensions(ImageSliderView);

/**
 * OVERRIDE DefaultTocRenderer.jsx
 * REASON: Remove h2 and simplify
 * FILE: https://github.com/plone/volto/blob/3a6bbdd4270db77fa9dd667c36678e7da730a72c/packages/volto/src/components/manage/Blocks/ToC/variations/DefaultTocRenderer.jsx
 * FILE VERSION: Volto 16.30.3
 * DATE: 2024-02-20
 * DEVELOPER: @danalvrz
 */
/**
 * View toc block.
 * @module components/manage/Blocks/ToC/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { List } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const RenderListItems = ({ items, data }) => {
  return map(items, (item) => {
    const { id, level, title } = item;
    return (
      item && (
        <List.Item key={id} className={`item headline-${level}`} as="li">
          <AnchorLink href={`#${id}`}>{title}</AnchorLink>
          {item.items?.length > 0 && (
            <List
              ordered={data.ordered}
              bulleted={!data.ordered}
              as={data.ordered ? 'ol' : 'ul'}
            >
              <RenderListItems items={item.items} data={data} />
            </List>
          )}
        </List.Item>
      )
    );
  });
};

/**
 * View toc block class.
 * @class View
 * @extends Component
 */
const View = ({ data, tocEntries }) => {
  return (
    <List
      ordered={data.ordered}
      bulleted={!data.ordered}
      as={data.ordered ? 'ol' : 'ul'}
    >
      <RenderListItems items={tocEntries} data={data} />
    </List>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(View);

/**
 * OVERRIDE querystring.js
 * REASON: Add option to fetch querystring info contextually.
 * FILE: https://github.com/plone/volto/blob/16.24.0/src/actions/querystring/querystring.js
 * FILE VERSION: Volto 16.24.0
 * TICKET: https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/2024
 * DATE: 2023-09-19
 * DEVELOPER: @davisagli
 */

/**
 * Querystring actions.
 * @module actions/querystring/querystring
 */

import { GET_QUERYSTRING } from '@plone/volto/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

/**
 * Get querystring.
 * @function getQuerystring
 * @returns {Object} Get querystring action.
 */
export function getQuerystring() {
  return (dispatch, getState) => {
    const state = getState();
    const url = flattenToAppURL(state.content.data?.['@id']);
    if (url && url !== state.querystring.context) {
      dispatch({
        type: GET_QUERYSTRING,
        context: url,
        request: {
          op: 'get',
          path: `${url}/@querystring`,
        },
      });
    }
  };
}

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { BlockDataForm, Icon } from '@plone/volto/components';
import { TeaserSchema } from '@kitconcept/volto-blocks-grid/components/Teaser/schema';
import { isEmpty } from 'lodash';
// import syncSVG from '@plone/volto/icons/sync.svg';
import trashSVG from '@plone/volto/icons/delete.svg';

const messages = defineMessages({
  resetTeaser: {
    id: 'Reset the block',
    defaultMessage: 'Reset the block',
  },
});

const TeaserData = (props) => {
  const {
    block,
    blocksConfig,
    data,
    onChangeBlock,
    contentType,
    navRoot,
  } = props;
  const intl = useIntl();

  // const syncronize = () => {
  //   onChangeBlock(block, {
  //     title: contentData.title,
  //     description: contentData.description,
  //     head_title: contentData.head_title,
  //   });
  // };

  const reset = () => {
    onChangeBlock(block, {
      ...data,
      href: '',
      title: '',
      description: '',
      head_title: '',
    });
  };

  const teaserDataAdapter = ({ block, data, id, onChangeBlock, value }) => {
    let dataSaved = {
      ...data,
      [id]: value,
    };
    if (id === 'href' && !isEmpty(value) && !data.title && !data.description) {
      dataSaved = {
        ...dataSaved,
        title: value[0].Title,
        description: value[0].Description,
        head_title: value[0].head_title,
      };
    }
    onChangeBlock(block, dataSaved);
  };

  const isReseteable =
    isEmpty(data.href) && !data.title && !data.description && !data.head_title;

  const HeaderActions = (
    <Button.Group>
      <Button
        aria-label={intl.formatMessage(messages.resetTeaser)}
        basic
        disabled={isReseteable}
        onClick={() => reset()}
      >
        <Icon name={trashSVG} size="24px" color="red" />
      </Button>
    </Button.Group>
  );
  const schema = TeaserSchema({ ...props, intl });

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        teaserDataAdapter({
          block,
          data,
          id,
          onChangeBlock,
          value,
        });
      }}
      onChangeBlock={onChangeBlock}
      formData={data}
      block={block}
      blocksConfig={blocksConfig}
      headerActions={HeaderActions}
      navRoot={navRoot}
      contentType={contentType}
    />
  );
};

export default TeaserData;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Infobox: {
    id: 'Infobox',
    defaultMessage: 'Infobox',
  },
  Button: {
    id: 'Button',
    defaultMessage: 'Button',
  },
  styling: {
    id: 'Styling',
    defaultMessage: 'Styling',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  buttonText: {
    id: 'Button Text',
    defaultMessage: 'Button Text',
  },
  buttonLink: {
    id: 'buttonLink',
    defaultMessage: 'Button Link',
  },
  alignment: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  svgbest: {
    id: 'It is advised to use an SVG graphic.',
    defaultMessage: 'It is advised to use an SVG graphic.',
  },
});

export const InfoboxBlockSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.Infobox),
    block: 'infobox',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['icon', 'title'],
      },
      {
        id: 'button',
        title: props.intl.formatMessage(messages.Button),
        fields: ['buttonText', 'buttonLink'],
      },
      {
        id: 'styles',
        title: props.intl.formatMessage(messages.styling),
        fields: ['align'],
      },
    ],
    properties: {
      icon: {
        title: props.intl.formatMessage(messages.icon),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: false,
        description: props.intl.formatMessage(messages.svgbest),
      },
      title: {
        title: props.intl.formatMessage(messages.title),
      },
      buttonText: {
        title: props.intl.formatMessage(messages.buttonText),
      },
      buttonLink: {
        title: props.intl.formatMessage(messages.buttonLink),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      align: {
        title: props.intl.formatMessage(messages.alignment),
        widget: 'inner_align',
        actions: ['left', 'right', 'center'],
        default: 'center',
      },
    },
    required: [],
  };
};

/**
 * OVERRIDE HorizontalMenu.jsx
 * REASON: Remove h2 and simplify
 * FILE: https://github.com/plone/volto/blob/3a6bbdd4270db77fa9dd667c36678e7da730a72c/packages/volto/src/components/manage/Blocks/ToC/variations/HorizontalMenu.jsx
 * FILE VERSION: Volto 16.30.3
 * DATE: 2024-02-20
 * DEVELOPER: @danalvrz
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Menu } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const RenderMenuItems = ({ items }) => {
  return map(items, (item) => {
    const { id, level, title } = item;
    return (
      item && (
        <React.Fragment key={id}>
          <Menu.Item className={`headline-${level}`}>
            <AnchorLink offset="60" href={`#${id}`}>
              {title}
            </AnchorLink>
          </Menu.Item>
          {item.items?.length > 0 && <RenderMenuItems items={item.items} />}
        </React.Fragment>
      )
    );
  });
};

/**
 * View toc block class.
 * @class View
 * @extends Component
 */
const View = ({ data, tocEntries }) => {
  useEffect(() => {
    if (data.sticky) {
      const toc = document.querySelector('.horizontalMenu');
      const tocPos = toc ? toc.offsetTop : 0;

      const handleScroll = () => {
        let scrollPos = window.scrollY;
        if (scrollPos > tocPos && toc) {
          toc.classList.add('sticky-toc');
        } else if (scrollPos <= tocPos && toc) {
          toc.classList.remove('sticky-toc');
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [data.sticky]);

  return (
    <Menu className="responsive-menu">
      <RenderMenuItems items={tocEntries} />
    </Menu>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(View);

/**
 * OVERRIDE View.jsx
 * REASON: Add id attribute to make Heading usable with ToC block
 * FILE: https://raw.githubusercontent.com/kitconcept/volto-heading-block/blob/main/src/components/View.jsx
 * FILE VERSION: volto-blocks-grid 2.4.0
 * DATE: 2024-01-16
 * DEVELOPER: @danalvrz
 */
import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import cx from 'classnames';
import config from '@plone/volto/registry';

const HeadingView = (props) => {
  const { className, data, id } = props;
  const Element = data.tag || 'h2';
  const show_alignment = config.blocks?.blocksConfig?.heading?.show_alignment;

  return (
    <>
      {data && (
        <div className={cx('block heading', className)}>
          <div className="heading-wrapper">
            {/* BEGIN CUSTOMIZATION */}
            <Element
              style={show_alignment ? { textAlign: data.alignment } : {}}
              className="heading"
              id={id}
            >
              {/* END CUSTOMIZATION */}
              {data?.heading || ''}
            </Element>
          </div>
        </div>
      )}
    </>
  );
};

export default withBlockExtensions(HeadingView);

/**
 * EventView view component.
 * @module components/theme/View/EventView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { Image, Container as SemanticContainer } from 'semantic-ui-react';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import config from '@plone/volto/registry';
import { FormattedMessage, injectIntl } from 'react-intl';
import EventDates from 'volto-dlr/components/Blocks/EventMetadata/EventDates';

const EventTextfieldView = ({ content }) => (
  <React.Fragment>
    {content.title && <h1 className="documentFirstHeading">{content.title}</h1>}
    {content.description && (
      <p className="documentDescription">{content.description}</p>
    )}
    {content.image && (
      <Image
        className="document-image"
        src={content.image.scales.thumb.download}
        floated="right"
      />
    )}
    {content.text && (
      <div
        dangerouslySetInnerHTML={{
          __html: flattenHTMLToAppURL(content.text.data),
        }}
      />
    )}
  </React.Fragment>
);

/**
 * EventView view component class.
 * @function EventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventView = (props) => {
  const { content } = props;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  return (
    <Container id="page-document" className="view-wrapper event-view">
      <div className="dates">
        {content?.is_public && (
          <span className="tag">
            <FormattedMessage id="Public" defaultMessage="Public" />
          </span>
        )}
        <EventDates content={content} />
      </div>
      {hasBlocksData(content) ? (
        <>
          <RenderBlocks {...props} />
        </>
      ) : (
        <EventTextfieldView {...props} />
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    end: PropTypes.string,
    event_url: PropTypes.string,
    location: PropTypes.string,
    start: PropTypes.string.isRequired,
  }).isRequired,
};
export default injectIntl(EventView);

/**
 * Longpager view component.
 * @module components/Theme/LongpagerView
 */

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { filter, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageSelector } from '@plone/volto/components';
import { Container } from 'semantic-ui-react';
import {
  BodyClass,
  hasBlocksData,
  flattenToAppURL,
  getBaseUrl,
} from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { Logo } from '@plone/volto/components';
import { PreviewImg } from 'volto-dlr/helpers';
import { getService } from 'volto-dlr/actions/getservice/getservice';
import {
  FormattedMessage,
  useIntl,
  injectIntl,
  defineMessages,
} from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  scrollTop: {
    id: 'scroll to top',
    defaultMessage: 'scroll to top',
  },
});

/**
 * Longpager view component class.
 * @function LongpagerView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const LongpagerView = (props) => {
  const { content, location } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const pathname = props.location.pathname;
  const lang = useSelector((state) => state.intl.locale);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const [menu, setMenu] = useState(0);
  const menuRef = useRef(null);
  const containerRef = useRef(null);
  const scrollProgressRef = useRef(null);
  const menuCallback = useCallback((node) => {
    if (node !== null) {
      setMenuHeight(node.getBoundingClientRect().height);
    }
    return node;
  }, []);
  const previewImg = content.preview_image_link
    ? `${content.preview_image_link['@id']}/${content.preview_image_link.image_scales.image[0].download}`
    : '';
  const items = filter(content.items, { '@type': 'Document' });

  const [fullItems, setFullItems] = useState([]);
  useEffect(() => {
    dispatch(getService(`${pathname}?fullobjects=1`)).then((result) => {
      setFullItems(filter(result.items, { '@type': 'Document' }));
    });
  }, [dispatch, pathname]);

  useEffect(() => {
    const height = window.innerHeight;
    const menuOffset = (height - menuHeight) / 2;
    if (menuRef.current) {
      menuRef.current.style.top = `${menuOffset}px`;
    }
  }, [menuHeight, menuRef]);

  useEffect(() => {
    const onScroll = () => {
      const position = document.documentElement.scrollTop;
      let currentPage = 0;
      const calcHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollValue = Math.round((position * 100) / calcHeight);

      if (scrollProgressRef.current) {
        scrollProgressRef.current.firstChild.style.background = `conic-gradient(#6cb9dc ${scrollValue}%, #fff ${scrollValue}%)`;
      }

      if (containerRef.current) {
        const children = containerRef.current.children;
        for (let i = 0; i < children.length; i += 1) {
          if (children[i].getBoundingClientRect().top < 4) {
            currentPage = i;
            continue;
          }
          break;
        }
      }

      setMenu(currentPage);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const toggleMobileMenu = (e) => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMenus = (e) => {
    e.stopPropagation();
    setIsMobileMenuOpen(false);
  };

  const scrollToElement = (elementID) => {
    let nIntervId;

    const stopInterval = () => {
      clearInterval(nIntervId);
      // release our intervalID from the variable
      nIntervId = null;
    };

    const scroll = () => {
      // check if an interval has already been set up
      if (!nIntervId) {
        nIntervId = setInterval(scrollTo, 12);
      }
    };

    const scrollTo = () => {
      const currentY = document.documentElement.scrollTop;
      const targetElement = document.getElementById(elementID);
      const targetPosition = targetElement?.getBoundingClientRect().top;
      if (Math.abs(targetPosition) > 800) {
        const scrollDirection = targetPosition > 0 ? 200 : -200;
        window.scrollTo(0, currentY + scrollDirection);
      } else {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        stopInterval();
      }
    };

    scroll();
    window.addEventListener('wheel', stopInterval);
    window.addEventListener('touchmove', stopInterval);
  };

  return (
    <>
      <BodyClass className="view-longpager" />
      <div className="longpager-wrapper">
        <div className="longpager-viewport">
          <div className="longpager-container" ref={containerRef}>
            <div className="longpager-page cover">
              <div className="longpager-background">
                {content.show_overlay && <div className="overlay" />}
                {!content.video_link && !content.video_url && (
                  <PreviewImg
                    previewImage={content.preview_image_link}
                    imageField="image"
                    targetWidth="full"
                  />
                )}
                {(content.video_link || content.video_url) && (
                  <video loop autoPlay="autoplay" muted>
                    <source
                      src={
                        content.video_link
                          ? `${content.video_link['@id']}/@@download/file`
                          : content.video_url
                      }
                      type="video/mp4"
                      poster={previewImg}
                    />
                  </video>
                )}
              </div>
              <div className="longpager-header desktop" id={content.id}>
                <LanguageSelector />
                <div className="logo">
                  <Logo
                    isSubsite
                    iNavRootInfo={{ subsite: { '@id': `/${lang}` } }}
                  />
                </div>
                <div className="scroll-wrapper">
                  <div className="scroll">
                    <a
                      href="#longpager-content"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        scrollToElement('longpager-content');
                      }}
                    >
                      <div>
                        <FormattedMessage id="Next" defaultMessage="Next" />
                        <svg
                          width="35"
                          height="18"
                          viewBox="0 0 28 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 1L14 14L27 1" stroke="white" />
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="titles">
                  <div className="head_title">
                    <h2>{content.head_title}</h2>
                  </div>
                  <div className="title">
                    <h1>{content.title}</h1>
                  </div>
                </div>
              </div>
              <div className="longpager-header mobile" id={content.id}>
                <div className="logo">
                  <Logo
                    isSubsite
                    iNavRootInfo={{ subsite: { '@id': `/${lang}` } }}
                  />
                </div>
              </div>
              <Container id="longpager-content">
                {hasBlocksData(content) && (
                  <RenderBlocks
                    {...props}
                    path={getBaseUrl(location?.pathname || '')}
                  />
                )}
              </Container>
            </div>
            {map(fullItems, (item, index) => {
              return (
                <div className="longpager-page" key={item.id}>
                  <div className="longpager-background">
                    <div className="overlay" />
                    <PreviewImg
                      previewImage={item.preview_image_link}
                      imageField="image"
                      scaleName="fullscreen"
                      targetWidth="full"
                    />
                  </div>
                  <div className="longpager-header" id={item.id}>
                    <div className="titles">
                      <div className="head_title">
                        <h2>{item.head_title}</h2>
                      </div>
                      <div className="title">
                        <h1>{item.title}</h1>
                      </div>
                    </div>
                  </div>
                  <Container>
                    {hasBlocksData(item) && (
                      <RenderBlocks
                        content={item}
                        path={flattenToAppURL(item['@id']) || ''}
                      />
                    )}
                  </Container>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {items.length > 0 && (
        <>
          <div className="longpager-menu desktop" ref={menuRef}>
            <ul ref={menuCallback} role="presentation">
              <li className={menu === 0 ? 'current' : ''}>
                <a
                  href={`#${content.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    scrollToElement(content.id);
                  }}
                >
                  {content.nav_title || content.title}
                </a>
              </li>
              {items.map((item, i) => {
                const id = item['@id'].split('/').slice(-1)[0];
                return (
                  <li
                    key={id}
                    className={
                      content.highlight_last_menu_option &&
                      i === items.length - 1
                        ? 'inverted'
                        : menu === i + 1
                        ? 'current'
                        : ''
                    }
                  >
                    <a
                      href={`#${id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        scrollToElement(id);
                      }}
                    >
                      <span>{item.head_title}</span>
                      {item.nav_title || item.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="longpager-menu mobile-nav mobile only tablet only">
            <div className="hamburger-wrapper">
              <button
                className={cx('hamburger hamburger--collapse', {
                  'is-active': isMobileMenuOpen,
                })}
                aria-label={
                  isMobileMenuOpen
                    ? intl.formatMessage(messages.closeMobileMenu)
                    : intl.formatMessage(messages.openMobileMenu)
                }
                title={
                  isMobileMenuOpen
                    ? intl.formatMessage(messages.closeMobileMenu)
                    : intl.formatMessage(messages.openMobileMenu)
                }
                type="button"
                onClick={toggleMobileMenu}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
            <div className="menu-logo">
              <Logo
                isSubsite
                iNavRootInfo={{ subsite: { '@id': `/${lang}` } }}
              />
            </div>

            <CSSTransition
              in={isMobileMenuOpen}
              timeout={500}
              classNames="menu-drawer"
              unmountOnExit
            >
              <div className="menu-drawer">
                <BodyClass className="has-menu-open" />
                <ul className="sections" role="presentation">
                  <li className="header">
                    <a
                      href={`#${content.id}`}
                      onClick={(e) => {
                        closeMenus(e);
                        e.preventDefault();
                        scrollToElement(content.id);
                      }}
                    >
                      {content.nav_title || content.title}
                    </a>
                  </li>
                  {items.map((item, i) => {
                    const id = item['@id'].split('/').slice(-1)[0];
                    return (
                      <li key={id}>
                        <a
                          href={`#${id}`}
                          onClick={(e) => {
                            closeMenus(e);
                            e.preventDefault();
                            scrollToElement(id);
                          }}
                        >
                          <span>{item.head_title}</span>
                          {item.nav_title || item.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <LanguageSelector />
              </div>
            </CSSTransition>
          </div>
          <div className="fast-navigation-mobile">
            <div className="titles">
              {menu === 0 ? (
                <h2 className="content-title">{content.title}</h2>
              ) : (
                <>
                  <h3>{items[menu - 1].head_title}</h3>
                  <h2>{items[menu - 1].title}</h2>
                </>
              )}
            </div>
            <div className="arrow-buttons">
              {menu > 0 &&
                (menu === 1 ? (
                  <a
                    href={`#${content.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      scrollToElement(content.id);
                    }}
                  >
                    <svg
                      className="arriba"
                      width="42"
                      height="22"
                      viewBox="0 0 42 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M41 21L21 0.999998L0.999999 21"
                        stroke="#B1B1B1"
                      />
                    </svg>
                  </a>
                ) : (
                  <a
                    href={`#${items[menu - 2]['@id'].split('/').slice(-1)[0]}`}
                    key={items[menu - 2]['@id'].split('/').slice(-1)[0]}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      scrollToElement(
                        items[menu - 2]['@id'].split('/').slice(-1)[0],
                      );
                    }}
                  >
                    <svg
                      className="arriba"
                      width="42"
                      height="22"
                      viewBox="0 0 42 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M41 21L21 0.999998L0.999999 21"
                        stroke="#B1B1B1"
                      />
                    </svg>
                  </a>
                ))}

              {menu < items.length && (
                <a
                  href={`#${items[menu]['@id'].split('/').slice(-1)[0]}`}
                  key={items[menu]['@id'].split('/').slice(-1)[0]}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    scrollToElement(items[menu]['@id'].split('/').slice(-1)[0]);
                  }}
                >
                  <svg
                    width="41"
                    height="22"
                    viewBox="0 0 41 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0.5 1L20.5 21L40.5 1" stroke="#B1B1B1" />
                  </svg>
                </a>
              )}
            </div>
          </div>
        </>
      )}
      <div role="complementary" ref={scrollProgressRef}>
        <a
          aria-label={intl.formatMessage(messages.scrollTop)}
          className="progress-wrapper"
          href="#main"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            scrollToElement('main');
          }}
        >
          <svg
            className="scroll-to-top"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="triangle"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.707 19.293L23.293 20.707L18 15.414L12.707 20.707L11.293 19.293L18 12.586L24.707 19.293Z"
            />
          </svg>
        </a>
      </div>
    </>
  );
};

export default injectIntl(LongpagerView);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { injectIntl } from 'react-intl';
import { Icon, FormFieldWrapper, Toast } from '@plone/volto/components';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import copySVG from '@plone/volto/icons/copy.svg';

const messages = defineMessages({
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  messageCopied: {
    id: 'Link URL copied.',
    defaultMessage: 'Link URL copied.',
  },
});

/**
 * The simple text widget.
 *
 * It is the default fallback widget, so if no other widget is found based on
 * passed field properties, it will be used.
 */
class AnchorWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    focus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    icon: PropTypes.shape({
      xmlns: PropTypes.string,
      viewBox: PropTypes.string,
      content: PropTypes.string,
    }),
    iconAction: PropTypes.func,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    wrapped: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    description: null,
    required: false,
    error: [],
    value: null,
    onChange: () => {},
    onBlur: () => {},
    onClick: () => {},
    onEdit: null,
    onDelete: null,
    focus: false,
    icon: null,
    iconAction: null,
    minLength: null,
    maxLength: null,
  };

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.props.focus) {
      this.node.focus();
    }
  }

  onCopy(link) {
    navigator.clipboard.writeText(link);
    this.props.toastify.toast.success(
      <Toast
        success
        title={this.props.intl.formatMessage(messages.success)}
        content={this.props.intl.formatMessage(messages.messageCopied)}
      />,
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      id,
      value,
      onChange,
      onBlur,
      onClick,
      minLength,
      maxLength,
      placeholder,
      isDisabled,
      path,
    } = this.props;

    const link = `${path}#${value}`;

    return (
      <FormFieldWrapper {...this.props} className="text">
        <Input
          id={`field-${id}`}
          name={id}
          value={value || ''}
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={({ target }) => {
            let value = target.value;
            value = value.replace(/[^a-zA-Z0-9-_:.]/gi, '');
            return onChange(id, value === '' ? undefined : value);
          }}
          ref={(node) => {
            this.node = node;
          }}
          onBlur={({ target }) => {
            let value = target.value;
            value = value.replace(/[^a-zA-Z0-9-_:.]/gi, '');
            return onBlur(id, value === '' ? undefined : value);
          }}
          onClick={() => onClick()}
          minLength={minLength || null}
          maxLength={maxLength || null}
        />
        <div
          className="copy-link-url"
          title={link}
          onClick={() => this.onCopy(link)}
          onKeydown={() => this.onCopy(link)}
          role="button"
          tabIndex="0"
        >
          <Icon name={copySVG} size="24px" />
          &nbsp;
          <FormattedMessage id="Copy link URL" defaultMessage="Copy link URL" />
        </div>
      </FormFieldWrapper>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      path: state.content.data?.['@id'],
    }),
    {},
  ),
  injectLazyLibs(['toastify']),
)(AnchorWidget);

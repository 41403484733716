/**
 * Subsite reducer.
 * @module reducers/subsite/subsite
 */

import { GET_CONTENT } from '@plone/volto/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

const initialState = {
  error: null,
  lrf: {},
  subsite: {},
  loaded: false,
  loading: false,
};

function atidFlattenToAppURL(obj) {
  if (obj['@id']) {
    return {
      ...obj,
      '@id': flattenToAppURL(obj['@id']),
    };
  }
  return obj;
}

export default function nearestInavRoot(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_CONTENT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_CONTENT}_SUCCESS`:
      if (!action.subrequest || action.subrequest === 'navRoot') {
        let subsite, lrf;
        if (action.result?.['@components']?.nearest_inav_root?.lrf) {
          lrf = action.result['@components'].nearest_inav_root.lrf;
          subsite = action.result['@components'].nearest_inav_root.subsite;
        } else {
          lrf = action.result.lrf || {};
          subsite = action.result.subsite || {};
        }

        return {
          ...state,
          error: null,
          lrf: atidFlattenToAppURL(lrf),
          subsite: atidFlattenToAppURL(subsite),
          loaded: true,
          loading: false,
        };
      }
      return state;
    case `${GET_CONTENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        // Do not remove the content of the existing subsite if the request FAIL
        // lrf: {},
        // subsite: {},
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}

import { ObjectListWidget } from '@plone/volto/components';

const JSONListWidget = (props) => {
  const { items } = props;

  // This is not ideal, but it makes it work
  // without storing the @id on the backend.

  const value = props.value.map((item, i) => ({
    ...item,
    '@id': String.valueOf(i),
  }));

  const onChange = (key, newValue) => {
    props.onChange(
      key,
      newValue.map((item) => {
        const { '@id': _, ...newItem } = item;
        return newItem;
      }),
    );
  };

  return (
    <ObjectListWidget
      {...props}
      value={value}
      schema={items}
      onChange={onChange}
    />
  );
};

export default JSONListWidget;

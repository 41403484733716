import { defineMessages } from 'react-intl';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import { removeFromArray } from '@plone/volto/helpers/Utils/Utils';
import { addExtensionFieldToSchema } from '@plone/volto/helpers/Extensions';

import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

import config from '@plone/volto/registry';

const messages = defineMessages({
  highlightBlockTitle: {
    id: 'highlightBlockTitle',
    defaultMessage: 'Highlight',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  head_title: {
    id: 'Headtitle',
    defaultMessage: 'Headtitle',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  height: {
    id: 'Height',
    defaultMessage: 'Height',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  button: {
    id: 'Button',
    defaultMessage: 'Button',
  },
  buttonLink: {
    id: 'buttonLink',
    defaultMessage: 'Button Link',
  },
  buttonText: {
    id: 'buttonText',
    defaultMessage: 'Button Text',
  },
  descriptionColor: {
    id: 'descriptionColor',
    defaultMessage: 'Description color',
  },
  aspectRatio: {
    id: 'Aspect ratio',
    defaultMessage: 'Aspect ratio',
  },
  credit: {
    id: 'Show credit',
    defaultMessage: 'Show credit',
  },
  variation: {
    id: 'Variation',
    defaultMessage: 'Variation',
  },
  flagAlign: {
    id: 'Flag align',
    defaultMessage: 'Flag align',
  },
});

export function HighlightSchema(props) {
  const { intl } = props;
  const imageUrl = isInternalURL(props.data.url)
    ? flattenToAppURL(props.data.url)
    : props.data.url;

  return {
    title: props.intl.formatMessage(messages.highlightBlockTitle),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'aspectRatio',
          'url',
          'head_title',
          'button',
          'buttonLink',
          'buttonText',
          'showCredit',
        ],
      },
    ],
    properties: {
      aspectRatio: {
        widget: 'select',
        title: intl.formatMessage(messages.aspectRatio),
        choices: [
          ['aspectRatio16_9', '16:9'],
          ['aspectRatio24_11', '24:11'],
        ],
        default: 'aspectRatio16_9',
      },
      url: {
        title: intl.formatMessage(messages.image),
        value: imageUrl,
        icon: props.data.url ? clearSVG : navTreeSVG,
        iconAction: props.data.url
          ? () => {
              props.resetSubmitUrl();
              props.onChangeBlock(props.block, {
                ...props.data,
                url: '',
                image_scales: undefined,
              });
            }
          : () =>
              props.openObjectBrowser({
                onSelectItem: props.onSelectItem,
              }),
      },
      head_title: {
        title: intl.formatMessage(messages.head_title),
      },
      showCredit: {
        title: intl.formatMessage(messages.credit),
        type: 'boolean',
      },
      button: {
        title: intl.formatMessage(messages.button),
        type: 'boolean',
      },
      buttonLink: {
        title: intl.formatMessage(messages.buttonLink),
        widget: 'object_browser',
        allowExternals: true,
        mode: 'link',
      },
      buttonText: {
        title: intl.formatMessage(messages.buttonText),
        widget: 'textarea',
      },
    },
    required: [],
  };
}

export const HighlightStylingSchema = ({ formData, schema, intl, navRoot }) => {
  addStyling({ schema, intl });

  const descriptionColors =
    config.blocks?.blocksConfig?.[formData['@type']]?.descriptionColors;

  schema.properties.styles.schema.fieldsets[0].fields = ['descriptionColor'];
  schema.properties.styles.schema.properties.descriptionColor = {
    title: intl.formatMessage(messages.descriptionColor),
    widget: 'color_picker',
    colors: descriptionColors,
    default: 'blue3',
  };

  // Remove styling & Image Credits section for the Highlight but only if we are in the karriere portal
  if (
    navRoot?.['@id']?.startsWith('/de/karriere') ||
    navRoot?.['@id']?.startsWith('/en/careers')
  ) {
    delete schema.properties.styles;
    schema.fieldsets = removeFromArray(
      schema.fieldsets,
      schema.fieldsets.indexOf('styling'),
    );
    schema.fieldsets[0].fields = removeFromArray(
      schema.fieldsets[0].fields,
      schema.fieldsets[0].fields.indexOf('showCredit'),
    );
    if (formData.variation !== 'verteilerseite') {
      schema.fieldsets[0].fields.push('flagAlign');
      schema.properties.flagAlign = {
        widget: 'inner_align',
        title: intl.formatMessage(messages.flagAlign),
        actions: ['left', 'right'],
        default: 'left',
      };
    }

    const variations = [
      {
        id: 'default',
        title: 'Default',
        isDefault: true,
      },
      {
        id: 'verteilerseite',
        title: 'Verteilerseite',
        isDefault: false,
      },
    ];
    schema = addExtensionFieldToSchema({
      schema,
      name: 'variation',
      items: variations,
      intl,
      title: messages.variation,
    });
  }

  // Remove styling for the Highlight if we are in the school_lab or next portal
  if (
    navRoot?.['@id']?.startsWith('/de/schoollab') ||
    navRoot?.['@id']?.startsWith('/en/schoollab') ||
    navRoot?.['@id']?.startsWith('/de/next') ||
    navRoot?.['@id']?.startsWith('/en/next')
  ) {
    delete schema.properties.styles;
    schema.fieldsets = removeFromArray(
      schema.fieldsets,
      schema.fieldsets.indexOf('styling'),
    );
  }

  return schema;
};

/**
 * OVERRIDE NewsItemView.jsx
 * REASON: DLR addition: attachment link
 * FILE: https://github.com/plone/volto/blob/37f41c3037f2beee366210688b345ca74c032524/src/components/theme/View/NewsItemView.jsx
 * FILE VERSION: Volto 16.11.0
 * DATE: 2023-02-16
 * DEVELOPER: @davisagli
 */

/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { flattenToAppURL } from '@plone/volto/helpers';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content }) => (
  <div id="page-document" className="ui container viewwrapper event-view">
    <RenderBlocks content={content} />
    {content.file?.download && (
      <div className="slate">
        <p>
          <FormattedMessage
            id="NewsArchiveIntro"
            defaultMessage="This archived news item is available as a PDF document. Please click on the link below to download it."
          />
        </p>
        <p>
          <a href={flattenToAppURL(content.file.download)}>
            {content.file.filename}
          </a>{' '}
          <span>
            (
            {(() => {
              switch (content?.file['content-type']) {
                case 'image/jpeg':
                  return 'JPEG';
                case 'image/png':
                  return 'PNG';
                case 'image/svg+xml':
                  return 'SVG';
                case 'image/gif':
                  return 'GIF';
                case 'application/pdf':
                  return 'PDF';
                case 'application/msexcel':
                  return 'XLS';
                case 'application/vnd.ms-excel':
                  return 'XLS';
                case 'application/msword':
                  return 'DOC';
                case 'application/mspowerpoint':
                  return 'PPT';
                case 'audio/mp4':
                  return 'MP4';
                case 'application/zip':
                  return 'ZIP';
                case 'video/webm':
                  return 'WEBM';
                case 'video/x-msvideo':
                  return 'AVI';
                case 'video/x-sgi-movie':
                  return 'MOVIE';
                case 'text/xml':
                  return 'XML';
                case 'text/plain':
                  return 'TXT';
                case 'text/calendar':
                  return 'ICS';
                case 'image/x-icon':
                  return 'ICO';
                case 'image/bmp':
                  return 'BMP';
                case 'audio/mpeg':
                  return 'MP3';
                case 'audio/wav':
                  return 'WAV';
                case 'application/json':
                  return 'JSON';
                case 'application/postscript':
                  return 'PS';
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  return 'XLSX';
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  return 'DOCX';
                case 'application/xml':
                  return 'XML';
                case 'application/mshelp':
                  return 'HLP';
                case 'application/gzip':
                  return 'GZ';
                default:
                  return '';
              }
            })()}{' '}
            /{' '}
            {content.file?.size < 1000000
              ? Math.round(content.file.size / 1000)
              : Math.round(content.file.size / 1000000)}
            {content.file?.size < 1000000 ? 'KB' : 'MB'})
          </span>
        </p>
      </div>
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;

import React from 'react';
import { v4 as uuid } from 'uuid';
import { SidebarPortal } from '@plone/volto/components';
import ContactListData from './Data';
import ContactListView from './View';

const ContactListEdit = (props) => {
  const { selected } = props;

  const data = {
    hrefList: [{ '@id': uuid(), href: [] }],
    ...props.data,
  };

  return (
    <>
      <ContactListView {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <ContactListData {...props} data={data} />
      </SidebarPortal>
    </>
  );
};

export default ContactListEdit;

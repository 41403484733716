import authors from './blog/authors';
import tags from './blog/tags';
import contactform from './contactform/contactform';
import solrsearch from './solrsearch/solrsearch';

const reducers = {
  authors,
  tags,
  contactform,
  solrsearch,
};

export default reducers;

import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getAuthors } from 'volto-dlr/actions/blog/authors';

/**
 * AuthorProfileView view component class.
 * @function AuthorProfileView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const AuthorProfileView = ({ content }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const authors = useSelector((store) => store.authors);

  useEffect(() => {
    dispatch(getAuthors(location.pathname));
  }, [dispatch, location]);

  content = { ...content, authors };
  return (
    <div
      id="page-document"
      className="ui container view-wrapper authorprofile-view"
    >
      <RenderBlocks content={content} />
      <div className="blog-footer">
        <div className="footer-wrapper">
          <div className="separator-wrapper">
            <div className="line"></div>
          </div>
          <div className="heading-wrapper">
            <h2 className="heading">
              <FormattedMessage id="Posts by" defaultMessage="Posts by" />{' '}
              {content?.title}
            </h2>
          </div>
          <RenderBlocks
            content={{
              blocks: {
                'dcdf1f42-645d-48f6-9531-357bdc2e1881': {
                  '@type': 'listing',
                  querystring: {
                    query: [
                      {
                        i: 'Creator',
                        o: 'plone.app.querystring.operation.selection.any',
                        v: [`${content?.UID}`],
                      },
                      {
                        i: 'portal_type',
                        o: 'plone.app.querystring.operation.selection.any',
                        v: ['Post'],
                      },
                    ],
                    sort_on: 'effective',
                    sort_order: 'descending',
                  },
                  variation: 'blogListing',
                },
              },
              blocks_layout: {
                items: ['dcdf1f42-645d-48f6-9531-357bdc2e1881'],
              },
            }}
            path="/"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AuthorProfileView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default AuthorProfileView;

import { flattenToAppURL } from '@plone/volto/helpers';

const GET_TAGS = 'GET_TAGS';

/**
 * Get blog tags mapping
 * @function getTags
 * @param {string} blogUrl URL of blog.
 * @returns {Object} Action.
 */
export function getTags(blogUrl) {
  return {
    type: GET_TAGS,
    blogUrl,
    request: {
      op: 'get',
      path: `${flattenToAppURL(
        blogUrl,
      )}/@vocabularies/collective.blog.tags?b_size=1000`,
    },
  };
}

export default GET_TAGS;

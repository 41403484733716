import { useIntl, defineMessages } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import cx from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import { Icon } from '@plone/volto/components';

import rocketSVG from 'volto-dlr/icons/rocket_cta.svg';

const messages = defineMessages({
  ButtonText: {
    id: 'Button text',
    defaultMessage: 'Button text',
  },
});

const StickyButton = ({ data }) => {
  const { sticky_action_url, sticky_action_title } = data;
  const [hasLink, setHasLink] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (sticky_action_url) {
      if (sticky_action_url && sticky_action_url.length > 0) {
        setHasLink(true);
      }
      if (sticky_action_url.length === 0) {
        setHasLink(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sticky_action_url]);

  useEffect(() => {
    const sticky_button = document.querySelector('.sticky-button-container');
    const sticky_buttonPos = sticky_button ? sticky_button.offsetTop : 0;

    const handleScroll = () => {
      let scrollPos = window.scrollY - 500;
      if (scrollPos > sticky_buttonPos && sticky_button) {
        sticky_button.classList.add('animate');
      } else if (scrollPos <= sticky_buttonPos && sticky_button) {
        sticky_button.classList.remove('animate');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let link = hasLink ? (
    sticky_action_url.length > 0 && isInternalURL(sticky_action_url) ? (
      <Button
        as={Link}
        to={
          sticky_action_url.length > 0 ? flattenToAppURL(sticky_action_url) : ''
        }
        className={'sticky-button'}
      >
        <div className="icon-container">
          <Icon title="rocket" name={rocketSVG} size="56px" />
        </div>
        {sticky_action_title || intl.formatMessage(messages.ButtonText)}
      </Button>
    ) : (
      sticky_action_url.length > 0 && (
        <>
          <Button
            as="a"
            href={flattenToAppURL(sticky_action_url)}
            target="_blank"
            rel="noreferrer"
            className={'sticky-button'}
          >
            <div className="icon-container">
              <Icon title="rocket" name={rocketSVG} size="72px" />
            </div>
            {sticky_action_title || intl.formatMessage(messages.ButtonText)}
          </Button>
        </>
      )
    )
  ) : (
    <Button className="noLink sticky-button">
      <div className="icon-ciontainer">
        <Icon title="rocket" name={rocketSVG} size="56px" />
      </div>
      {sticky_action_title || intl.formatMessage(messages.ButtonText)}
    </Button>
  );
  return <div className={cx('sticky-button-container')}>{link}</div>;
};

export default StickyButton;

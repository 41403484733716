import { defineMessages } from 'react-intl';
import { v4 as uuid } from 'uuid';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  addLocation: {
    id: 'Add location',
    defaultMessage: 'Add location',
  },
  location: {
    id: 'Location',
    defaultMessage: 'Location',
  },
  locations: {
    id: 'Locations',
    defaultMessage: 'Locations',
  },
  latitude: {
    id: 'Latitude',
    defaultMessage: 'Latitude',
  },
  longitude: {
    id: 'Longitude',
    defaultMessage: 'Longitude',
  },
  tooltip: {
    id: 'Tooltip',
    defaultMessage: 'Tooltip',
  },
  Target: {
    id: 'Target',
    defaultMessage: 'Target',
  },
  Map: {
    id: 'Map Diagram',
    defaultMessage: 'Map Diagram',
  },
});

export const itemSchema = (props) => ({
  title: props.intl.formatMessage(messages.location),
  addMessage: props.intl.formatMessage(messages.addLocation),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'latitude', 'longitude', 'href', 'tooltip'],
    },
  ],

  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
    },
    latitude: {
      title: props.intl.formatMessage(messages.latitude),
      type: 'number',
    },
    longitude: {
      title: props.intl.formatMessage(messages.longitude),
      type: 'number',
    },
    href: {
      title: props.intl.formatMessage(messages.Target),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description', '@type'],
      allowExternals: true,
    },
    tooltip: {
      title: props.intl.formatMessage(messages.tooltip),
      widget: 'richtext',
    },
  },
  required: [],
});

export const MapSchema = (props) => ({
  title: props.intl.formatMessage(messages.Map),
  block: 'map',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['locations'],
    },
  ],
  properties: {
    locations: {
      widget: 'object_list',
      title: props.intl.formatMessage(messages.locations),
      schema: itemSchema(props),
      default: [{ '@id': uuid() }],
    },
  },
  required: [],
});

import React, { useState, useEffect } from 'react';
import { includes } from 'lodash';
import { Checkbox, Form, Button } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';
import config from '@plone/volto/registry';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const messages = defineMessages({
  technically_required: {
    id: 'CMS Plone (provided by DLR e.V)',
    defaultMessage: 'CMS Plone (provided by DLR e.V)',
  },
  tracking: {
    id: 'Tracking',
    defaultMessage: 'Tracking',
  },
});

const View = (props) => {
  const modules = config.settings.DSGVOBanner.modules;
  const [cookies, setCookie, removeCookie] = useCookies();
  const intl = useIntl();
  let history = useHistory();

  const [confirmTracking, setConfirmTracking] = useState(
    !!Number(cookies.confirm_tracking),
  );
  const [confirmYoutube, setConfirmYoutube] = useState(
    !!Number(cookies.confirm_youtube),
  );
  const [confirmVimeo, setConfirmVimeo] = useState(
    !!Number(cookies.confirm_vimeo),
  );
  const [confirmQuickchannel, setConfirmQuickchannel] = useState(
    !!Number(cookies.confirm_quickchannel),
  );
  const [confirmFacebook, setConfirmFacebook] = useState(
    !!Number(cookies.confirm_facebook),
  );
  const [confirmGoogle, setConfirmGoogle] = useState(
    !!Number(cookies.confirm_google),
  );

  const [confirmGoogleMaps, setConfirmGoogleMaps] = useState(
    !!Number(cookies.confirm_gmaps),
  );

  const [confirmOpenStreetMaps, setConfirmOpenStreetMaps] = useState(
    !!Number(cookies.confirm_osm),
  );

  const [confirmSoundcloud, setConfirmSoundcloud] = useState(
    !!Number(cookies.confirm_soundcloud),
  );

  const [confirmPodcaster, setConfirmPodcaster] = useState(
    !!Number(cookies.confirm_podcaster),
  );

  const [confirmTwitter, setConfirmTwitter] = useState(
    !!Number(cookies.confirm_twitter),
  );

  const [confirmInstagram, setConfirmInstagram] = useState(
    !!Number(cookies.confirm_instagram),
  );

  //update toggles when cookie consent is changed via video Blocks directly
  useEffect(() => {
    setConfirmYoutube(!!Number(cookies.confirm_youtube));
  }, [cookies.confirm_youtube]);
  useEffect(() => {
    setConfirmVimeo(!!Number(cookies.confirm_vimeo));
  }, [cookies.confirm_vimeo]);
  useEffect(() => {
    setConfirmQuickchannel(!!Number(cookies.confirm_quickchannel));
  }, [cookies.confirm_quickchannel]);

  useEffect(() => {
    setConfirmGoogleMaps(!!Number(cookies.confirm_gmaps));
  }, [cookies.confirm_gmaps]);

  useEffect(() => {
    setConfirmOpenStreetMaps(!!Number(cookies.confirm_osm));
  }, [cookies.confirm_osm]);

  useEffect(() => {
    setConfirmSoundcloud(!!Number(cookies.confirm_soundcloud));
  }, [cookies.confirm_soundcloud]);

  useEffect(() => {
    setConfirmPodcaster(!!Number(cookies.confirm_podcaster));
  }, [cookies.confirm_podcaster]);

  useEffect(() => {
    setConfirmTwitter(!!Number(cookies.confirm_twitter));
  }, [cookies.confirm_twitter]);

  useEffect(() => {
    setConfirmInstagram(!!Number(cookies.confirm_instagram));
  }, [cookies.confirm_instagram]);

  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 1);
  const options = { path: '/', expires: expiryDate };

  const confirmSelection = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    if (confirmTracking) {
      setCookie('confirm_tracking', 1, options);
      window[`ga-disable-${config.settings.DSGVOBanner.trackingId}`] = false;
    } else {
      removeCookie('confirm_tracking', options);
      window[`ga-disable-${config.settings.DSGVOBanner.trackingId}`] = true;
      removeCookie('_ga', options);
      removeCookie('_gat', options);
      removeCookie('_gid', options);
    }

    if (confirmFacebook) {
      setCookie('confirm_facebook', 1, options);
    } else {
      removeCookie('confirm_facebook', options);
    }

    if (confirmYoutube) {
      setCookie('confirm_youtube', 1, options);
    } else {
      removeCookie('confirm_youtube', options);
    }

    if (confirmVimeo) {
      setCookie('confirm_vimeo', 1, options);
    } else {
      removeCookie('confirm_vimeo', options);
    }

    if (confirmQuickchannel) {
      setCookie('confirm_quickchannel', 1, options);
    } else {
      removeCookie('confirm_quickchannel', options);
    }

    if (confirmGoogle) {
      setCookie('confirm_google', 1, options);
    } else {
      removeCookie('confirm_google', options);
    }

    if (confirmGoogleMaps) {
      setCookie('confirm_gmaps', 1, options);
    } else {
      removeCookie('confirm_gmaps', options);
    }

    if (confirmOpenStreetMaps) {
      setCookie('confirm_osm', 1, options);
    } else {
      removeCookie('confirm_osm', options);
    }

    if (confirmSoundcloud) {
      setCookie('confirm_soundcloud', 1, options);
    } else {
      removeCookie('confirm_soundcloud', options);
    }

    if (confirmPodcaster) {
      setCookie('confirm_podcaster', 1, options);
    } else {
      removeCookie('confirm_podcaster', options);
    }

    if (confirmTwitter) {
      setCookie('confirm_twitter', 1, options);
    } else {
      removeCookie('confirm_twitter', options);
    }

    if (confirmInstagram) {
      setCookie('confirm_instagram', 1, options);
    } else {
      removeCookie('confirm_instagram', options);
    }

    // setCookie('confirm_cookies', 1, options);
    //props.hideDSGVOBanner();
  };

  //Save the selection on every switch in the settings
  useEffect(() => {
    confirmSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    confirmYoutube,
    confirmVimeo,
    confirmQuickchannel,
    confirmGoogleMaps,
    confirmOpenStreetMaps,
    confirmSoundcloud,
    confirmPodcaster,
    confirmTwitter,
    confirmInstagram,
  ]);

  return (
    <Form className="block cookie-configure" id="cookie-configure">
      <h2>
        <FormattedMessage
          id="Data storage that is technically mandatory for the operation of the website (cannot be deselected)."
          defaultMessage="Data storage that is technically mandatory for the operation of the website (cannot be deselected)."
        />
      </h2>
      <Form.Field>
        <Checkbox
          label={intl.formatMessage(messages.technically_required)}
          checked
          disabled
        />
      </Form.Field>
      <h2>
        <FormattedMessage
          id="External video / audio services"
          defaultMessage="External video / audio services"
        />
      </h2>
      {includes(modules, 'tracking') && (
        <Form.Field>
          <Checkbox
            label={intl.formatMessage(messages.tracking)}
            onChange={() => setConfirmTracking(!confirmTracking)}
            checked={confirmTracking}
          />
        </Form.Field>
      )}
      {includes(modules, 'youtube') && (
        <Form.Field>
          <div className="ui checkbox">
            <input
              id="youtube-checkbox"
              type="checkbox"
              onChange={() => setConfirmYoutube(!confirmYoutube)}
              checked={confirmYoutube}
              autofocus="autofocus"
            />
            <label htmlFor="youtube-checkbox">Youtube.com</label>
          </div>
        </Form.Field>
      )}
      {includes(modules, 'vimeo') && (
        <Form.Field>
          <Checkbox
            label="Vimeo.com"
            onChange={() => setConfirmVimeo(!confirmVimeo)}
            checked={confirmVimeo}
          />
        </Form.Field>
      )}
      {includes(modules, 'quickchannel') && (
        <Form.Field>
          <Checkbox
            label="Quickchannel.com"
            onChange={() => setConfirmQuickchannel(!confirmQuickchannel)}
            checked={confirmQuickchannel}
          />
        </Form.Field>
      )}
      {includes(modules, 'google') && (
        <Form.Field>
          <Checkbox
            label="Google"
            onChange={() => setConfirmGoogle(!confirmGoogle)}
            checked={confirmGoogle}
          />
        </Form.Field>
      )}
      <h2>
        <FormattedMessage id="Audio player" defaultMessage="Audio player" />
      </h2>
      {includes(modules, 'soundcloud') && (
        <Form.Field>
          <Checkbox
            label="Soundcloud.com"
            onChange={() => setConfirmSoundcloud(!confirmSoundcloud)}
            checked={confirmSoundcloud}
          />
        </Form.Field>
      )}
      {includes(modules, 'podcaster') && (
        <Form.Field>
          <Checkbox
            label="Podcaster.de"
            onChange={() => setConfirmPodcaster(!confirmPodcaster)}
            checked={confirmPodcaster}
          />
        </Form.Field>
      )}
      <h2>
        <FormattedMessage
          id="External map services"
          defaultMessage="External map services"
        />
      </h2>
      {includes(modules, 'gmaps') && (
        <Form.Field>
          <Checkbox
            label="Google Maps"
            onChange={() => setConfirmGoogleMaps(!confirmGoogleMaps)}
            checked={confirmGoogleMaps}
          />
        </Form.Field>
      )}
      {includes(modules, 'osm') && (
        <Form.Field>
          <Checkbox
            label="Open Streetmaps"
            onChange={() => setConfirmOpenStreetMaps(!confirmOpenStreetMaps)}
            checked={confirmOpenStreetMaps}
          />
        </Form.Field>
      )}
      <h2>
        <FormattedMessage
          id="External social media services"
          defaultMessage="External social media services"
        />
      </h2>
      {includes(modules, 'twitter') && (
        <Form.Field>
          <Checkbox
            label="Twitter"
            onChange={() => setConfirmTwitter(!confirmTwitter)}
            checked={confirmTwitter}
          />
        </Form.Field>
      )}
      {includes(modules, 'instagram') && (
        <Form.Field>
          <Checkbox
            label="Instagram"
            onChange={() => setConfirmInstagram(!confirmInstagram)}
            checked={confirmInstagram}
          />
        </Form.Field>
      )}
      {includes(modules, 'facebook') && (
        <Form.Field>
          <Checkbox
            label="Facebook"
            onChange={() => setConfirmFacebook(!confirmFacebook)}
            checked={confirmFacebook}
          />
        </Form.Field>
      )}
      <Button onClick={() => history.goBack()} className="back-btn">
        <FormattedMessage id="Back" defaultMessage="Back" />
      </Button>
    </Form>
  );
};

export default View;

/**
 * Image view component.
 * @module components/theme/View/ImageView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

import { flattenToAppURL } from '@plone/volto/helpers';

// BEGIN CUSTOMIZATION
import config from '@plone/volto/registry';
import Caption from 'volto-dlr/components/Image/Caption';

const srcSetOptions = {
  // The serialization format here is different from
  // the ones in image field, as it contains the full url.
  // Consequently the source does not have to be prepended
  // to the url, like in the default case.
  createScaledSrc: (src, scaleName, scaleData) => ({
    // Important: this MUST be flattened, because it does not go through
    // the source processing of the image component.
    url: flattenToAppURL(scaleData.download),
    width: scaleData.width,
  }),
};

// END CUSTOMIZATION

/**
 * Image view component class.
 * @function ImageView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ImageView = ({ content }) => {
  const Img = config.getComponent('Img').component;
  return (
    <Container className="view-wrapper">
      {/* BEGIN CUSTOMIZATION */}
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content?.image?.download && (
        <figure>
          <Img
            width={content.image?.width}
            height={content.image?.height}
            alt={content.alt_tag}
            src={content.image.download}
            scales={content.image.scales}
            defaultScale="fullscreen"
            srcSetOptions={srcSetOptions}
            blurhash={content.blurhash}
            blurhashOptions={{
              // override default width 100%
              style: {},
            }}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
          <Caption
            title={content.title}
            description={content.description}
            credit={content.credit?.data}
            shows_people={content.shows_people}
            downloadFilename={content.title}
            downloadHref={
              content.allow_image_download &&
              flattenToAppURL(content.image.download)
            }
          />
        </figure>
      )}
      {/* END CUSTOMIZATION */}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ImageView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      scales: PropTypes.shape({
        preview: PropTypes.shape({
          download: PropTypes.string,
        }),
      }),
    }),
    // BEGIN CUSTOMIZATION
    allow_image_download: PropTypes.bool,
    shows_people: PropTypes.bool,
    credit: PropTypes.shape({
      data: PropTypes.string,
    }),
    alt_tag: PropTypes.string,
    // END CUSTOMIZATION
  }).isRequired,
};

export default ImageView;

/**
 * OVERRIDE querystring.js
 * REASON: Keep track of context for which the querystring info was fetched.
 * FILE: https://github.com/plone/volto/blob/16.24.0/src/reducers/querystring/querystring.js
 * FILE VERSION: Volto 16.24.0
 * TICKET: https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/2024
 * DATE: 2023-09-19
 * DEVELOPER: @davisagli
 */

/**
 * Querystring reducer.
 * @module reducers/querystring/querystring
 */

import { GET_QUERYSTRING } from '@plone/volto/constants/ActionTypes';

const initialState = {
  error: null,
  indexes: {},
  sortable_indexes: {},
  loaded: false,
  loading: false,
};

/**
 * Querystring reducer.
 * @function querystring
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function querystring(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_QUERYSTRING}_PENDING`:
      return {
        ...state,
        /* BEGIN CUSTOMIZATION */
        context: action.context,
        /* END CUSTOMIZATION */
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_QUERYSTRING}_SUCCESS`:
      return {
        ...state,
        error: null,
        indexes: action.result.indexes,
        sortable_indexes: action.result.sortable_indexes,
        loaded: true,
        loading: false,
      };
    case `${GET_QUERYSTRING}_FAIL`:
      return {
        ...state,
        context: null,
        error: action.error,
        indexes: {},
        sortable_indexes: {},
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}

/**
 * View audio block.
 * @module components/Blocks/Audio/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import Audio from 'volto-dlr/components/Blocks/Audio/Body';
import cx from 'classnames';
import Caption from 'volto-dlr/components/Image/Caption';

/**
 * View audio block class.
 * @class View
 * @extends Component
 */
const View = ({ data, className }) => {
  return (
    <div
      className={cx(
        'block audio align',
        className,
        {
          center: !Boolean(data.align),
        },
        data.align,
      )}
    >
      {data.embed_code && (
        <figure className="audio-inner">
          <Audio data={data} />
          <Caption
            title={data.title}
            description={data.description}
            credit={data.credit?.data}
            shows_people={data.shows_people}
          />
        </figure>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;

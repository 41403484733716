/* Parse an ISO date string as a Javascript Date.
 *  The date is constructed from parts to make sure it's in the local timezone.
 */
const parseLocalDate = (datestr) => {
  if (!datestr) return;
  const parts = datestr.split('-');
  return new Date(
    parseInt(parts[0]),
    parseInt(parts[1]) - 1,
    parseInt(parts[2]),
  );
};

/* Format a range of dates (either of which might be null) with month and year */
const formatMonthRange = (locale, start, end) => {
  const monthFormat = new Intl.DateTimeFormat(locale, {
    month: 'short',
    year: 'numeric',
  });
  const startDate = parseLocalDate(start);
  const endDate = parseLocalDate(end);
  if (startDate && endDate) {
    return monthFormat
      .formatRange(startDate, endDate)
      .replaceAll('\u2009', ' ');
  } else if (startDate) {
    return monthFormat.format(startDate);
  } else if (endDate) {
    return monthFormat.format(endDate);
  }
  return null;
};

export { formatMonthRange };

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import { ImageSliderSchema } from './schema';

const SliderData = (props) => {
  const {
    block,
    blocksConfig,
    data,
    onChangeBlock,
    contentType,
    navRoot,
  } = props;
  const intl = useIntl();
  const schema = ImageSliderSchema({ ...props, intl });

  const dataAdapter = blocksConfig[data['@type']].dataAdapter;

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        dataAdapter({
          block,
          data,
          id,
          onChangeBlock,
          value,
        });
      }}
      block={block}
      formData={data}
      onChangeBlock={onChangeBlock}
      navRoot={navRoot}
      contentType={contentType}
    />
  );
};

SliderData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default SliderData;

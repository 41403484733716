import React from 'react';
import { Grid } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { useSelector } from 'react-redux';

import Newsletter from 'volto-dlr/icons/Newsletter.png';
import Pressemitteilungen from 'volto-dlr/icons/Pressemitteilungen.png';
import wasIst from 'volto-dlr/icons/was-ist.png';

const messages = defineMessages({
  subscribeNow: {
    id: 'Subscribe now',
    defaultMessage: 'Subscribe now',
  },
  aboutDlr: {
    id: 'About DLR',
    defaultMessage: 'About DLR',
  },
  descriptionPresse: {
    id:
      'Stay up to date and sub­scribe to the DLR press releases with ar­ti­cles and media calls from the DLR ed­i­to­ri­al team in Ger­man and En­glish.',
    defaultMessage:
      'Stay up to date and sub­scribe to the DLR press releases with ar­ti­cles and media calls from the DLR ed­i­to­ri­al team in Ger­man and En­glish.',
  },
  descriptionNewsletter: {
    id:
      'Subscribe to our free newsletter and receive regular updates on DLR research topics. You can of course unsubscribe or change your preferences at any time.',
    defaultMessage:
      'Subscribe to our free newsletter and receive regular updates on DLR research topics. You can of course unsubscribe or change your preferences at any time.',
  },
  descriptionAbout: {
    id:
      'The German Aerospace Center (DLR) is the national aeronautics and space research centre of the Federal Republic of Germany.',
    defaultMessage:
      'The German Aerospace Center (DLR) is the national aeronautics and space research centre of the Federal Republic of Germany.',
  },
  titlePress: {
    id: 'Press releases',
    defaultMessage: 'Press releases',
  },
  titleNewsletter: {
    id: 'Newsletter',
    defaultMessage: 'Newsletter',
  },
  titleAbout: {
    id: 'DLR at a glance',
    defaultMessage: 'DLR at a glance',
  },
});

const TeaserBody = (props) => {
  const intl = useIntl();
  return (
    <Grid.Column className="column-container">
      <div className="grid-image-wrapper">
        <img src={props.imgSrc} alt="" />
      </div>
      <div className="content">
        <h2> {intl.formatMessage(messages[props.title])} </h2>
        <p>{intl.formatMessage(messages[props.description])}</p>
        <UniversalLink href={props.buttonLink}>
          <button className={`button-pre ${props.title}`}>
            {intl.formatMessage(messages[props.buttonText])}
          </button>
        </UniversalLink>
      </div>
    </Grid.Column>
  );
};

const PreFooterNewsItem = (props) => {
  const currentLang = useSelector((state) => state.intl.locale);

  const items = [
    {
      imgSrc: Pressemitteilungen,
      title: 'titlePress',
      description: 'descriptionPresse',
      buttonText: 'subscribeNow',
      buttonLink:
        currentLang === 'en'
          ? '/en/service/newsletter/press-newsletter-subscription'
          : '/de/service/newsletter/presse-newsletter-bestellen',
    },
    {
      imgSrc: Newsletter,
      title: 'titleNewsletter',
      description: 'descriptionNewsletter',
      buttonText: 'subscribeNow',
      buttonLink:
        currentLang === 'en'
          ? '/en/service/newsletter/topic-newsletter-subscription'
          : '/de/service/newsletter/themen-newsletter-bestellen',
    },
    {
      imgSrc: wasIst,
      title: 'titleAbout',
      description: 'descriptionAbout',
      buttonText: 'aboutDlr',
      buttonLink: currentLang === 'en' ? '/en/dlr' : '/de/das-dlr',
    },
  ];
  return (
    <div className="has--align--full footer-news-item">
      <div className="ui container">
        <Grid stackable columns={3}>
          <Grid.Row className="row-container">
            {items.map((item) => (
              <TeaserBody key={item.title} {...item} />
            ))}
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default PreFooterNewsItem;

/**
 * OVERRIDE Data.jsx
 * REASON: Add navRoot and contentType to BlockDataForm
 * FILE: https://raw.githubusercontent.com/kitconcept/volto-slider-block/main/src/components/Data.jsx
 * FILE VERSION: volto-slider-block 6.1.0
 * DATE: 2024-01-24
 * DEVELOPER: @sneridagh
 */
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BlockDataForm } from '@plone/volto/components';
import { SliderSchema } from '@kitconcept/volto-slider-block/components/schema';

const SliderData = (props) => {
  const { block, blocksConfig, data, onChangeBlock, contentType } = props;
  const intl = useIntl();
  const schema = SliderSchema({ ...props, intl });

  const dataAdapter = blocksConfig[data['@type']].dataAdapter;
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot.subsite);

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        dataAdapter({
          block,
          data,
          id,
          onChangeBlock,
          value,
        });
      }}
      onChangeBlock={onChangeBlock}
      formData={data}
      block={block}
      navRoot={iNavRootInfo}
      contentType={contentType}
    />
  );
};

export default SliderData;

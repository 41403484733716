/**
 * Body video block.
 * @module components/manage/Blocks/Video/Body
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Embed, Message } from 'semantic-ui-react';
import { isInternalURL, flattenToAppURL } from '@plone/volto/helpers';
import { IfConfirm } from '@kitconcept/volto-dsgvo-banner/components';

/**
 * Body video block class.
 * @class Body
 * @extends Component
 */
const Body = ({
  data,
  isEditMode,
  image_field = 'preview_image_link',
  currentSlide,
  inGallery,
}) => {
  const [active, setActive] = useState(false);

  // Order of preference for placeholder image:
  // 1. preview_image assigned on the block
  // 2. preview_image_link assigned on the Video content
  // 3. image from the video hosting platform (YouTube & Vimeo only)

  let placeholder = data.preview_image;
  const preview_image = data.image_scales?.[image_field]?.[0];
  if (!placeholder && preview_image) {
    placeholder = `${preview_image.base_path}/${
      preview_image?.scales?.larger?.download || preview_image?.download
    }`;
  }
  if (placeholder) {
    placeholder = flattenToAppURL(placeholder);
  }

  let videoID = null;
  let listID = null;
  const url = data.target_url;
  const vidRef = useRef(null);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    inGallery && !currentSlide && vidRef?.current?.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  if (url) {
    if (url.match('youtu')) {
      //load video preview image from youtube
      if (url.match('list')) {
        listID = url.match(/^.*\?list=(.*)|^.*&list=(.*)$/)[1];
      } else {
        videoID = url.match(/.be\//)
          ? url.match(/^.*\.be\/(.*)/)[1]
          : url.match(/^.*\?v=(.*)$/)
          ? url.match(/^.*\?v=(.*)$/)[1]
          : null;
      }

      if (!placeholder) {
        placeholder =
          'https://img.youtube.com/vi/' + videoID + '/sddefault.jpg';
      }
    } else if (url.match('vimeo')) {
      videoID = url.match(/^.*\.com\/(.*)/)[1];
      if (!placeholder) {
        placeholder = 'https://vumbnail.com/' + videoID + '.jpg';
      }
    }
  }

  const ref = React.createRef();
  const onKeyDown = (e) => {
    if (e.nativeEvent.keyCode === 13) {
      ref.current.handleClick();
    }
  };

  let embedSettings = {
    placeholder: placeholder,
    icon: 'play',
    active,
    aspectRatio: '16:9',
    tabIndex: 0,
    onKeyPress: onKeyDown,
    onClick: () => setActive(true),
    ref: ref,
  };

  let VideoProvider;
  if (url.match('youtu')) {
    VideoProvider = 'youtube';
  } else if (url.match('vimeo')) {
    VideoProvider = 'vimeo';
  } else if (url.match('quickchannel') || url.match('screen9')) {
    VideoProvider = 'quickchannel';
  }

  // BEGIN CUSTOMIZATION
  const video = url.match('youtu') ? (
    <>
      {url.match('list') ? (
        <Embed
          url={`https://www.youtube.com/embed/videoseries?list=${listID}`}
          {...embedSettings}
          iframe={{
            allowFullScreen: true,
          }}
        />
      ) : (
        <Embed
          id={videoID}
          source="youtube"
          {...embedSettings}
          autoplay={true}
          iframe={{
            allowFullScreen: true,
          }}
        />
      )}
    </>
  ) : (
    <>
      {url.match('vimeo') ? (
        <Embed
          id={videoID}
          source="vimeo"
          {...embedSettings}
          autoplay={true}
          iframe={{
            allowFullScreen: true,
          }}
        />
      ) : (
        <>
          {url.match('quickchannel') || url.match('screen9') ? (
            <Embed
              url={url}
              active={true}
              aspectRatio="16:9"
              tabIndex={0}
              iframe={{
                allowFullScreen: true,
              }}
            />
          ) : (
            <>
              {url.match('.mp4') ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  src={
                    isInternalURL(url)
                      ? url.includes('@@download')
                        ? url
                        : `${flattenToAppURL(url)}/@@download/file`
                      : url
                  }
                  controls
                  poster={placeholder}
                  type="video/mp4"
                  ref={vidRef}
                />
              ) : isEditMode ? (
                <div>
                  <Message>
                    <center>
                      <FormattedMessage
                        id="Please enter a valid URL by deleting the block and adding a new video block."
                        defaultMessage="Please enter a valid URL by deleting the block and adding a new video block."
                      />
                    </center>
                  </Message>
                </div>
              ) : (
                <div className="invalidVideoFormat" />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  const videoInfo = (
    <div className="video-info">
      <div className="info">
        {data?.description && (
          <span className="headtitle"> {data.description}</span>
        )}
        {data?.title && <p>{data?.title}</p>}
      </div>
      <button
        className="play"
        onClick={(e) => setActive(true)}
        aria-label="play"
      ></button>
    </div>
  );

  return (
    <IfConfirm module={VideoProvider} data={data} extraPlaceholder={videoInfo}>
      {!active ? videoInfo : null}
      {video}
    </IfConfirm>
  );
  // END CUSTOMIZATION
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Body.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Body;

/**
 * OVERRIDE NotFound.jsx
 * REASON: Custom 404 page
 * FILE: https://github.com/plone/volto/blob/110f8323b73bbf9c0def9db93971d5d15f89bf33/src/components/theme/NotFound/NotFound.jsx
 * FILE VERSION: Volto 16.20.0
 * DATE: 2023-04-13
 * DEVELOPER: @robgietema
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React, { forwardRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
// START CUSTOMIZATION
import { Button, Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { indexOf, slice, split, random } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  BodyClass,
  flattenToAppURL,
  normalizeLanguageName,
} from '@plone/volto/helpers';
import { useIntl, defineMessages } from 'react-intl';
import { Icon } from '@plone/volto/components';
import {
  changeLanguage,
  getContent,
  getNavigation,
} from '@plone/volto/actions';
import { useLocation } from 'react-router-dom';
import config from '@plone/volto/registry';

import digitalisierungPNG from 'volto-dlr/static/404-digitalisierung.png';
import energiePNG from 'volto-dlr/static/404-energie.png';
import luftfahrtPNG from 'volto-dlr/static/404-luftfahrt.png';
import raumfahrtPNG from 'volto-dlr/static/404-raumfahrt.png';
import sicherheitPNG from 'volto-dlr/static/404-sicherheit.png';
import verkehrPNG from 'volto-dlr/static/404-verkehr.png';
import searchSVG from 'volto-dlr/icons/search-icon.svg';

const messages = defineMessages({
  TypeSearchWords: {
    id: 'Search...',
    defaultMessage: 'Search...',
  },
  AeronauticsTitle: {
    id: 'Attention:',
    defaultMessage: 'Attention:',
  },
  AeronauticsDescription: {
    id: "Passengers travelling to 'DLR' will be transferred to another page.",
    defaultMessage:
      "Passengers travelling to 'DLR' will be transferred to another page.",
  },
  DigitalisationTitle: {
    id: 'Attention:',
    defaultMessage: 'Attention:',
  },
  DigitalisationDescription: {
    id: '01000001 01100011 01101000 01110100 01110101 01101110 01100111',
    defaultMessage:
      '01000001 01100011 01101000 01110100 01110101 01101110 01100111',
  },
  EnergyTitle: {
    id: 'Power outage!',
    defaultMessage: 'Power outage!',
  },
  EnergyDescription: {
    id: 'Unfortunately, the URL you have selected does not exist.',
    defaultMessage: 'Unfortunately, the URL you have selected does not exist.',
  },
  TrafficTitle: {
    id: 'Please go back:',
    defaultMessage: 'Please go back:',
  },
  TrafficDescription: {
    id: "You can't go any further ...",
    defaultMessage: "You can't go any further ...",
  },
  SpaceTitle: {
    id: 'Congratulations!',
    defaultMessage: 'Congratulations!',
  },
  SpaceDescription: {
    id: 'You have discovered a black hole!',
    defaultMessage: 'You have discovered a black hole!',
  },
  SecurityTitle: {
    id: 'Attention:',
    defaultMessage: 'Attention:',
  },
  SecurityDescription: {
    id: 'Sorry, you cannot access this page',
    defaultMessage: 'Sorry, you cannot access this page',
  },
  URLDoesNotExist: {
    id: 'Unfortunately, this URL does not exist.',
    defaultMessage: 'Unfortunately, this URL does not exist.',
  },
});

const errors = [
  {
    title: messages.DigitalisationTitle,
    description: messages.DigitalisationDescription,
    image: digitalisierungPNG,
  },
  {
    title: messages.EnergyTitle,
    description: messages.EnergyDescription,
    image: energiePNG,
  },
  {
    title: messages.AeronauticsTitle,
    description: messages.AeronauticsDescription,
    image: luftfahrtPNG,
  },
  {
    title: messages.SpaceTitle,
    description: messages.SpaceDescription,
    image: raumfahrtPNG,
  },
  {
    title: messages.SecurityTitle,
    description: messages.SecurityDescription,
    image: sicherheitPNG,
  },
  {
    title: messages.TrafficTitle,
    description: messages.TrafficDescription,
    image: verkehrPNG,
  },
];

const error = random(5);

// XXX for some reason formatMessage is missing from this.props.intl.
// Until we figure this out, just acquire it directly from hook.
// This should not be necessary.. @reebalazs
const TranslatedInput = forwardRef(
  ({ forwardRef, placeholder, className, name, value, onChange }, ref) => {
    const intl = useIntl();
    return (
      <input
        ref={ref}
        placeholder={intl.formatMessage(placeholder)}
        className={className}
        name={name}
        value={value}
        onChange={onChange}
      />
    );
  },
);

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => {
  const [searchword, setSearchword] = useState('');
  const intl = useIntl();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.intl.locale);
  const pathname = useLocation().pathname;

  useEffect(() => {
    const parts = pathname.split('/');
    dispatch(
      getContent(
        slice(parts, 0, parts[2] === 'site' ? 4 : 3).join('/'),
        null,
        'navRoot',
      ),
    )
      .then((resp) => {
        dispatch(
          getNavigation(
            flattenToAppURL(
              resp['@components'].nearest_inav_root.subsite['@id'] ||
                `/${lang}`,
            ),
            config.settings.navDepth,
          ),
        );
      })
      .catch((err) => {
        dispatch(getNavigation(`/${lang}`, config.settings.navDepth));
      });

    // Get language
    const pathLang = split(pathname, '/')[1];

    if (indexOf(config.settings.supportedLanguages, pathLang) !== -1) {
      const langFileName = normalizeLanguageName(pathLang);
      import('@root/../locales/' + langFileName + '.json').then((locale) => {
        dispatch(changeLanguage(pathLang, locale.default));
      });
    }
  }, [dispatch, lang, pathname]);

  return (
    <Container className="view-wrapper" id="page-document">
      <BodyClass className="page-not-found" />
      <div className="block">
        <h1>
          <FormattedMessage
            id="404 Error page"
            defaultMessage="404 Error page"
          />
        </h1>
        <h2>{intl.formatMessage(errors[error].title)}</h2>
      </div>
      <div className="block default">
        <div>
          <img
            src={errors[error].image}
            alt={intl.formatMessage(errors[error].description) || ''}
          />
        </div>
        <p className="description">
          {intl.formatMessage(errors[error].description) || ''}
        </p>
      </div>

      <div className="block narrow">
        <h2>
          <FormattedMessage
            id="Error 404: What does this error message mean?"
            defaultMessage="Error 404: What does this error message mean?"
          />
        </h2>
        <p>
          <FormattedMessage
            id="There can be various reasons why content cannot be found:"
            defaultMessage="There can be various reasons why content cannot be found:"
          />
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="The content has been moved or deleted (the latter occurs very rarely)."
              defaultMessage="The content has been moved or deleted (the latter occurs very rarely)."
            />
          </li>
          <li>
            <FormattedMessage
              id="The address you entered or the link you clicked is incorrect (e.g. a typo)."
              defaultMessage="The address you entered or the link you clicked is incorrect (e.g. a typo)."
            />
          </li>
        </ul>
        <p>
          <FormattedMessage
            id="We recommend using the search of our web portal DLR.de."
            defaultMessage="We recommend using the search of our web portal DLR.de."
          />
        </p>
      </div>

      <div className="block default has--backgroundColor--grey search">
        <h3>
          <FormattedMessage
            id="Search the website DLR.de"
            defaultMessage="Search the website DLR.de"
          />
        </h3>
        <div className="search-input">
          <form action="/@@search" method="GET">
            <div className="search-input-wrapper">
              <TranslatedInput
                placeholder={messages.TypeSearchWords}
                className="searchinput"
                name="SearchableText"
                value={searchword}
                onChange={(e) => setSearchword(e.target.value)}
              />
              <Button type="submit">
                <Icon name={searchSVG} size="24px" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

// END CUSTOMIZATION

export default withServerErrorCode(404)(NotFound);

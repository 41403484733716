/**
 * OVERRIDE ContentsBreadcrumbs.jsx
 * REASON: Change the "Root" literal for a "home" icon
 * FILE: https://github.com/plone/volto/blob/cffd7fe7545f6df306dde10079719aba8d6557e1/src/components/manage/Contents/ContentsBreadcrumbs.jsx
 * FILE VERSION: Volto 16.0.0-alpha.27
 * DATE: 2022-08-30
 * DEVELOPER: @sneridagh
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import config from '@plone/volto/registry';
import { langmap } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';
import ContentsBreadcrumbsRootItem from '@plone/volto/components/manage/Contents/ContentsBreadcrumbsRootItem';
import ContentsBreadcrumbsHomeItem from '@plone/volto/components/manage/Contents/ContentsBreadcrumbsHomeItem';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  root: {
    id: 'Root',
    defaultMessage: 'Root',
  },
});

const ContentsBreadcrumbs = (props) => {
  const { settings } = config;
  const { items } = props;
  const intl = useIntl();
  const pathname = useLocation().pathname;
  const lang = pathname.split('/')[1];

  const nearestInavRoot = useSelector((state) => state.nearestInavRoot);
  const subsite = nearestInavRoot.subsite;

  return (
    <Breadcrumb>
      {settings.isMultilingual && (
        <>
          <Link
            to="/contents"
            className="section"
            title={intl.formatMessage(messages.root)}
          >
            <ContentsBreadcrumbsRootItem />
          </Link>
          <Breadcrumb.Divider />
        </>
      )}
      {settings.isMultilingual && pathname?.split('/')?.length > 2 && (
        <Link
          to={`/${lang}/contents`}
          className="section"
          title={intl.formatMessage(messages.home)}
        >
          {langmap?.[lang]?.nativeName ?? lang}
        </Link>
      )}
      {settings.isMultilingual &&
        pathname?.split('/')?.length > 2 &&
        subsite?.['@id'] && (
          <>
            <Breadcrumb.Divider />
            <Link
              to={`${subsite['@id']}/contents`}
              className="section"
              title={intl.formatMessage(messages.home)}
            >
              {subsite.name}
            </Link>
          </>
        )}

      {!settings.isMultilingual && (
        <Link
          to="/contents"
          className="section"
          title={intl.formatMessage(messages.home)}
        >
          <ContentsBreadcrumbsHomeItem />
        </Link>
      )}
      {items.map((breadcrumb, index, breadcrumbs) => [
        <Breadcrumb.Divider key={`divider-${breadcrumb.url}`} />,
        index < breadcrumbs.length - 1 ? (
          <Link
            key={breadcrumb.url}
            to={`${breadcrumb.url}/contents`}
            className="section"
          >
            {breadcrumb.nav_title || breadcrumb.title}
          </Link>
        ) : (
          <Breadcrumb.Section key={breadcrumb.url} active>
            {breadcrumb.nav_title || breadcrumb.title}
          </Breadcrumb.Section>
        ),
      ])}
    </Breadcrumb>
  );
};

export default ContentsBreadcrumbs;

import React from 'react';

const ContactDetail = (props) => {
  const { contact } = props;

  return (
    <>
      <h3 className="name">{`${contact.academic ?? ''} ${
        contact.first_name ?? ''
      } ${contact.last_name ?? ''}`}</h3>
      {contact.position ? (
        <div className="position">{contact.position}</div>
      ) : (
        ''
      )}
      {contact.company ? <div className="company">{contact.company}</div> : ''}
      {contact.institute ? (
        <div className="institute">{contact.institute}</div>
      ) : (
        ''
      )}
      {contact.department ? (
        <div className="department">{contact.department}</div>
      ) : (
        ''
      )}
      {contact.house_number ||
      contact.road ||
      contact.city ||
      contact.zipcode ? (
        <div className="address">
          {[
            [contact.road, contact.house_number].filter((el) => el).join(' '),
            [contact.zipcode, contact.city].filter((el) => el).join(' '),
          ]
            .filter((el) => el)
            .join(', ')}
        </div>
      ) : (
        ''
      )}
      {contact.country && contact.country !== 'Deutschland' ? (
        <div className="country">{contact.country}</div>
      ) : (
        ''
      )}
      {contact.phone && contact.is_official_press_contact ? (
        <div className="phone">Tel: {contact.phone}</div>
      ) : null}
    </>
  );
};

export default ContactDetail;

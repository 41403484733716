import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { MaybeWrap } from '@plone/volto/components';
import { UniversalLink } from '@plone/volto/components';
import { EffectiveDate } from '@kitconcept/volto-blocks/components';
import { softHyphenSupport, PreviewImg } from 'volto-dlr/helpers';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const NewsItemTemplate = (props) => {
  const { data, isEditMode, className, insideGridBlock = false } = props;
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  return (
    <div
      className={cx('block teaser', className, {
        standalone: !insideGridBlock,
      })}
    >
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item placeholder">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {href && (
        <MaybeWrap
          condition={!isEditMode}
          as={UniversalLink}
          href={href['@id']}
          target={data.openLinkInNewTab ? '_blank' : null}
        >
          <div
            className={`grid-teaser-item default newsItem ${
              data.hideImage ? 'hideImage' : ''
            }`}
          >
            {(href.hasPreviewImage || href.image_field || image) &&
              !data.hideImage && (
                <div className="grid-image-wrapper">
                  <PreviewImg
                    previewImage={image}
                    href={href}
                    scaleName="teaser"
                    targetWidth={
                      !insideGridBlock && data.styles?.align === 'center'
                        ? 'default'
                        : 'half'
                    }
                  />
                </div>
              )}
            <div className="content">
              <div className="headline">
                <EffectiveDate item={href} />
              </div>
              <h2>{softHyphenSupport(data?.title)}</h2>
              <p>{data?.description ?? ''}</p>
            </div>
          </div>
        </MaybeWrap>
      )}
    </div>
  );
};

NewsItemTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default NewsItemTemplate;

import GET_TAGS from 'volto-dlr/actions/blog/tags';

const initialState = {};

export default function tags(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_TAGS}_PENDING`:
      return {
        ...state,
        [action.blogUrl]: {
          error: null,
          loaded: false,
          loading: true,
        },
      };
    case `${GET_TAGS}_SUCCESS`:
      return {
        ...state,
        [action.blogUrl]: {
          ...action.result,
          lookup: action.result.items.reduce((result, term) => {
            result[term.token] = term;
            return result;
          }, {}),
          error: null,
          loaded: true,
          loading: false,
        },
      };
    case `${GET_TAGS}_FAIL`:
      return {
        ...state,
        [action.blogUrl]: {
          error: action.error,
          loaded: false,
          loading: false,
        },
      };
    default:
      return state;
  }
}

/**
 * View component with conditional redirect.
 * @module components/Theme/ConditionalRedirectView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LinkView from '@plone/volto/components/theme/View/LinkView';
import DefaultView from '@plone/volto/components/theme/View/DefaultView';

/**
 * ConditionalRedirectView component class.
 * @function ConditionalRedirectView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ConditionalRedirectView = (props) => {
  const { content, token } = props;
  if (content.remoteUrl && content.redirect_to_website !== false && !token) {
    // redirect public traffic to target
    return <LinkView {...props} />;
  } else {
    return <DefaultView {...props} />;
  }
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ConditionalRedirectView.propTypes = {
  content: PropTypes.shape({
    remoteUrl: PropTypes.string,
  }).isRequired,
};

export default connect((state, props) => ({
  token: state.userSession.token,
}))(ConditionalRedirectView);

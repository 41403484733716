import { includes, inRange } from 'lodash';
import config from '@plone/volto/registry';

const checkRatio = (value, aspect) =>
  inRange(value, aspect * 0.99, aspect * 1.01);

export const getAspectRatio = (item) => {
  if (
    !includes(config.settings.imageObjects, item['@type']) ||
    !item.image_scales?.[item.image_field]?.[0]
  ) {
    return '';
  }

  const image = item.image_scales[item.image_field][0];

  const ratio = image.width / image.height;

  if (checkRatio(ratio, 16 / 9)) {
    return '16:9';
  }

  if (checkRatio(ratio, 24 / 11)) {
    return '24:11';
  }

  if (checkRatio(ratio, 6 / 7)) {
    return '6:7';
  }

  if (checkRatio(ratio, 4 / 3)) {
    return '4:3';
  }

  if (checkRatio(ratio, 1 / 1)) {
    return '1:1';
  }

  if (checkRatio(ratio, 210 / 297)) {
    return 'A4';
  }

  return '';
};

export const RESET_NAVIGATION = 'RESET_NAVIGATION';
export const RESET_BREADCRUMBS = 'RESET_BREADCRUMBS';

/**
 * Reset navigation function
 * @function resetNavigation
 * @returns {Object} Reset action
 */
export function resetNavigation() {
  return {
    type: RESET_NAVIGATION,
  };
}

/**
 * Reset breadcrumbs function
 * @function resetNavigation
 * @returns {Object} Reset action
 */
export function resetBreadcrumbs() {
  return {
    type: RESET_BREADCRUMBS,
  };
}

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Audio: {
    id: 'Audio',
    defaultMessage: 'Audio',
  },
  AudioURL: {
    id: 'Audio URL',
    defaultMessage: 'Audio URL',
  },
  Preview_image: {
    id: 'Preview Image URL',
    defaultMessage: 'Preview Image URL',
  },
  Alignment: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
});

export const AudioBlockSchema = (props) => ({
  title: props.intl.formatMessage(messages.Audio),
  block: 'Audio',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['url', 'title', 'description', 'align'],
    },
  ],

  properties: {
    url: {
      title: props.intl.formatMessage(messages.AudioURL),
      widget: 'url',
      isDisabled: true,
    },
    preview_image: {
      title: props.intl.formatMessage(messages.Preview_image),
      widget: 'url',
    },
    align: {
      title: props.intl.formatMessage(messages.Alignment),
      widget: 'align',
    },
    description: {
      title: props.intl.formatMessage(messages.Description),
      widget: 'textarea',
    },
    title: {
      title: props.intl.formatMessage(messages.Title),
    },
  },
  required: [],
});

export default AudioBlockSchema;

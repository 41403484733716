const deCountry = {
  AF: 'Afghanistan',
  EG: '\u00c4gypten',
  AX: '\u00c5landinseln',
  AL: 'Albanien',
  DZ: 'Algerien',
  AS: 'Amerikanisch-Samoa',
  VI: 'Amerikanische Jungferninseln',
  UM: 'Amerikanische \u00dcberseeinseln',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarktis',
  AG: 'Antigua und Barbuda',
  GQ: '\u00c4quatorialguinea',
  AR: 'Argentinien',
  AM: 'Armenien',
  AW: 'Aruba',
  AZ: 'Aserbaidschan',
  ET: '\u00c4thiopien',
  AU: 'Australien',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesch',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgien',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivien',
  BQ: 'Bonaire, Sint Eustatius und Saba',
  BA: 'Bosnien und Herzegowina',
  BW: 'Botsuana',
  BV: 'Bouvetinsel',
  BR: 'Brasilien',
  VG: 'Britische Jungferninseln',
  IO: 'Britisches Territorium im Indischen Ozean',
  BN: 'Brunei Darussalam',
  BG: 'Bulgarien',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  CL: 'Chile',
  CN: 'China',
  CK: 'Cookinseln',
  CR: 'Costa Rica',
  CI: 'C\u00f4te d\u2019Ivoire',
  CW: 'Cura\u00e7ao',
  DK: 'D\u00e4nemark',
  DE: 'Deutschland',
  DM: 'Dominica',
  DO: 'Dominikanische Republik',
  DJ: 'Dschibuti',
  EC: 'Ecuador',
  SV: 'El Salvador',
  ER: 'Eritrea',
  EE: 'Estland',
  SZ: 'Eswatini',
  FK: 'Falklandinseln',
  FO: 'F\u00e4r\u00f6er',
  FJ: 'Fidschi',
  FI: 'Finnland',
  FR: 'Frankreich',
  GF: 'Franz\u00f6sisch-Guayana',
  PF: 'Franz\u00f6sisch-Polynesien',
  TF: 'Franz\u00f6sische S\u00fcd- und Antarktisgebiete',
  GA: 'Gabun',
  GM: 'Gambia',
  GE: 'Georgien',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Grenada',
  GR: 'Griechenland',
  GL: 'Gr\u00f6nland',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard und McDonaldinseln',
  HN: 'Honduras',
  IN: 'Indien',
  ID: 'Indonesien',
  IQ: 'Irak',
  IR: 'Iran',
  IE: 'Irland',
  IS: 'Island',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italien',
  JM: 'Jamaika',
  JP: 'Japan',
  YE: 'Jemen',
  JE: 'Jersey',
  JO: 'Jordanien',
  KY: 'Kaimaninseln',
  KH: 'Kambodscha',
  CM: 'Kamerun',
  CA: 'Kanada',
  KZ: 'Kasachstan',
  QA: 'Katar',
  KE: 'Kenia',
  KG: 'Kirgisistan',
  KI: 'Kiribati',
  CC: 'Kokosinseln',
  CO: 'Kolumbien',
  KM: 'Komoren',
  CG: 'Kongo-Brazzaville',
  CD: 'Kongo-Kinshasa',
  HR: 'Kroatien',
  CU: 'Kuba',
  KW: 'Kuwait',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Lettland',
  LB: 'Libanon',
  LR: 'Liberia',
  LY: 'Libyen',
  LI: 'Liechtenstein',
  LT: 'Litauen',
  LU: 'Luxemburg',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Malediven',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Marokko',
  MH: 'Marshallinseln',
  MQ: 'Martinique',
  MR: 'Mauretanien',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexiko',
  FM: 'Mikronesien',
  MC: 'Monaco',
  MN: 'Mongolei',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MZ: 'Mosambik',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NC: 'Neukaledonien',
  NZ: 'Neuseeland',
  NI: 'Nicaragua',
  NL: 'Niederlande',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  KP: 'Nordkorea',
  MP: 'N\u00f6rdliche Marianen',
  MK: 'Nordmazedonien',
  NF: 'Norfolkinsel',
  NO: 'Norwegen',
  OM: 'Oman',
  AT: '\u00d6sterreich',
  PK: 'Pakistan',
  PS: 'Pal\u00e4stinensische Autonomiegebiete',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papua-Neuguinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippinen',
  PN: 'Pitcairninseln',
  PL: 'Polen',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  MD: 'Republik Moldau',
  RE: 'R\u00e9union',
  RW: 'Ruanda',
  RO: 'Rum\u00e4nien',
  RU: 'Russland',
  SB: 'Salomonen',
  ZM: 'Sambia',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'S\u00e3o Tom\u00e9 und Pr\u00edncipe',
  SA: 'Saudi-Arabien',
  SE: 'Schweden',
  CH: 'Schweiz',
  SN: 'Senegal',
  RS: 'Serbien',
  SC: 'Seychellen',
  SL: 'Sierra Leone',
  ZW: 'Simbabwe',
  SG: 'Singapur',
  SX: 'Sint Maarten',
  SK: 'Slowakei',
  SI: 'Slowenien',
  SO: 'Somalia',
  HK: 'Sonderverwaltungsregion Hongkong',
  MO: 'Sonderverwaltungsregion Macau',
  ES: 'Spanien',
  SJ: 'Spitzbergen und Jan Mayen',
  LK: 'Sri Lanka',
  BL: 'St. Barth\u00e9lemy',
  SH: 'St. Helena',
  KN: 'St. Kitts und Nevis',
  LC: 'St. Lucia',
  MF: 'St. Martin',
  PM: 'St. Pierre und Miquelon',
  VC: 'St. Vincent und die Grenadinen',
  ZA: 'S\u00fcdafrika',
  SD: 'Sudan',
  GS: 'S\u00fcdgeorgien und die S\u00fcdlichen Sandwichinseln',
  KR: 'S\u00fcdkorea',
  SS: 'S\u00fcdsudan',
  SR: 'Suriname',
  SY: 'Syrien',
  TJ: 'Tadschikistan',
  TW: 'Taiwan',
  TZ: 'Tansania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad und Tobago',
  TD: 'Tschad',
  CZ: 'Tschechien',
  TN: 'Tunesien',
  TR: 'T\u00fcrkei',
  TM: 'Turkmenistan',
  TC: 'Turks- und Caicosinseln',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  HU: 'Ungarn',
  UY: 'Uruguay',
  UZ: 'Usbekistan',
  VU: 'Vanuatu',
  VA: 'Vatikanstadt',
  VE: 'Venezuela',
  AE: 'Vereinigte Arabische Emirate',
  US: 'Vereinigte Staaten',
  GB: 'Vereinigtes K\u00f6nigreich',
  VN: 'Vietnam',
  WF: 'Wallis und Futuna',
  CX: 'Weihnachtsinsel',
  EH: 'Westsahara',
  CF: 'Zentralafrikanische Republik',
  CY: 'Zypern',
};

export default deCountry;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Message } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
import { getFormTicket } from 'volto-dlr/actions';
import { subscribe } from 'volto-dlr/actions/newsletter/newsletter';
import warningSVG from '@plone/volto/icons/warning.svg';

const messages = defineMessages({
  DataProtection: {
    id:
      'We store the data entered by you in order to provide you with services. It will only be used for this purpose and will not be transferred to third parties. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
    defaultMessage:
      'We store the data entered by you in order to provide you with services. It will only be used for this purpose and will not be transferred to third parties. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
  },
  PrivacyConsent: {
    id:
      'I have read the data protection regulations carefully, I have understood the procedure described and hereby consent to the processing of my data for the purpose of contacting me.',
    defaultMessage:
      'I have read the data protection regulations carefully, I have understood the procedure described and hereby consent to the processing of my data for the purpose of contacting me.',
  },
  AgeConsent: {
    id:
      'Our offer is generally aimed at persons aged 16 years or older. Individuals under the age of 16 are not permitted to submit personal data to us without the consent of their parents or legal guardian.',
    defaultMessage:
      'Our offer is generally aimed at persons aged 16 years or older. Individuals under the age of 16 are not permitted to submit personal data to us without the consent of their parents or legal guardian.',
  },
});

const NewsletterSubscribeForm = (props) => {
  const intl = useIntl();
  const lang = useSelector((state) => state.intl.locale);
  const dispatch = useDispatch();
  const location = useLocation();
  const { content } = props;

  const [ticket, setTicket] = React.useState();
  useEffect(() => {
    dispatch(getFormTicket(getBaseUrl(location.pathname))).then((result) =>
      setTicket(result.ticket),
    );
  }, [dispatch, location]);
  const [state, setState] = React.useState({
    validated: false,
    name: '',
    email: '',
    topics:
      // Subscribe to the first topic by default
      content.topics?.length ? { [content.topics[0].list_id]: true } : {},
    privacy_consent: false,
    age_consent: false,
    loading: false,
    success: false,
    error: {},
    serverError: null,
  });

  // Scroll to top after submitting form
  useEffect(() => {
    if (state.success) {
      window.scrollTo(0, 0);
    }
  }, [state.success]);

  const onChangeHandler = (event, data) => {
    if (data === undefined) {
      data = event.currentTarget;
    }
    if (data.name === 'privacy_consent' || data.name === 'age_consent') {
      setState({
        ...state,
        [data.name]: !state[data.name],
        error: { ...state.error, [data.name]: false },
      });
      return;
    }
    setState({
      ...state,
      [data.name]: data.value,
    });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (state.loading) return;
    const form = event.currentTarget;
    let hasError = false;
    const newState = {
      serverError: null,
      error: {},
    };
    if (!form.checkValidity()) {
      form.reportValidity();
      newState.validated = true;
      hasError = true;
    }
    if (!state.privacy_consent) {
      newState.error.privacy_consent = true;
      hasError = true;
    }
    if (!state.age_consent) {
      newState.error.age_consent = true;
      hasError = true;
    }
    setState((state) => {
      return { ...state, ...newState };
    });
    if (hasError) return true;

    setState((state) => {
      return { ...state, loading: true, serverError: null };
    });
    const list_ids = [];
    Object.keys(state.topics).forEach((key) => {
      if (state.topics[key]) list_ids.push(parseInt(key));
    });
    dispatch(
      subscribe(content['@id'], {
        name: state.name,
        email: state.email,
        list_ids: list_ids,
        ticket,
      }),
    )
      .then((result) => {
        setState((state) => {
          return { ...state, loading: false, success: true };
        });
      })
      .catch((error) => {
        setState((state) => {
          return { ...state, loading: false, serverError: error };
        });
      });
  };

  return state.success ? (
    <div
      className="contact-form-overlay"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      role="presentation"
    >
      <fieldset>
        <legend className="sent">
          <FormattedMessage
            id="ConfirmMessage"
            defaultMessage="Thank you for subscribing to the DLR newsletter. Please check your e-mails and confirm your registration. Please also check your SPAM folder."
          />
        </legend>
      </fieldset>
    </div>
  ) : (
    <Form
      noValidate
      className={'contact-form-overlay' + (state.validated ? ' validated' : '')}
      onSubmit={onSubmitHandler}
    >
      <header className="head-title">
        <FormattedMessage id="NewsletterSubscribe" defaultMessage="Subscribe" />
      </header>
      <h1>{content.title}</h1>
      <p>{content.description}</p>
      <fieldset>
        <label htmlFor="name">
          <FormattedMessage id="Name" defaultMessage="Name" />
        </label>
        <Form.Input
          id="name"
          name="name"
          onChange={onChangeHandler}
          value={state.name}
          type="text"
          maxLength="100"
        />
        <label htmlFor="email">
          <FormattedMessage id="Email" defaultMessage="Email" />
          <dfn title="Mandatory">*</dfn>
        </label>
        <Form.Input
          required
          id="email"
          name="email"
          value={state.email}
          onChange={onChangeHandler}
          type="email"
          maxLength="100"
          pattern=".+@.+\..+"
        />
        {content.topics && content.topics.length > 1 ? (
          <>
            <Form.Field>
              <FormattedMessage
                id="NewsletterTopicsHelp"
                defaultMessage="Select your topics of interest. You will receive individual emails when news for the selected topics becomes available. *"
              />
            </Form.Field>
            <Form.Group widths="two">
              {content.topics.map((topic) => (
                <Form.Checkbox
                  key={topic.list_id}
                  label={topic.title}
                  checked={state.topics[topic.list_id]}
                  onChange={(e, data) =>
                    setState((state) => {
                      return {
                        ...state,
                        topics: {
                          ...state.topics,
                          [topic.list_id]: data.checked,
                        },
                      };
                    })
                  }
                />
              ))}
            </Form.Group>
          </>
        ) : null}
        <p className="note">
          <FormattedMessage
            id="Mandatory"
            defaultMessage="Note: Required information is marked with a *"
          />
        </p>
      </fieldset>
      <hr />
      <fieldset>
        <legend className="privacy">
          <FormattedMessage
            id="Our Privacy Policy"
            defaultMessage="Our Privacy Policy"
          />
        </legend>
        <p>
          {intl.formatMessage(messages.DataProtection)}{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href={
              lang === 'de'
                ? '/de/service/datenschutz'
                : '/en/service/privacypolicy'
            }
          >
            <FormattedMessage
              id="PrivacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </a>
        </p>
        <Form.Checkbox
          name="privacy_consent"
          onChange={onChangeHandler}
          aria-labelledby="privacy-consent-label"
          label={
            <label id="privacy-consent-label">
              {intl.formatMessage(messages.PrivacyConsent) + ' *'}
            </label>
          }
          error={state.error.privacy_consent}
        />
        <Form.Checkbox
          name="age_consent"
          onChange={onChangeHandler}
          aria-labelledby="age-consent-label"
          label={
            <label id="age-consent-label">
              {intl.formatMessage(messages.AgeConsent) + ' *'}
            </label>
          }
          error={state.error.age_consent}
        />
      </fieldset>
      <hr />
      <fieldset>
        {state.serverError && (
          <Message negative>
            <div className="icon-container-warning">
              {' '}
              <Icon name={warningSVG} size="50px" color="red" />
            </div>
            <Message.Header>
              <FormattedMessage id="Error" defaultMessage="Error" />
            </Message.Header>
            <Message.Content>
              {state.serverError.response?.body?.message}
            </Message.Content>
          </Message>
        )}
        <Button primary name="submit" loading={state.loading}>
          <FormattedMessage
            id="NewsletterSubscribe"
            defaultMessage="Subscribe"
          />
        </Button>
      </fieldset>
    </Form>
  );
};

export default NewsletterSubscribeForm;

/**
 * Home container.
 * @module components/theme/ConnectionRefused/ConnectionRefused
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
/**
 * OVERRIDE ConnnectionRefused.jsx
 * REASON: Customize error message
 * FILE: https://github.com/plone/volto/blob/7c692718d2b786d381570d52f0b1d0ec575acb2c/src/components/theme/ConnectionRefused/ConnectionRefused.jsx
 * FILE VERSION: Volto 16.0.0-alpha.36
 * DATE: 2022-09-26
 * DEVELOPER: @reebalazs
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
import { Container } from 'semantic-ui-react';
import config from '@plone/volto/registry';

const ConnectionRefused = () => (
  <Container
    className="view-wrapper"
    style={{
      fontFamily: 'Helvetica, sans-serif',
      fontSize: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {/* START CUSTOMIZATION */}
    <main>
      <div id="view">
        <div id="page-document" className="ui container">
          <h1
            className="documentFirstHeading"
            style={{
              textAlign: 'center',
              lineHeight: '40px',
              marginBottom: '40px',
            }}
          >
            <FormattedMessage
              id="Technical malfunction"
              defaultMessage="Technical malfunction"
            />
          </h1>
          {__DEVELOPMENT__ && (
            <h2>
              <br />
              <a href={config.settings.apiPath}>{config.settings.apiPath}</a>
            </h2>
          )}
          <FormattedMessage
            id="<p>Due to a technical malfunction this site is currently unavailable. There can be many causes, like e.g. disrupted network connection.</p><p>Please check your internet connection and reload the page.</p>"
            defaultMessage="<p>Due to a technical malfunction this site is currently unavailable. There can be many causes, like e.g. disrupted network connection.</p><p>Please check your internet connection and reload the page.</p>"
            values={{
              p: (...chunks) =>
                chunks.map((txt) => (
                  <p
                    className="description"
                    style={{
                      maxWidth: '530px',
                    }}
                  >
                    {txt}
                  </p>
                )),
            }}
          />
          {__DEVELOPMENT__ && (
            <p
              className="description"
              style={{
                maxWidth: '530px',
              }}
            >
              <FormattedMessage
                id="The backend is not responding, please check if you have started Plone, check your project's configuration object apiPath (or if you are using the internal proxy, devProxyToApiPath) or the RAZZLE_API_PATH Volto's environment variable."
                defaultMessage="The backend is not responding, please check if you have started Plone, check your project's configuration object apiPath (or if you are using the internal proxy, devProxyToApiPath) or the RAZZLE_API_PATH Volto's environment variable."
              />
            </p>
          )}
          {!__DEVELOPMENT__ && (
            <>
              <p
                className="description"
                style={{
                  maxWidth: '530px',
                }}
              >
                <FormattedMessage id="Thank you." defaultMessage="Thank you." />
              </p>
            </>
          )}
        </div>
      </div>
    </main>
    {/* END CUSTOMIZATION */}
  </Container>
);

export default ConnectionRefused;

import { isInternalURL, flattenToAppURL } from '@plone/volto/helpers';
import { TextBlockView } from '@plone/volto-slate/blocks/Text';
import { DetachedTextBlockEditor } from '@plone/volto-slate/blocks/Text/DetachedTextBlockEditor';
import { UniversalLink } from '@plone/volto/components';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';

const InfoboxView = (props) => {
  const { data, isEditMode } = props;
  const anchor = data.buttonLink?.[0]?.anchor;

  return (
    <div className={cx('block infobox', data.align || 'center')}>
      <div className="inner">
        <div className="title_row">
          {data.icon?.length > 0 && (
            <img
              className="icon"
              src={flattenToAppURL(
                `${data.icon?.[0]['@id']}/@@images/image/teaser`,
              )}
              alt=""
            />
          )}
          <h3>{data.title}</h3>
        </div>
        <div className="info-text">
          {isEditMode ? (
            <DetachedTextBlockEditor {...props} />
          ) : (
            <TextBlockView {...props} />
          )}
        </div>
        {data.buttonText && (
          <UniversalLink
            href={
              isEditMode
                ? null
                : isInternalURL(data.buttonLink?.[0]['@id'])
                ? anchor
                  ? `${flattenToAppURL(data.buttonLink?.[0]['@id'])}#${anchor}`
                  : flattenToAppURL(data.buttonLink?.[0]['@id'])
                : data.buttonLink?.[0]['@id']
            }
          >
            <Button>{data.buttonText}</Button>
          </UniversalLink>
        )}
      </div>
    </div>
  );
};

export default InfoboxView;

import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

import { PreviewImg } from 'volto-dlr/helpers';

const SummaryTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <ConditionalLink item={item} condition={!isEditMode}>
              <div className="image-wrapper">
                <PreviewImg
                  previewImage={item}
                  imageField={item.image_scales?.logo ? 'logo' : null}
                  blurhashOptions={{
                    style: {
                      width: '100%',
                      height: 'auto',
                      alignSelf: 'center',
                    },
                  }}
                  style={
                    item.image_scales?.logo ? { 'object-fit': 'contain' } : null
                  }
                  targetWidth="half"
                />
              </div>
              <div className="listing-body">
                {item.head_title && (
                  <div className="head-title">{item.head_title}</div>
                )}
                <h3>{item.title ? item.title : item.id}</h3>
                <p>{item.description}</p>
              </div>
            </ConditionalLink>
          </div>
        ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

SummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default SummaryTemplate;

import { includes } from 'lodash';
import config from '@plone/volto/registry';

export const getResolutionInPx = (item) => {
  if (
    !includes(config.settings.imageObjects, item['@type']) ||
    !item.image_scales?.[item.image_field]?.[0]
  ) {
    return [];
  }

  const image = item.image_scales[item.image_field][0];
  return [image.width, image.height];
};

/**
 * Video view component.
 * @module components/Theme/VideoView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
// render using the same logic as the video block
import Video from '@plone/volto/components/manage/Blocks/Video/Body';
import Caption from 'volto-dlr/components/Image/Caption';

/**
 * Video view component class.
 * @function VideoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const VideoView = ({ content }) => {
  const image_scales = content.preview_image_link?.image_scales;
  if (image_scales) {
    image_scales.image[0].base_path = content.preview_image_link['@id'];
  }

  return (
    <Container className="view-wrapper">
      <h1 className="documentFirstHeading">{content.title}</h1>
      <figure>
        <Video
          data={{
            ...content,
            image_scales,
          }}
          image_field="image"
        />
        <Caption
          title={content.title}
          description={content.description}
          credit={content.credit?.data}
          shows_people={content.shows_people}
          video_length={content.video_length}
        />
      </figure>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
VideoView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    preview_image: PropTypes.shape({
      download: PropTypes.string,
    }),
  }).isRequired,
};

export default VideoView;

import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { getQuerystring } from '@plone/volto/actions';

const messages = defineMessages({
  Rss: {
    id: 'RSS View',
    defaultMessage: 'RSS View',
  },
});

const RssLinks = (props) => {
  const dispatch = useDispatch();
  const researchDomains = useSelector(
    (state) => state.querystring?.indexes?.research_domains?.values,
  );

  useEffect(() => {
    dispatch(getQuerystring());
  }, [props.currentLang, dispatch]);

  return (
    <>
      {researchDomains &&
        Object.keys(researchDomains)?.map((item) => (
          <li key={item}>
            <a
              aria-label="DLR RSS"
              href={`/++api++/@@rss?portal_type=News%20Item&research_domains=${item}&Language=${props.currentLang}`}
            >
              <span>{researchDomains[item]?.title}</span>
            </a>
          </li>
        ))}
    </>
  );
};

const EnglishRssView = (props) => {
  return (
    <>
      <div className="rss-container">
        <h1>RSS feeds on the re­search ar­eas</h1>
        <article>
          <p>
            You can keep up with the latest news from the DLR portal and
            connected topic-specific sites via an RSS news feed.
          </p>
          <p>
            RSS (Really Simple Syndication) is a platform-independent electronic
            news format. You can use it to retrieve content from a website, such
            as new articles or other changes published on it, to your desktop
            without needing to visit the relevant webpages directly. If you
            subscribe to an RSS news feed either by clicking on the RSS icon on
            our pages or using the links below the program will check the RSS
            file on the respective server at regular intervals and display new
            stories in your reader. You can find a large selection of available
            readers at rss-verzeichnis.de.
          </p>

          <p>
            Another option is to subscribe to RSS feeds in an online feed
            reader, which allows you to retrieve all the feeds you subscribe to
            on one webpage.
          </p>
          <p>
            Please note: To access the following links to the RSS feeds, you
            need a current browser (e.g.: Mozilla Firefox, Microsoft Edge).
            Also, newer versions of some browsers no longer support RSS feeds as
            dynamic bookmarks. In Firefox, addons installed in the browser
            provide a solution. For more information on using RSS feeds with
            Firefox, click here:
          </p>
          <p>
            <a
              href="https://support.mozilla.org/en-US/kb/feed-reader-replacements-firefox"
              target="_blank"
              rel="noreferrer"
              title="External link Mozilla Firefox RSS-Feed"
            >
              Feed Reader replacements for Firefox
            </a>
          </p>
          <p>
            <a
              href="https://www.zdnet.com/article/end-nears-for-rss-firefox-64-to-drop-built-in-support-for-rss-atom-feeds-says-mozilla/"
              target="_blank"
              rel="noreferrer"
              title="External link ZDNet Article Firefox RSS-Feed"
            >
              Firefox to drop built-in support for RSS
            </a>
          </p>
          <p>
            <strong>
              The following RSS news feeds are available on the DLR Portal:
            </strong>
          </p>
          <ul>
            <RssLinks currentLang={props.currentLang} />
          </ul>
        </article>
      </div>
    </>
  );
};

const GermanRssView = (props) => {
  return (
    <>
      <div className="rss-container">
        <h1> RSS-Feeds zu den For­schungs­be­rei­chen</h1>
        <article>
          <p>
            Sie können die Nachrichten des DLR Portals und angeschlossener
            Themensites aktuell per RSS-Newsfeed abrufen.
          </p>
          <p>
            RSS (Really Simple Syndication, deutsch etwa "wirklich einfache
            Verbreitung") ist ein plattformunabhängiges, elektronisches
            Nachrichtenformat. Sie können damit Inhalte einer Internetseite,
            dort erscheinende neue Beiträge oder Änderungen auf Ihren Desktop
            holen, ohne dass Sie die entsprechenden Seiten besuchen müssen. Wenn
            Sie einen RSS-Newsfeed abonnieren indem Sie auf das RSS-Symbol auf
            unseren Seiten oder auf die unten stehenden Links dieser Seite
            klicken überprüft das Programm in bestimmten Abständen die RSS-Datei
            des jeweiligen Servers auf neue Nachrichten und zeigt diese an. Für
            alle Betriebssysteme gibt es mittlerweile eine große Auswahl an
            Readern, die auf rss-verzeichnis.de gelistet sind.
          </p>

          <p>
            Eine weitere Möglichkeit besteht darin, RSS-Feeds in einem
            Online-Feedreader zu abonnieren. Sie besuchen dann immer nur eine
            Webseite, um alle Ihre Newsfeeds abzurufen.
          </p>
          <p>
            Bitte beachten Sie: Um die nachfolgenden Links zu den RSS-Feeds
            aufrufen zu können, benötigen Sie einen aktuellen Browser (z.B.:
            Mozilla Firefox, Microsoft Edge). Außerdem unterstützen neuere
            Versionen einiger Browser keine RSS-Feeds als dynamische Lesezeichen
            mehr. Bei Firefox schaffen RSS Reader Addons, die im Browser
            installiert werden, Abhilfe. Weitere Informationen zur Nutzung von
            RSS-Feeds bei Firefox finden Sie hier:
          </p>
          <p>
            <a
              className="RichTextExtLink ExternalLink"
              href="https://support.mozilla.org/de/kb/dynamische-lesezeichen"
              target="_blank"
              rel="noreferrer"
              title="Externer Link Hinweise von Mozilla zur Nutzung von RSS-Feeds"
            >
              Tipps für die Nutzung des RSS-Feeds bei Firefox
            </a>
          </p>
          <p>
            <a
              className="RichTextExtLink ExternalLink"
              href="https://www.heise.de/newsticker/meldung/Mozilla-bestaetigt-Firefox-64-ohne-RSS-Unterstuetzung-4190813.html"
              target="_blank"
              rel="noreferrer"
              title="Externer Link Hinweise Heise zur RSS-Feed Nutzung unter Mozilla Firefox"
            >
              Mozilla stellt RSS-Unterstützung ein
            </a>
          </p>
          <p>
            <strong>
              Folgende RSS-Newsfeeds stehen im DLR Portal zur Verfügung:
            </strong>
          </p>
          <ul>
            <RssLinks currentLang={props.currentLang} />
          </ul>
        </article>
      </div>
    </>
  );
};

const Rss = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);

  return (
    <div id="rss-view">
      <Container id="view">
        <Helmet title={intl.formatMessage(messages.Rss)} />
        {currentLang === 'de' ? (
          <GermanRssView currentLang={currentLang} />
        ) : (
          <EnglishRssView currentLang={currentLang} />
        )}
      </Container>
    </div>
  );
};

export default Rss;

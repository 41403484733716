import { defineMessages } from 'react-intl';

const messages = defineMessages({
  label_seo_noindex: {
    id: 'label_seo_noindex',
    defaultMessage: 'No Index',
  },
  label_showinsearch: {
    id: 'label_showinsearch',
    defaultMessage: 'Show in search',
  },
});

const contentsSchemaEnhancer = ({ schema, intl }) => {
  schema.properties.seo_noindex = {
    title: intl.formatMessage(messages.label_seo_noindex),
    type: 'boolean',
  };
  schema.properties.showinsearch = {
    title: intl.formatMessage(messages.label_showinsearch),
    type: 'boolean',
  };
  schema.fieldsets[0].fields = [
    ...schema.fieldsets[0].fields,
    'seo_noindex',
    'showinsearch',
  ];
  return schema;
};

export default contentsSchemaEnhancer;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  contactList: {
    id: 'Contacts List',
    defaultMessage: 'Contacts List',
  },
  item: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add Contact',
    defaultMessage: 'Add Contact',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  contactInitialPath: {
    id: 'contactInitialPath',
    defaultMessage: '/en/contacts',
  },
});

const itemSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: false,
        initialPath: intl.formatMessage(messages.contactInitialPath),
        sortOn: 'sortable_title',
        widgetOptions: {
          pattern_options: {
            selectableTypes: ['Contact'],
          },
        },
      },
    },
    required: [],
  };
};

export const ContactListSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.contactList),
    block: 'contactList',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['headline', 'hrefList'],
      },
    ],

    properties: {
      headline: {
        title: intl.formatMessage(messages.headline),
        default: intl.formatMessage(messages.item),
      },
      hrefList: {
        widget: 'object_list',
        title: intl.formatMessage(messages.items),
        schema: itemSchema,
        default: [],
      },
    },
    required: [],
  };
};

export const contactListSchemaEnhancer = ({
  formData,
  schema,
  intl,
  navRoot,
}) => {
  const DLR_BG_COLORS = [
    { name: 'transparent', label: 'Transparent' },
    { name: 'grey', label: 'Grey' },
  ];
  if (
    navRoot?.['@id']?.startsWith('/de/schoollab') ||
    navRoot?.['@id']?.startsWith('/en/schoollab') ||
    navRoot?.['@id']?.startsWith('/de/next') ||
    navRoot?.['@id']?.startsWith('/en/next')
  ) {
    schema.properties.styles.schema.properties.backgroundColor = {
      ...schema.properties.styles.schema.properties.backgroundColor,
      colors: DLR_BG_COLORS,
      default: 'transparent',
    };
  }

  return schema;
};

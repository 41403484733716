/**
 * OVERRIDE RssSidebar.jsx
 * REASON: The block's sidebar has unwanted fields (support for multiple feeds, Link More button, RSS source feed name),
 * and the block is not using a schema that allows to modify its widgets.
 * FILE: https://github.com/RedTurtle/volto-rss-block/blob/master/src/Block/RssSidebar.jsx
 * FILE VERSION: volto-rss-block 3.0.0
 * PULL REQUEST: https://github.com/kitconcept/dlr-internet/pull/1142
 * TICKET: https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/1330
 * DATE: 2023-04-10
 * DEVELOPER: @danalvrz
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { TextWidget } from '@plone/volto/components';
import NumberWidget from '@plone/volto/components/manage/Widgets/NumberWidget';

const messages = defineMessages({
  RssFeed: {
    id: 'RssFeed',
    defaultMessage: 'RSS Feed',
  },
  title: {
    id: 'title',
    defaultMessage: 'Title',
  },
  feed: {
    id: 'Feed',
    defaultMessage: 'Feed',
  },
  RssFeedURL: {
    id: 'RssFeed Url',
    defaultMessage: 'RSS Feed URL',
  },
  RssFeedUrlDescription: {
    id: 'RssFeed Url description',
    defaultMessage: 'To see the set feed, you need to save the content.',
  },
  RssFeedSource: {
    id: 'RssFeed Source',
    defaultMessage: 'RSS Feed Source name',
  },

  setrss: {
    id: 'setrss',
    defaultMessage: 'Set RSS feed',
  },
  limit: {
    id: 'RssLimit',
    defaultMessage: 'Maximum items to show',
  },
  linkMore: {
    id: 'linkMore',
    defaultMessage: 'Link more',
  },
  linkHref: {
    id: 'linkHref',
    defaultMessage: 'Link more href',
  },
  linkMoreTitle: {
    id: 'linkMoreTitle',
    defaultMessage: 'Title for link more',
  },
  addFeed: {
    id: 'RSSFeedAdd',
    defaultMessage: 'Add feed',
  },
});

const RssSidebar = ({ data, block, onChangeBlock, required = false }) => {
  const intl = useIntl();

  useEffect(() => {
    if (!data.limit || !data.feeds) {
      let newData = { ...data };
      newData.limit = data.limit ?? 10;
      newData.feeds = data.feeds ?? [{}];
      onChangeBlock(block, {
        ...newData,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>{intl.formatMessage(messages.RssFeed)}</h2>
      </header>

      <Segment>
        <TextWidget
          id="title"
          title={intl.formatMessage(messages.title)}
          required={false}
          value={data.title}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        {/*START CUSTOMIZATION*/}
        <NumberWidget
          id="limit"
          title={intl.formatMessage(messages.limit)}
          required={true}
          value={data.limit}
          defaultValue={10}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value > -1 ? parseInt(value) : null,
            });
          }}
        />
        {data.feeds ? (
          <TextWidget
            id="url"
            title={intl.formatMessage(messages.RssFeedURL)}
            description={intl.formatMessage(messages.RssFeedUrlDescription)}
            required={true}
            value={data.feeds[0].url || ''}
            onChange={(name, value) => {
              let newFeeds = [...data.feeds];
              newFeeds[0][name] = value;

              onChangeBlock(block, {
                ...data,
                feeds: newFeeds,
              });
            }}
          />
        ) : null}
      </Segment>
      {/*END CUSTOMIZATION*/}
    </Segment.Group>
  );
};

RssSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default RssSidebar;

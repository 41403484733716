/**
 * OVERRIDE withQueryString.jsx
 * REASON: Re-fetch querystring info if context changes.
 * FILE: https://github.com/plone/volto/blob/16.24.0/src/components/manage/Blocks/Search/hocs/withQueryString.jsx
 * FILE VERSION: Volto 16.24.0
 * TICKET: https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/2024
 * DATE: 2023-09-19
 * DEVELOPER: @davisagli
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuerystring } from '@plone/volto/actions';
import { usePrevious } from '@plone/volto/helpers';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * A HOC that injects querystring metadata information from the backend.
 *
 */
export default function withQueryString(WrappedComponent) {
  function WithQueryString(props) {
    const dispatch = useDispatch();

    const qs = useSelector((state) => state.querystring);
    const content = useSelector((state) => state.content.data);
    const prevContent = usePrevious(content);
    const contextChanged = content['@id'] !== prevContent?.['@id'];
    const indexes = qs?.indexes || {};

    React.useEffect(() => {
      if (Object.keys(indexes).length === 0 || contextChanged) {
        dispatch(getQuerystring());
      }
    }, [dispatch, indexes, contextChanged]);

    return <WrappedComponent {...props} querystring={qs} />;
  }
  WithQueryString.displayName = `WithQueryString(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithQueryString;
}

/*
 * OVERRIDE View.jsx
 * REASON: Update default button text as well as a11y issue.
 * FILE: https://github.com/kitconcept/volto-button-block/blob/master/src/components/View.jsx
 * FILE VERSION: 2.3.1
 * DATE: 2022-06-27
 * DEVELOPER: @IFlameing
 */

import React from 'react';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';
import { UniversalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  ButtonText: {
    id: 'Continue reading',
    defaultMessage: 'Continue reading',
  },
});

const View = ({ data, isEditMode, className }) => {
  const [hasLink, setHasLink] = React.useState(false);
  const intl = useIntl();

  React.useEffect(() => {
    if (data.href) {
      if (data.href && data.href.length > 0) {
        setHasLink(true);
      }
      if (data.href.length === 0) {
        setHasLink(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.href]);

  let link = hasLink ? (
    <UniversalLink href={data.href?.[0]?.['@id']} className="ui button">
      {data.title || intl.formatMessage(messages.ButtonText)}
    </UniversalLink>
  ) : (
    <Button className="noLink">
      {data.title || intl.formatMessage(messages.ButtonText)}
    </Button>
  );

  return (
    <div className={cx('block __button', className)}>
      <div className="button container">
        <div className={cx(`align ${data?.inneralign}`)}>{link}</div>
      </div>
    </div>
  );
};

export default View;

import React from 'react';
import { Container, Segment, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import Doormat from 'volto-dlr/components/Doormat/Doormat';
import MobileNavigation from 'volto-dlr/components/MobileNavigation/MobileNavigation';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import SubsiteTheming from 'volto-dlr/components/SubsiteTheming/SubsiteTheming';
import TopHeaderBar from './TopHeaderBar';
import StickyButton from 'volto-dlr/components/StickyButton/StickyButton';

import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import clearSVG from '@plone/volto/icons/clear.svg';
import zoomSVG from 'volto-dlr/icons/search-icon.svg';

import { Logo, Navigation } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  links: {
    id: 'Links',
    defaultMessage: 'Links',
  },
});

const MainHeader = (props) => {
  const { pathname } = props;
  const [showSitemap, setShowSitemap] = React.useState(false);
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot);
  const contentInfo = useSelector((state) => state.content);
  const intl = useIntl();
  const isSubsite = !isEmpty(iNavRootInfo.subsite);

  const modalHandler = () => {
    setShowSitemap(!showSitemap);
  };

  const subsitePath = iNavRootInfo.subsite['@id'];
  const search = subsitePath
    ? {
        pathname: flattenToAppURL(subsitePath),
        query: '?allow_local=true',
      }
    : { pathname, query: '?allow_subsite=true' };
  const searchUrl =
    search.pathname.replace(/\/$/, '').replace(/\/$/, '') +
    '/@@search' +
    search.query;

  return (
    <>
      <div
        className="top-header-wrapper"
        role={isSubsite ? 'navigation' : null}
        aria-label={isSubsite ? intl.formatMessage(messages.links) : null}
      >
        {isSubsite && <TopHeaderBar />}
      </div>
      <Segment basic className="header-wrapper" role="banner">
        <SubsiteTheming />
        {(subsitePath === '/de/karriere' || subsitePath === '/en/careers') &&
          contentInfo?.data?.show_sticky_action_button && (
            <StickyButton data={contentInfo?.data} />
          )}
        <Container>
          <div className="header">
            {!isSubsite && <TopHeaderBar />}
            {showSitemap && (
              <div>
                <button onClick={modalHandler} className="close-icon">
                  <Icon name={clearSVG} size="32px" color="#fff" />
                </button>
                <div
                  role="button"
                  className="modal"
                  onKeyDown={modalHandler}
                  onClick={modalHandler}
                  tabIndex={0}
                >
                  <Doormat columns="3" />
                </div>
              </div>
            )}

            <div className="logo-nav-wrapper">
              <div className="logo">
                <Logo iNavRootInfo={iNavRootInfo} isSubsite={isSubsite} />
              </div>
              <div className="grouped-utility-icons">
                <MobileNavigation pathname={pathname} />
                <Link
                  /* To have search select default global:
                to={pathname.replace(/\/$/, '') + '/@@search?allow_local=true&local=false'} */
                  /* To have search select default local and also allow local:
              to={pathname.replace(/\/$/, '') + '/@@search?allow_local=true'} */
                  /* To have search restrict to global only:
              to={pathname.replace(/\/$/, '') + '/@@search'} */
                  to={searchUrl}
                  className="search"
                  title={intl.formatMessage(messages.search)}
                >
                  <Button
                    className="institut-search"
                    basic
                    icon
                    aria-label={intl.formatMessage(messages.search)}
                  >
                    <Icon name={zoomSVG} size="36px" />
                  </Button>
                </Link>
              </div>
            </div>
            <Navigation pathname={pathname} />
          </div>
        </Container>
      </Segment>
    </>
  );
};

export default MainHeader;

/**
 * OVERRIDE Unauthorized.jsx
 * REASON: Remove login link because we don't want other to login.
 * FILE: https://github.com/plone/volto/blob/master/src/components/theme/Unauthorized/Unauthorized.jsx
 * DATE: 2023-06-19
 * DEVELOPER: @iRohitSingh
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * @module components/theme/Unauthorized/Unauthorized
 */

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import { slice } from 'lodash';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers';

const Unauthorized = () => {
  const dispatch = useDispatch();
  const error_message = useSelector((state) => state.apierror?.message);
  const language = useSelector((state) => state.intl.locale);
  const pathname = useSelector((state) => state.router.location.pathname);
  let location = useLocation();
  let pathnameWithoutSlash = getBaseUrl(location.pathname);
  if (pathnameWithoutSlash.endsWith('/')) {
    pathnameWithoutSlash = pathnameWithoutSlash.slice(0, -1);
  }

  const [navRoot, setNavRoot] = useState(`/${language}`);
  const { settings } = config;
  const isLive = settings.apiPath === 'https://www.dlr.de';

  useEffect(() => {
    const parts = pathname.split('/');
    dispatch(
      getContent(
        slice(parts, 0, parts[2] === 'site' ? 4 : 3).join('/'),
        null,
        'navRoot',
      ),
    ).then((resp) => {
      setNavRoot(
        flattenToAppURL(
          resp['@components'].nearest_inav_root.subsite['@id'] ||
            `/${language}`,
        ),
      );
    });
  }, [dispatch, pathname, language]);

  const contactRoute = language === 'de' ? 'kontakt-formular' : 'contact-form';

  return (
    <Container className="view-wrapper">
      {!isLive ? (
        <>
          <h1>
            <FormattedMessage
              id="You are not logged in."
              defaultMessage="You are not logged in."
            />
          </h1>
          <p>
            <FormattedMessage
              id="EditorialUnauthMessage"
              defaultMessage="Since you are not logged in to the editorial system, you cannot see the content either because it has not yet been published or because you have tried to execute a function that is reserved exclusively for editors."
            />
          </p>
          <p>
            <FormattedMessage
              id="Please log in to the editorial system to view, edit or publish content."
              defaultMessage="Please log in to the editorial system to view, edit or publish content."
            />
          </p>
          <Link
            to={{
              pathname: `${pathnameWithoutSlash}/login`,
              state: {
                // This is needed to cover the use case of being logged in in
                // another backend (eg. in development), having a token for
                // localhost and try to use it, the login route has to know that
                // it's the same as it comes from a logout
                isLogout: true,
              },
            }}
          >
            <FormattedMessage id="Log in now" defaultMessage="Log in now" />
          </Link>
        </>
      ) : (
        <div>
          <h1>
            <FormattedMessage id="Unauthorized" defaultMessage="Unauthorized" />
          </h1>
          <h3>{error_message}</h3>
          <p>
            <FormattedMessage
              id="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
              defaultMessage="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
              values={{
                site_admin: (
                  <Link to={`${navRoot}/${contactRoute}`}>
                    <FormattedMessage
                      id="Site Administration"
                      defaultMessage="Site Administration"
                    />
                  </Link>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="Thank you." defaultMessage="Thank you." />
          </p>
        </div>
      )}
    </Container>
  );
};

export default withServerErrorCode(401)(Unauthorized);

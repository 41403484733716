import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import ProjectFactsheetSchema from './schema';
import ProjectFactsheetView from './View';

const ProjectFactsheetEdit = (props) => {
  const {
    block,
    blocksConfig,
    data,
    onChangeBlock,
    selected,
    contentType,
    navRoot,
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const schema = ProjectFactsheetSchema({ ...props, intl });
  const dataAdapter = blocksConfig[data['@type']].dataAdapter;

  return (
    <>
      <ProjectFactsheetView {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(id, value) => {
            dataAdapter({
              block,
              data,
              id,
              onChangeBlock,
              value,
              dispatch,
            });
          }}
          block={block}
          formData={data}
          onChangeBlock={onChangeBlock}
          navRoot={navRoot}
          contentType={contentType}
        />
      </SidebarPortal>
    </>
  );
};

export default ProjectFactsheetEdit;

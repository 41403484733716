import React, { useState } from 'react';
import UrlWidget from '@plone/volto/components/manage/Widgets/UrlWidget';
import { Container, Segment, Dimmer, Loader, Form } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { getVideoMetadata } from '../../actions';
import { toast } from 'react-toastify';
import { Toast } from '@plone/volto/components';
import { defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { getBaseUrl } from '@plone/volto/helpers';

const messages = defineMessages({
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  titleFetched: {
    id: '{title} has been fetched.',
    defaultMessage: '{title} has been fetched.',
  },
  loading: {
    id: 'Fetching Data',
    defaultMessage: 'Fetching Data',
  },
  buttonText: {
    id: 'Import video',
    defaultMessage: 'Import video',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  errorContent: {
    id: 'Please paste a correct url',
    defaultMessage: 'Please paste a correct url',
  },
  titleUrl: {
    id: 'Video URL',
    defaultMessage: 'Video URL',
  },
  descriptionUrl: {
    id: 'Enter a video URL (YouTube, Vimeo, Quickchannel)',
    defaultMessage: 'Enter a video URL (YouTube, Vimeo, Quickchannel)',
  },
  heading: {
    id:
      'Import video from external video hosting platform as Plone Content type video.',
    defaultMessage:
      'Import video from external video hosting platform as Plone Content type video.',
  },
  fetchedPreview: {
    id: 'Fetched Metadata Preview',
    defaultMessage: 'Fetched Metadata Preview',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  embedUrl: {
    id: 'Embed Url',
    defaultMessage: 'Embed Url',
  },
  createContent: {
    id: 'Create video content type',
    defaultMessage: 'Create video content type',
  },
});

const VideoAdd = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');
  const [url, setUrl] = useState('');
  const onChange = (id, newValue) => {
    setUrl(newValue);
  };
  const location = useLocation();
  const pathname = location.pathname;

  const fetchMetadata = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(getVideoMetadata(getBaseUrl(pathname), url))
      .then((value) => {
        setLoading(false);
        const response = value;
        let target_url = response.target_url;
        let title = response.title;
        let description = response.description;
        if (!title) {
          return toast.error(
            <Toast
              error
              title={props.intl.formatMessage(messages.error)}
              content={props.intl.formatMessage(messages.errorContent)}
            />,
          );
        }
        setTitle(title);
        setDescription(description);
        setEmbedUrl(target_url);
        toast.success(
          <Toast
            success
            title={props.intl.formatMessage(messages.success)}
            content={props.intl.formatMessage(messages.titleFetched, {
              title: title,
            })}
          />,
        );
      })
      .catch((error) => {
        setLoading(false);
        return toast.error(
          <Toast
            error
            title={props.intl.formatMessage(messages.error)}
            content={props.intl.formatMessage(messages.errorContent)}
          />,
        );
      });
  };

  const createContent = () => {
    props.onSubmit({ title, description, target_url: embedUrl });
  };

  return (
    <Container className="add-video-container">
      <h2>{props.intl.formatMessage(messages.heading)}</h2>
      <Form onSubmit={fetchMetadata}>
        <Segment>
          {isLoading && (
            <Dimmer active>
              <Loader indeterminate>
                {props.intl.formatMessage(messages.loading)}
              </Loader>
            </Dimmer>
          )}
          <UrlWidget
            id="video-url"
            title={props.intl.formatMessage(messages.titleUrl)}
            description={props.intl.formatMessage(messages.descriptionUrl)}
            onChange={onChange}
          />
          <button type="submit">
            {props.intl.formatMessage(messages.buttonText)}
          </button>
        </Segment>
      </Form>
      {title && (
        <Segment>
          <h2 className="fetched-heading">
            {props.intl.formatMessage(messages.fetchedPreview)}
          </h2>
          <p>
            <span className="label">
              {props.intl.formatMessage(messages.title)} <br />
            </span>
            <span className="value">{title}</span>
          </p>
          <p>
            <span className="label">
              {props.intl.formatMessage(messages.description)}
            </span>

            <br />
            <span className="value">{description}</span>
          </p>
          <p>
            <span className="label">
              {props.intl.formatMessage(messages.embedUrl)} <br />
            </span>
            <span className="value">{embedUrl}</span>
          </p>
          <button onClick={createContent}>
            {props.intl.formatMessage(messages.createContent)}
          </button>
        </Segment>
      )}
    </Container>
  );
};

export default VideoAdd;

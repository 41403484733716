import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'semantic-ui-react';
import { Icon, UniversalLink } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import { confirm } from 'volto-dlr/actions/newsletter/newsletter';
import warningSVG from '@plone/volto/icons/warning.svg';

const messages = defineMessages({
  socialHref: {
    id: 'socialHref',
    defaultMessage: '/en/latest/social-media',
  },
  socialLinkText: {
    id: 'socialLinkText',
    defaultMessage: 'social media channels',
  },
});

const NewsletterConfirmationForm = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;

  const [state, setState] = React.useState({});

  useEffect(() => {
    dispatch(getContent(getBaseUrl(pathname))).then((content) => {
      if (__CLIENT__) {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        dispatch(confirm(content['@id'], token))
          .then(() => setState({ success: true }))
          .catch((error) => setState({ serverError: error }));
      }
    });
  }, [dispatch, pathname]);

  const content = useSelector((state) => state.content.data);

  return (
    <div
      className="contact-form-overlay"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      role="presentation"
    >
      <h1>{content?.title}</h1>
      {state.success ? (
        <fieldset>
          <legend className="sent">
            <FormattedMessage
              id="SubscribedMessage"
              defaultMessage="It worked! You will now receive regular DLR newsletters. We wish you informative entertainment."
            />
          </legend>
          <p>
            <FormattedMessage
              id="SocialPrompt"
              defaultMessage="You can also follow us on our {social_link}."
              values={{
                social_link: (
                  <UniversalLink href={intl.formatMessage(messages.socialHref)}>
                    {intl.formatMessage(messages.socialLinkText)}
                  </UniversalLink>
                ),
              }}
            />
          </p>
        </fieldset>
      ) : state.serverError ? (
        <Message negative>
          <div className="icon-container-warning">
            {' '}
            <Icon name={warningSVG} size="50px" color="red" />
          </div>
          <Message.Header>
            <FormattedMessage id="Error" defaultMessage="Error" />
          </Message.Header>
          <Message.Content>
            {state.serverError.response?.body?.message}
          </Message.Content>
        </Message>
      ) : null}
    </div>
  );
};

export default NewsletterConfirmationForm;

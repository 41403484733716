import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import cx from 'classnames';
import { map } from 'lodash';
import { Popup, Grid } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

import geoUrl from 'volto-dlr/static/4_niedrig.geo.json';

const MapBlockView = (props) => {
  const { data, isEditMode } = props;
  const locations = data.locations || [];
  const [hover, setHover] = useState(-1);

  return (
    <div className="block map">
      <div className="items">
        <Grid stackable columns={2}>
          <Grid.Column>
            <ComposableMap
              width="800"
              height="800"
              projection="geoAzimuthalEqualArea"
              projectionConfig={{
                rotate: [-10.0, -52.0, 0],
                center: [0.3, -0.8],
                scale: 5800,
              }}
            >
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      className="geography"
                      key={geo.rsmKey}
                      geography={geo}
                      stroke-width="2"
                    />
                  ))
                }
              </Geographies>
              {map(locations, (location, index) => (
                <Marker
                  coordinates={[
                    location.latitude || 0,
                    location.longitude || 0,
                  ]}
                >
                  {location.tooltip?.data &&
                  location.tooltip?.data !== '<p></p>' ? (
                    <Popup
                      hoverable
                      onMouseOver={() => setHover(index)}
                      onFocus={() => setHover(index)}
                      onMouseOut={() => setHover(-1)}
                      onBlur={() => setHover(-1)}
                      content={
                        <>
                          <div className="header">
                            <UniversalLink
                              href={
                                isEditMode
                                  ? null
                                  : location.href?.[0]?.anchor
                                  ? `${location.href?.[0]?.['@id']}#${location.href?.[0]?.anchor}`
                                  : location.href?.[0]?.['@id']
                              }
                            >
                              {location.title}
                            </UniversalLink>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: location.tooltip?.data,
                            }}
                          />
                        </>
                      }
                      key={location['@id']}
                      className="map-popup"
                      position="right center"
                      trigger={
                        <g
                          fill="none"
                          transform="translate(-23, -60) scale(1.5)"
                          onMouseOver={() => setHover(index)}
                          onFocus={() => setHover(index)}
                          onMouseOut={() => setHover(-1)}
                          onBlur={() => setHover(-1)}
                        >
                          <UniversalLink
                            href={
                              isEditMode
                                ? null
                                : location.href?.[0]?.anchor
                                ? `${location.href?.[0]?.['@id']}#${location.href?.[0]?.anchor}`
                                : location.href?.[0]?.['@id']
                            }
                          >
                            <path
                              className={cx('marker', {
                                hover: hover === index,
                              })}
                              d="M15.4914 0.302734C11.6074 0.319218 7.88709 1.90788 5.14067 4.72273C2.39425 7.53757 0.844208 11.3506 0.828125 15.3314C0.828125 23.4301 14.3713 39.0432 14.9375 39.7069C15.1129 39.8711 15.3435 39.9593 15.581 39.9532C15.6865 39.956 15.7913 39.9355 15.8883 39.893C15.9853 39.8505 16.0722 39.787 16.1431 39.7069C16.7093 39.0432 30.2524 23.4259 30.2524 15.3314C30.2034 11.3438 28.6294 7.53525 25.869 4.72479C23.1086 1.91433 19.3822 0.326481 15.4914 0.302734ZM15.4914 22.6954C14.072 22.6954 12.6844 22.264 11.5042 21.4557C10.3239 20.6475 9.40405 19.4987 8.86085 18.1546C8.31765 16.8105 8.17552 15.3315 8.45244 13.9047C8.72936 12.4778 9.41289 11.1672 10.4166 10.1385C11.4203 9.10974 12.6991 8.40918 14.0913 8.12536C15.4834 7.84154 16.9265 7.98721 18.2379 8.54394C19.5493 9.10068 20.6701 10.0435 21.4587 11.2531C22.2473 12.4627 22.6683 13.8849 22.6683 15.3397C22.665 17.2895 21.9079 19.1585 20.5626 20.5373C19.2174 21.916 17.3938 22.6921 15.4914 22.6954Z"
                            />
                            <path
                              d="M14.6633 0C10.7793 0.0164837 7.05896 1.60514 4.31254 4.41999C1.56612 7.23484 0.016083 11.0479 0 15.0286C0 23.1274 13.5432 38.7404 14.1093 39.4042C14.2848 39.5683 14.5154 39.6566 14.7529 39.6505C14.8584 39.6533 14.9632 39.6328 15.0602 39.5903C15.1572 39.5477 15.2441 39.4843 15.315 39.4042C15.8811 38.7404 29.4243 23.1232 29.4243 15.0286C29.3753 11.0411 27.8013 7.23252 25.0409 4.42206C22.2805 1.6116 18.5541 0.0237463 14.6633 0Z"
                              fill="rgba(255,255,255,0.0001)"
                            />
                          </UniversalLink>
                        </g>
                      }
                    />
                  ) : (
                    <g fill="none" transform="translate(-23, -60) scale(1.5)">
                      <UniversalLink
                        href={isEditMode ? null : location.href?.[0]?.['@id']}
                      >
                        <path
                          onMouseOver={() => setHover(index)}
                          onFocus={() => setHover(index)}
                          onMouseOut={() => setHover(-1)}
                          onBlur={() => setHover(-1)}
                          className={cx('marker', { hover: hover === index })}
                          d="M15.4914 0.302734C11.6074 0.319218 7.88709 1.90788 5.14067 4.72273C2.39425 7.53757 0.844208 11.3506 0.828125 15.3314C0.828125 23.4301 14.3713 39.0432 14.9375 39.7069C15.1129 39.8711 15.3435 39.9593 15.581 39.9532C15.6865 39.956 15.7913 39.9355 15.8883 39.893C15.9853 39.8505 16.0722 39.787 16.1431 39.7069C16.7093 39.0432 30.2524 23.4259 30.2524 15.3314C30.2034 11.3438 28.6294 7.53525 25.869 4.72479C23.1086 1.91433 19.3822 0.326481 15.4914 0.302734ZM15.4914 22.6954C14.072 22.6954 12.6844 22.264 11.5042 21.4557C10.3239 20.6475 9.40405 19.4987 8.86085 18.1546C8.31765 16.8105 8.17552 15.3315 8.45244 13.9047C8.72936 12.4778 9.41289 11.1672 10.4166 10.1385C11.4203 9.10974 12.6991 8.40918 14.0913 8.12536C15.4834 7.84154 16.9265 7.98721 18.2379 8.54394C19.5493 9.10068 20.6701 10.0435 21.4587 11.2531C22.2473 12.4627 22.6683 13.8849 22.6683 15.3397C22.665 17.2895 21.9079 19.1585 20.5626 20.5373C19.2174 21.916 17.3938 22.6921 15.4914 22.6954Z"
                        />
                        <path
                          d="M14.6633 0C10.7793 0.0164837 7.05896 1.60514 4.31254 4.41999C1.56612 7.23484 0.016083 11.0479 0 15.0286C0 23.1274 13.5432 38.7404 14.1093 39.4042C14.2848 39.5683 14.5154 39.6566 14.7529 39.6505C14.8584 39.6533 14.9632 39.6328 15.0602 39.5903C15.1572 39.5477 15.2441 39.4843 15.315 39.4042C15.8811 38.7404 29.4243 23.1232 29.4243 15.0286C29.3753 11.0411 27.8013 7.23252 25.0409 4.42206C22.2805 1.6116 18.5541 0.0237463 14.6633 0Z"
                          fill="rgba(255,255,255,0.0001)"
                        />
                      </UniversalLink>
                    </g>
                  )}
                </Marker>
              ))}
            </ComposableMap>
          </Grid.Column>
          <Grid.Column className="list">
            {map(locations, (location, index) => (
              <div>
                <UniversalLink
                  href={
                    isEditMode
                      ? null
                      : location.href?.[0]?.anchor
                      ? `${location.href?.[0]?.['@id']}#${location.href?.[0]?.anchor}`
                      : location.href?.[0]?.['@id']
                  }
                  onMouseOver={() => setHover(index)}
                  onFocus={() => setHover(index)}
                  onMouseOut={() => setHover(-1)}
                  onBlur={() => setHover(-1)}
                  className={cx({ hover: hover === index })}
                >
                  {location.title}
                </UniversalLink>
              </div>
            ))}
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};

export default MapBlockView;

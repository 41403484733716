import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { ConditionalLink, Icon } from '@plone/volto/components';
import rssSVG from 'volto-dlr/icons/rss.svg';

const messages = defineMessages({
  readMore: { id: 'rss_read_more', defaultMessage: 'Read more' },
  noResults: {
    id: 'rss_no_results',
    defaultMessage: 'No results from RSS feed.',
  },
});

const SimplifiedRssTemplate = ({ items, data, isEditMode }) => {
  const intl = useIntl();
  const rssFeed = {};
  rssFeed.to = data.feeds[0]?.url ?? '#';
  rssFeed['@id'] = data.feeds[0]?.url ?? '#';

  return (
    <div className="block-container">
      <>
        {data.title && (
          <h2 className="headline">
            {data.title}
            {/* condition is set as true, because isEditMode is unavailable */}
            {data.feeds && (
              <ConditionalLink
                item={rssFeed}
                condition={true}
                className="rss-feed-link"
                aria-label="RSS"
              >
                <Icon name={rssSVG} size="30px" />
              </ConditionalLink>
            )}
          </h2>
        )}
      </>
      {items?.length > 0 ? (
        <>
          <ol className="rss-feed-items-list">
            {items.map((item) => {
              item.to = item.url ?? '#';
              item['@id'] = item.url ?? '#';
              return (
                <li className="rss-feed-item" key={item['@id']}>
                  {/* condition is set as true, because isEditMode is unavailable */}
                  <ConditionalLink
                    item={item}
                    condition={true}
                    className="rss-feed-item-link"
                  >
                    {item.title ? item.title.replace(/:/, ': ') : item.id}
                  </ConditionalLink>
                </li>
              );
            })}
          </ol>
        </>
      ) : data.feeds?.length > 0 && Object.keys(data.feeds[0]).length !== 0 ? (
        <div className="no-rss-feed-results">
          {intl.formatMessage(messages.noResults)}
        </div>
      ) : null}
    </div>
  );
};
SimplifiedRssTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  isEditMode: PropTypes.bool,
};

export default SimplifiedRssTemplate;

import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import config from '@plone/volto/registry';

const targetWidths = {
  half: '500px', // small enough to use the "larger" scale size at 2x DPR
  default: '940px', // --default-container-width
  layout: '1440px', // --layout-container-width
  full: '100vw',
};

export const PreviewImg = ({
  previewImage,
  href = null,
  imageField = null,
  scaleName = 'preview',
  alt = '',
  loading = 'lazy',
  defaultImage = DefaultImageSVG,
  targetWidth = 'default',
  ...props
}) => {
  const Img = config.getComponent('Img').component;
  const src = previewImage || href;
  imageField = imageField || src?.image_field;
  const key = imageField || defaultImage;
  const imageInfo = src?.image_scales?.[imageField]?.[0];

  // add original image to scales
  const scales = imageInfo?.scales || {};
  if (imageInfo && scales) {
    const { download, width, height } = imageInfo;
    scales.original = { download, width, height };
  }

  // Give the browser a hint of what size to load.
  // (It can't do this from CSS which might load too late.)
  // If the viewport is below the tablet breakpoint,
  // pick a scale based on the viewport width.
  // (Most images are full width below this breakpoint.)
  // Otherwise we give a target width
  // based on how the image is used at desktop sizes.
  // The default targetWidth should work for most images
  // except ones that are always less than 500 logical pixels wide
  // (then use "half" to make sure we don't load a large scale)
  // or full layout width
  // (then use "layout" to make sure we get a large enough scale)
  const sizes = `(max-width: 769px) 100vw, ${targetWidths[targetWidth]}`;

  return (
    <Img
      src={imageField ? imageInfo?.base_path || src?.['@id'] : null}
      key={key}
      alt={alt}
      loading={loading}
      scales={scales}
      sizes={sizes}
      defaultScale={scaleName}
      blurhash={imageInfo?.blurhash}
      placeholder={<img alt={alt} src={defaultImage} />}
      {...props}
    />
  );
};

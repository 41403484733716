import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getService } from 'volto-dlr/actions/getservice/getservice';
import { Container } from 'semantic-ui-react';
import NotFound from '@plone/volto/components/theme/NotFound/NotFound';

const useGet = (path) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    dispatch(getService(path))
      .then((result) => setResult(result))
      .catch((error) => setError(error));
  }, [path, dispatch]);
  return [result, error];
};

const EditorEmails = (props) => {
  const token = useSelector((state) => state.userSession?.token);
  const [result, error] = useGet('/@editors');
  return token ? (
    <Container className="editor-emails">
      <h1>Editor Emails</h1>
      <textarea
        style={{ width: '100%', minHeight: '300px' }}
        value={
          result
            ? result.map((user) => user.email).join(',')
            : error
            ? JSON.stringify(error, null, 4)
            : 'Loading...'
        }
        readOnly
      />
    </Container>
  ) : (
    <NotFound {...props} />
  );
};

export default EditorEmails;

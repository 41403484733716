import React from 'react';
import { BodyClass } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const StagingBanner = () => {
  return (
    <>
      {config.settings.apiPath.includes('staging') ? (
        <>
          <BodyClass className="is-staging" />
          <div className="banner-header">STAGING</div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default StagingBanner;

import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import EventDates from 'volto-dlr/components/Blocks/EventMetadata/EventDates';

import { PreviewImg } from 'volto-dlr/helpers';

const EventImageListingTemplate = ({ items, linkMore, isEditMode, intl }) => {
  let link = null;
  let href = linkMore?.href || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink
        to={flattenToAppURL(href)}
        condition={!isEditMode}
        className="ui button"
      >
        {linkMore?.title || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkMore?.title || href}</a>;
  }

  return (
    <>
      <div className="event-listing-template-image">
        <div className="items">
          {items.map((item) => (
            <div key={item.UID} className="event-item">
              <div className="content">
                <div className="listing-item" key={item['@id']}>
                  <div className="text">
                    <ConditionalLink
                      to={flattenToAppURL(item['@id'])}
                      condition={!isEditMode}
                    >
                      <div className="image-wrapper">
                        <PreviewImg
                          previewImage={item}
                          blurhashOptions={{
                            style: {
                              width: '100%',
                              height: 'auto',
                              alignSelf: 'center',
                            },
                          }}
                          targetWidth="half"
                        />
                      </div>
                      <div className="listing-body">
                        <div className="head-title">
                          {item?.['@type'] === 'Event' && item?.start && (
                            <EventDates content={item} showTime={false} />
                          )}
                        </div>
                        <h2>{item.title ? item.title : item.id}</h2>
                        <p className="description">{item?.description}</p>
                      </div>
                    </ConditionalLink>
                    {item?.subjects && item.subjects?.length >= 1 ? (
                      <div className="tags">
                        {item.subjects.map((item, i) => {
                          return (
                            <ConditionalLink
                              to={`/search?Subject=${item}`}
                              condition={!isEditMode}
                            >
                              {item}
                            </ConditionalLink>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {link && <div className="block-footer">{link}</div>}
      </div>
    </>
  );
};
EventImageListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default EventImageListingTemplate;

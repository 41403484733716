import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {} from 'react-intl';
import { isEmpty } from 'lodash';
import config from '@plone/volto/registry';

import LogoDe from '../../customizations/volto/components/theme/Logo/Logo-de.svg';
import LogoEn from '../../customizations/volto/components/theme/Logo/Logo-en.svg';
import LogoSimple from '../../customizations/volto/components/theme/Logo/LogoSimple.svg';

const messages = defineMessages({
  JobOffer: {
    id: 'Job offers',
    defaultMessage: 'Job offers',
  },
  EASJobOffer: {
    id: 'ESA job offers',
    defaultMessage: 'ESA job offers',
  },
  EUMETJobOffer: {
    id: 'EUMETSAT job offers',
    defaultMessage: 'EUMETSAT job offers',
  },
  Application: {
    id: 'Unsolicited application',
    defaultMessage: 'Unsolicited application',
  },
});

const Logo = {
  de: LogoDe,
  en: LogoEn,
};
const logoHeight = {
  de: 70,
  en: 93,
};

const CareerPortalFooter = ({
  content,
  intl,
  subsitePath,
  isSubsite,
  lang,
}) => {
  return (
    <Grid.Column className="center">
      <h2>
        <FormattedMessage id="JobContact" defaultMessage="Jobs & contact" />
      </h2>
      <div className="details">
        <ul>
          <li>
            <a
              aria-label={intl.formatMessage(messages.JobOffer)}
              href="https://dlr.concludis.de/prj/lst/a181a603769c1f98ad927e7367c7aa51/GesamtlisteOffenePositionen.htm"
            >
              {intl.formatMessage(messages.JobOffer)}
            </a>
          </li>
          <li>
            <a
              aria-label={intl.formatMessage(messages.Application)}
              to={`${subsitePath}`}
              href={
                lang === 'de'
                  ? '/de/karriere/dein-einstieg/initiativ-bewerben'
                  : '/en/careers/your-entry/unsolicited-application'
              }
            >
              {intl.formatMessage(messages.Application)}
            </a>
          </li>
          <li>
            <a
              href={
                lang === 'de'
                  ? '/de/karriere/esa-stellenausschreibungen'
                  : '/en/careers/esa-vacancies'
              }
              aria-label={intl.formatMessage(messages.EASJobOffer)}
            >
              {intl.formatMessage(messages.EASJobOffer)}
            </a>
          </li>
          <li>
            <a
              href={
                lang === 'de'
                  ? '/de/karriere/eumetsat-stellenausschreibungen'
                  : '/en/careers/eumetsat-vacancies'
              }
              aria-label={intl.formatMessage(messages.EUMETJobOffer)}
            >
              {intl.formatMessage(messages.EUMETJobOffer)}
            </a>
          </li>

          <li>
            <NavLink
              aria-label="Contact"
              to={
                lang === 'de'
                  ? `${subsitePath}/kontakt-formular`
                  : `${subsitePath}/contact-form`
              }
            >
              <FormattedMessage
                id="Contact form"
                defaultMessage="Contact form"
              />
            </NavLink>
          </li>
          {!isSubsite ? (
            <li>
              <NavLink
                aria-label="Newsletter"
                to={`/${lang}/service/newsletter`}
              >
                <FormattedMessage id="Newsletter" defaultMessage="Newsletter" />
              </NavLink>
            </li>
          ) : null}
        </ul>
      </div>
    </Grid.Column>
  );
};

const PortalFooter = ({ content }) => {
  const lang = useSelector((state) => state.intl.locale);
  const intl = useIntl();
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot);
  const isSubsite = !isEmpty(iNavRootInfo.subsite);
  const logoWidth = isSubsite ? 82 : 310;
  const subsitePath = iNavRootInfo?.subsite?.['@id'];
  const isCareer =
    subsitePath === '/de/karriere' || subsitePath === '/en/careers';

  return (
    <>
      <div className="doormat">
        <div className="portal-footer">
          <Container>
            <div className="icon-wrapper top logo detached">
              <img
                src={isSubsite ? LogoSimple : Logo[lang]}
                alt="DLR logo"
                width={logoWidth}
                height={isSubsite ? 70 : logoHeight[lang]}
              />
              <div className="logo-title">
                <div className="logo-textline-1">
                  {iNavRootInfo.subsite.logo_textline_1}
                </div>
                <div className="logo-textline-2">
                  {iNavRootInfo.subsite.logo_textline_2}
                </div>
              </div>
            </div>
            <Grid stackable columns={3}>
              <Grid.Column className="left">
                <div
                  className={`icon-wrapper top logo ${isSubsite ? '' : lang}`}
                >
                  <img
                    src={isSubsite ? LogoSimple : Logo[lang]}
                    alt="DLR Logo"
                    width={logoWidth}
                    height={isSubsite ? 70 : logoHeight[lang]}
                  />
                  <div className="logo-title">
                    <div className="logo-textline-1">
                      {iNavRootInfo.subsite.logo_textline_1}
                    </div>
                    <div className="logo-textline-2">
                      {iNavRootInfo.subsite.logo_textline_2}
                    </div>
                  </div>
                </div>
                <div className="text">
                  {isSubsite ? (
                    <p
                      style={{ whiteSpace: 'pre-line' }}
                      dangerouslySetInnerHTML={{
                        __html: iNavRootInfo.subsite.footer_address,
                      }}
                    />
                  ) : (
                    <p>
                      <FormattedMessage
                        id="FooterAddress"
                        defaultMessage="Address"
                      />
                      : DLR e.V.
                      <br />
                      Linder Höhe
                      <br /> 51147 Köln
                    </p>
                  )}
                </div>
              </Grid.Column>
              {isCareer ? (
                <CareerPortalFooter
                  content={content}
                  intl={intl}
                  subsitePath={subsitePath}
                  isSubsite={isSubsite}
                  lang={lang}
                />
              ) : (
                <Grid.Column className="center">
                  <h2>
                    <FormattedMessage id="Contact" defaultMessage="Contact" />
                  </h2>
                  <div className="details">
                    <ul>
                      <li>
                        <NavLink
                          aria-label="Contact"
                          to={
                            lang === 'de'
                              ? !isSubsite
                                ? '/de/kontakt-formular'
                                : `${subsitePath}/kontakt-formular`
                              : !isSubsite
                              ? '/en/contact-form'
                              : `${subsitePath}/contact-form`
                          }
                        >
                          <FormattedMessage
                            id="Contact form"
                            defaultMessage="Contact form"
                          />
                        </NavLink>
                      </li>
                      {!isSubsite ? (
                        <li>
                          <NavLink
                            aria-label="Newsletter"
                            to={`/${lang}/service/newsletter`}
                          >
                            <FormattedMessage
                              id="Newsletter"
                              defaultMessage="Newsletter"
                            />
                          </NavLink>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </Grid.Column>
              )}
              <Grid.Column className="right">
                <h2>
                  <FormattedMessage
                    id="About the website"
                    defaultMessage="About the website"
                  />
                </h2>
                <div className="details">
                  <ul>
                    <li>
                      <NavLink
                        aria-label="Accessibility"
                        to={
                          lang === 'de'
                            ? '/de/service/barrierefreiheit'
                            : '/en/service/accessibility'
                        }
                      >
                        <FormattedMessage
                          id="Accessibility"
                          defaultMessage="Accessibility"
                        />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        aria-label="Privacy Policy"
                        to={
                          lang === 'de'
                            ? '/de/service/datenschutz'
                            : '/en/service/privacypolicy'
                        }
                      >
                        <FormattedMessage
                          id="Privacy Policy"
                          defaultMessage="Privacy Policy"
                        />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        aria-label="Privacy Settings"
                        to={
                          lang === 'de'
                            ? config.settings.DSGVOBanner.privacy_url.de
                            : config.settings.DSGVOBanner.privacy_url.en
                        }
                      >
                        <FormattedMessage
                          id="Privacy Settings (Cookies)"
                          defaultMessage="Privacy Settings (Cookies)"
                        />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        aria-label="Imprint & terms of use"
                        to={
                          lang === 'de'
                            ? '/de/service/impressum'
                            : '/en/service/imprint'
                        }
                      >
                        <FormattedMessage
                          id="Imprint & terms of use"
                          defaultMessage="Imprint & terms of use"
                        />
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default PortalFooter;

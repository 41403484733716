import { defineMessages } from 'react-intl';
import config from '@plone/volto/registry';

const messages = defineMessages({
  modifiedHeadTitle: {
    id: 'Date / Time',
    defaultMessage: 'Date / Time',
  },
  align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  hideImage: {
    id: 'Hide Image',
    defaultMessage: 'Hide Image',
  },
  backgroundColor: {
    id: 'Background color',
    defaultMessage: 'Background color',
  },
  variation: {
    id: 'Variation',
    defaultMessage: 'Variation',
  },
});

// schema enhancer for both standalone and grid teasers
export const teaserSchemaEnhancer = ({ schema, formData, intl }) => {
  // Don't show head_title field for News Item (it shows the date instead)
  if (formData.href?.[0]?.['@type'] === 'News Item') {
    schema.fieldsets[0].fields = [
      'href',
      'title',
      'description',
      'preview_image',
    ];
  }

  // Add "hide image" field
  schema.fieldsets[0].fields.push('hideImage');
  schema.properties.hideImage = {
    title: intl.formatMessage(messages.hideImage),
    type: 'boolean',
  };

  // fetch more metadata needed for rendering
  schema.properties.href.selectedItemAttrs = [
    ...schema.properties.href.selectedItemAttrs,
    'effective',
    'getRemoteUrl',
    'getObjSize',
    'mime_type',
  ];

  return schema;
};

// schema enhancer for standalone teaser only
export const standaloneTeaserSchemaEnhancer = ({ schema, formData, intl }) => {
  // Adjust label for head_title
  schema.properties.head_title.title = intl.formatMessage(
    messages.modifiedHeadTitle,
  );

  // adjust styling fieldset
  const colors = config.blocks?.blocksConfig?.[formData['@type']]?.colors;
  const defaultBGColor =
    config.blocks?.blocksConfig?.[formData['@type']]?.defaultBGColor;
  schema.properties.styles.schema.fieldsets[0].fields = [
    'align',
    'backgroundColor',
  ];
  schema.properties.styles.schema.properties.backgroundColor = {
    widget: 'color_picker',
    title: intl.formatMessage(messages.backgroundColor),
    colors,
    default: defaultBGColor,
  };
  schema.properties.styles.schema.properties.align = {
    widget: 'inner_align',
    title: intl.formatMessage(messages.align),
    actions: ['left', 'right', 'center'],
    default: 'left',
  };
  return schema;
};

// schema enhancer for grid teaser only
export const gridTeaserSchemaEnhancer = ({ schema, intl, navRoot }) => {
  // remove styling fieldset
  schema.fieldsets = schema.fieldsets.filter((item) => item.id !== 'styling');

  return schema;
};

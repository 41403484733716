/**
 * contactform actions.
 * @module actions/contactform/contactform
 */

import { flattenToAppURL } from '@plone/volto/helpers';
import { EMAIL_NOTIFICATION } from '@plone/volto/constants/ActionTypes';

export const FORM_TICKET = 'FORM_TICKET';
export const CONTACTFORM_SUBMIT = 'CONTACTFORM_SUBMIT';
export const CONTACTFORM_RESET = 'CONTACTFORM_RESET';

/**
 * Get form ticket
 * @function getFormTicket
 * @param {string} contextPath Context path.
 * @returns {Object} Action.
 */
export function getFormTicket(contextPath) {
  return {
    type: FORM_TICKET,
    request: {
      op: 'get',
      path: `${flattenToAppURL(contextPath)}/@form-ticket`,
    },
  };
}

export function submitContactForm(contactPath, data) {
  return {
    type: CONTACTFORM_SUBMIT,
    request: {
      op: 'post',
      path: `${flattenToAppURL(contactPath)}/@contact-form`,
      data,
    },
  };
}

export function submitSubsiteContactForm(subsitePath, data) {
  return {
    type: CONTACTFORM_SUBMIT,
    request: {
      op: 'post',
      path: `${flattenToAppURL(subsitePath)}/@contact-form`,
      data,
    },
  };
}

export function submitSchoollabContactForm(subsitePath, data) {
  return {
    type: CONTACTFORM_SUBMIT,
    request: {
      op: 'post',
      path: `${flattenToAppURL(subsitePath)}/@contact-form-schoollab`,
      data,
    },
  };
}

export function resetContactForm() {
  return {
    type: CONTACTFORM_RESET,
  };
}

// Modified from volto to add ticket
export function emailNotification(ticket, from, message, name, subject, topic) {
  return {
    type: EMAIL_NOTIFICATION,
    request: {
      op: 'post',
      path: '/@email-notification',
      data: {
        ticket,
        from,
        message,
        name,
        subject,
        topic,
      },
    },
  };
}

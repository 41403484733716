import { isEmpty } from 'lodash';
import { getContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { toast } from 'react-toastify';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  IncorrectImageContentType: {
    id: 'Only Image content type URL is allowed.',
    defaultMessage: 'Only Image content type URL is allowed.',
  },
  IncorrectLink: {
    id: 'Please enter a valid link.',
    defaultMessage: 'Please enter a valid link.',
  },
  Error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
});

export const ImageListDataAdapter = ({
  block,
  data,
  id,
  onChangeBlock,
  value,
  dispatch,
  intl,
}) => {
  if (id === 'url' && !isEmpty(value)) {
    if (value !== data[id]) {
      dispatch(getContent(flattenToAppURL(value), null, block))
        .then((resp) => {
          if (resp['@type'] === 'Image') {
            const itemData = { url: flattenToAppURL(value) };
            ['title', 'description'].map((n) => (itemData[n] = resp[n]));
            onChangeBlock(block, {
              ...data,
              ...itemData,
              alt: resp.alt_tag,
              image_scales: {
                image: [
                  {
                    blurhash: resp.blurhash,
                    ...resp.image,
                  },
                ],
              },
              credit: {
                data: resp.credit?.data,
              },
            });
          } else {
            toast.error(
              <Toast
                error
                title={intl.formatMessage(messages.Error)}
                content={intl.formatMessage(messages.IncorrectImageContentType)}
              />,
            );
          }
        })
        .catch((err) => {
          toast.error(
            <Toast
              error
              title={intl.formatMessage(messages.Error)}
              content={intl.formatMessage(messages.IncorrectLink)}
            />,
          );
        });
    }
  } else {
    const dataSaved = {
      ...data,
      [id]: value,
    };
    onChangeBlock(block, dataSaved);
  }
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTags } from 'volto-dlr/actions/blog/tags';

const useTags = () => {
  const dispatch = useDispatch();
  const blogUrl = useSelector(
    (state) => state.nearestInavRoot?.subsite?.['@id'],
  );
  useEffect(() => {
    if (blogUrl) {
      dispatch(getTags(blogUrl));
    }
  }, [dispatch, blogUrl]);
  return useSelector((state) => state.tags?.[blogUrl]?.lookup || {});
};

export { useTags };

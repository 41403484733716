import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addMessage: {
    id: 'addMessage facets',
    defaultMessage: 'Add Facet',
  },
});

export const searchBlockSchemaEnhancer = ({ schema, formData, intl }) => {
  schema.properties.showSearchButton.default = false;
  schema.properties.facets.schema.addMessage = intl.formatMessage(
    messages.addMessage,
  );
  schema.properties.query.default = {
    sort_on: 'effective',
    sort_order_boolean: 'descending',
  };
  schema.properties.facets.schema.properties.multiple.default = true;
  return schema;
};

import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Message, Button, Dimmer } from 'semantic-ui-react';
import { getBaseUrl, Helmet, usePrevious } from '@plone/volto/helpers';
import { getFormTicket, emailNotification } from 'volto-dlr/actions';
import { useLocation } from 'react-router-dom';
import { getContent } from '@plone/volto/actions';

const messages = defineMessages({
  ContactForm: {
    id: 'Contact - DLR Portal',
    defaultMessage: 'Contact - DLR Portal',
  },
  h1: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  general: {
    id: 'General',
    defaultMessage: 'General',
  },
  External: {
    id: 'External relations',
    defaultMessage: 'External relations',
  },
  Selection: {
    id: 'Selection examination pilot, air traffic controller',
    defaultMessage: 'Selection examination pilot, air traffic controller',
  },
  Comment: {
    id: "Comment on the website's content",
    defaultMessage: "Comment on the website's content",
  },
  Literature: {
    id: 'Literature/Publications',
    defaultMessage: 'Literature/Publications',
  },
  Satellite: {
    id: 'Satellite imagery planets',
    defaultMessage: 'Satellite imagery planets',
  },
  SatelliteEarth: {
    id: 'Satellite imagery of the earth',
    defaultMessage: 'Satellite imagery of the earth',
  },
  SatelliteProgram: {
    id: 'Student and youth programmes',
    defaultMessage: 'Student and youth programmes',
  },
  Technical: {
    id: 'Technical problems with our website',
    defaultMessage: 'Technical problems with our website',
  },
  Salutation: {
    id: 'Salutation',
    defaultMessage: 'Salutation',
  },
  Please: {
    id: 'Please select ...',
    defaultMessage: 'Please select ...',
  },
  Divers: {
    id: 'Divers',
    defaultMessage: 'Divers',
  },
  Mrs: {
    id: 'Mrs',
    defaultMessage: 'Mrs',
  },
  Mr: {
    id: 'Mr',
    defaultMessage: 'Mr',
  },
  Name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  Email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  Topic: {
    id: 'Topic',
    defaultMessage: 'Topic',
  },
  Subject: {
    id: 'Subject (max. 160 characters)',
    defaultMessage: 'Subject (max. 160 characters)',
  },
  Message: {
    id: 'Message (max. 1000 characters)',
    defaultMessage: 'Message (max. 1000 characters)',
  },
  h4: {
    id: 'Data protection regulations',
    defaultMessage: 'Data protection regulations',
  },
  DataProtection: {
    id:
      'We store the data entered by you in order to provide you with services. It will only be used for this purpose and will not be transferred to third parties. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
    defaultMessage:
      'We store the data entered by you in order to provide you with services. It will only be used for this purpose and will not be transferred to third parties. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
  },
  FirstCheckbox: {
    id:
      'I have read the data protection regulations carefully, I have understood the procedure described and hereby consent to the processing of my data for the purpose of contacting me.',
    defaultMessage:
      'I have read the data protection regulations carefully, I have understood the procedure described and hereby consent to the processing of my data for the purpose of contacting me.',
  },
  SecondCheckbox: {
    id:
      'Our offer is generally aimed at persons aged 16 years or older. Individuals under the age of 16 are not permitted to submit personal data to us without the consent of their parents or legal guardian.',
    defaultMessage:
      'Our offer is generally aimed at persons aged 16 years or older. Individuals under the age of 16 are not permitted to submit personal data to us without the consent of their parents or legal guardian.',
  },
  Submit: {
    id: 'Submit',
    defaultMessage: 'Submit',
  },
  messageSent: {
    id: 'Email sent',
    defaultMessage: 'Email sent',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  selectLabel: {
    id: 'Select group',
    defaultMessage: 'Select group',
  },
  confirmationH1: {
    id: 'Message sent',
    defaultMessage: 'Message sent',
  },
  confirmation: {
    id:
      'Thank you for your message, here with we confirm the receipt. We attach importance to your concern that will be processed soon. We request your patience, you will receive a response as soon as possible.',
    defaultMessage:
      'Thank you for your message, here with we confirm the receipt. We attach importance to your concern that will be processed soon. We request your patience, you will receive a response as soon as possible.',
  },
});

const ContactForm = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;

  // Get form ticket after component is mounted
  useEffect(() => {
    dispatch(getFormTicket('/'));
    dispatch(getContent(getBaseUrl(pathname)));
  }, [dispatch, pathname]);

  const { loading, loaded, serverError, ticket } = useSelector((state) => ({
    ticket: state.contactform.ticket,
    loading: state.emailNotification.loading,
    loaded: state.emailNotification.loaded,
    serverError: state.emailNotification.error,
  }));
  const wasLoading = usePrevious(loading);

  // scroll to top after form submission
  useEffect(() => {
    if (loaded) {
      window.scrollTo(0, 0);
    }
  }, [loaded]);

  const [error, setError] = React.useState({});
  const [state, setState] = React.useState({
    Group: '',
    Salutation: '',
    Name: '',
    Email: '',
    Message: '',
    Subject: '',
    FirstCheckbox: false,
    SecondCheckbox: false,
  });
  const onChangeHandler = (event) => {
    if (
      event.target.name === 'FirstCheckbox' ||
      event.target.name === 'SecondCheckbox'
    ) {
      setState({ ...state, [event.target.name]: !state[event.target.name] });
      setError({ ...error, [event.target.name]: false });
      return;
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const checkRequiredField = () => {
    if (state.FirstCheckbox === false) {
      setError((error) => {
        return { ...error, FirstCheckbox: true };
      });
      return true;
    }
    if (state.SecondCheckbox === false) {
      setError((error) => {
        return { ...error, SecondCheckbox: true };
      });
      return true;
    }

    return false;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (loading) return;
    const error = checkRequiredField();
    if (error) {
      return;
    }
    let message = `Themen: ${state.Group}\nAnrede: ${state.Salutation}\nName: ${state.Name}\nBetreff: ${state.Subject}\nEmail: ${state.Email}\nNachricht:\n${state.Message}`;
    dispatch(
      emailNotification(
        ticket,
        state.Email,
        message,
        state.Name,
        state.Subject,
        state.Group,
      ),
    );
  };

  return (
    <div id="contact-form">
      <Container id="view">
        <Helmet title={intl.formatMessage(messages.ContactForm)} />
        <Dimmer active={loading} page></Dimmer>
        {loaded && wasLoading ? (
          <>
            <h1>{intl.formatMessage(messages.confirmationH1)}</h1>
            <p className="confirmation-message">
              {intl.formatMessage(messages.confirmation)}
            </p>
          </>
        ) : (
          <>
            {' '}
            <div>
              <h1>{intl.formatMessage(messages.h1)}</h1>
            </div>
            <div>
              <div className="form-container">
                <form onSubmit={onSubmitHandler}>
                  <div className="filter-group-select">
                    <label htmlFor="Group">
                      {intl.formatMessage(messages.Topic)}
                    </label>
                    <select
                      id="group"
                      name="Group"
                      aria-label={intl.formatMessage(messages.selectLabel)}
                      onBlur={onChangeHandler}
                      onChange={onChangeHandler}
                      value={state.Group}
                    >
                      <option
                        title={intl.formatMessage(messages.general)}
                        value="Allgemein_Kategorie"
                      >
                        {intl.formatMessage(messages.general)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.External)}
                        value="Aussenbeziehungen_Kategorie"
                      >
                        {intl.formatMessage(messages.External)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.Selection)}
                        value="Auswahluntersuchung_Kategorie"
                      >
                        {intl.formatMessage(messages.Selection)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.Comment)}
                        value="Kommentar_zum_Inhalt_der_Website_Kategorie"
                      >
                        {intl.formatMessage(messages.Comment)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.Literature)}
                        value="Literatur_Publikationen_Kategorie"
                      >
                        {intl.formatMessage(messages.Literature)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.Satellite)}
                        value="Satellitenbilder_Planeten_Kategorie"
                      >
                        {intl.formatMessage(messages.Satellite)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.SatelliteEarth)}
                        value="Satellitenbilder_der_Erde_Kategorie"
                      >
                        {intl.formatMessage(messages.SatelliteEarth)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.SatelliteProgram)}
                        value="Schueler-_und_Jugendprogramme_Kategorie"
                      >
                        {intl.formatMessage(messages.SatelliteProgram)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.Technical)}
                        value="Technische_Probleme_mit_unserer_Website_Kategorie"
                      >
                        {intl.formatMessage(messages.Technical)}
                      </option>
                    </select>
                  </div>

                  <div className="salutation">
                    <div>
                      <span>
                        <label htmlFor="Salutation">
                          {intl.formatMessage(messages.Salutation)}
                        </label>
                      </span>
                    </div>
                    <select
                      id="Salutation"
                      name="Salutation"
                      onBlur={onChangeHandler}
                      onChange={onChangeHandler}
                      value={state.Salutation}
                    >
                      <option
                        title={intl.formatMessage(messages.Please)}
                        value="BitteWaehlen"
                      >
                        {intl.formatMessage(messages.Please)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.Divers)}
                        value="Divers"
                      >
                        {intl.formatMessage(messages.Divers)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.Mrs)}
                        value="Frau"
                      >
                        {intl.formatMessage(messages.Mrs)}
                      </option>
                      <option
                        title={intl.formatMessage(messages.Mr)}
                        value="Herr"
                      >
                        {intl.formatMessage(messages.Mr)}
                      </option>
                    </select>
                  </div>
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {intl.formatMessage(messages.Name)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="name"
                      name="Name"
                      onChange={onChangeHandler}
                      value={state.Name}
                      title="Name"
                      type="text"
                      size="50"
                      maxLength="50"
                    ></input>
                  </div>
                  <div className="custom-input-group">
                    <label htmlFor="email">
                      {intl.formatMessage(messages.Email)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="email"
                      name="Email"
                      value={state.Email}
                      onChange={onChangeHandler}
                      title="E-Mail"
                      type="email"
                      size="60"
                      maxLength="60"
                    />
                  </div>
                  <div className="custom-input-group">
                    <label htmlFor="subject">
                      {intl.formatMessage(messages.Subject)}{' '}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="subject"
                      name="Subject"
                      onChange={onChangeHandler}
                      value={state.Subject}
                      title={intl.formatMessage(messages.Subject)}
                      type="text"
                      size="160"
                      maxLength="160"
                    />
                  </div>
                  <div className="custom-input-group">
                    <label htmlFor="message">
                      {intl.formatMessage(messages.Message)}{' '}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <textarea
                      required
                      id="message"
                      name="Message"
                      value={state.Message}
                      onChange={onChangeHandler}
                      title={intl.formatMessage(messages.Message)}
                      cols="59"
                      rows="4"
                    ></textarea>
                  </div>
                  <div className="wrapper">
                    <h4>{intl.formatMessage(messages.h4)}:</h4>
                    <p>
                      {intl.formatMessage(messages.DataProtection)}{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          currentLang === 'de'
                            ? '/de/service/datenschutz'
                            : '/en/service/privacypolicy'
                        }
                      >
                        {intl.formatMessage(messages.h4)}
                      </a>
                    </p>
                    <br />
                    <div className="checkbox-wrapper">
                      <input
                        id="first-checkbox"
                        name="FirstCheckbox"
                        type="checkbox"
                        onChange={onChangeHandler}
                      />
                      <label
                        className={error.FirstCheckbox ? 'error' : ''}
                        htmlFor="first-checkbox"
                      >
                        {intl.formatMessage(messages.FirstCheckbox)}*
                      </label>
                    </div>
                    <br />
                    <div className="checkbox-wrapper">
                      <input
                        id="second-checkbox"
                        name="SecondCheckbox"
                        type="checkbox"
                        onChange={onChangeHandler}
                      />
                      <label
                        className={error.SecondCheckbox ? 'error' : ''}
                        htmlFor="second-checkbox"
                      >
                        {intl.formatMessage(messages.SecondCheckbox)}*
                      </label>
                    </div>
                  </div>
                  {serverError && (
                    <Message
                      icon="warning"
                      negative
                      header={intl.formatMessage(messages.error)}
                      content={serverError.response.body.message}
                    />
                  )}
                  <div className="button-container">
                    <Button
                      name="submit"
                      type="submit"
                      title={intl.formatMessage(messages.Submit)}
                      loading={loading}
                    >
                      {intl.formatMessage(messages.Submit)}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default ContactForm;

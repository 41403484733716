import { FormattedMessage } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { useSelector } from 'react-redux';
import { LanguageSelector } from '@plone/volto/components';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

const TopHeaderBar = () => {
  const currentLang = useSelector((state) => state.intl.locale);
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot);
  const isSubsite = !isEmpty(iNavRootInfo.subsite);

  return (
    <>
      {isSubsite && <div className="header-top mobile only tablet only"></div>}
      <div className="header-top mobile hidden tablet hidden">
        <ul className="links">
          {isSubsite && (
            <li>
              <UniversalLink
                className="back-to-home"
                href={iNavRootInfo.lrf['@id']}
                title="DLR Portal"
              >
                <FormattedMessage id="DLR Portal" defaultMessage="DLR Portal" />
              </UniversalLink>
            </li>
          )}
          <li>
            <Link
              className="presse-button"
              to={
                currentLang === 'de'
                  ? '/de/aktuelles/presse'
                  : '/en/latest/media-relations'
              }
            >
              <FormattedMessage id="Press" defaultMessage="Press" />
            </Link>
          </li>
          <li>
            <Link
              className="accessibity-button"
              to={
                currentLang === 'de'
                  ? '/de/service/barrierefreiheit'
                  : '/en/service/accessibility'
              }
            >
              <FormattedMessage
                id="HeaderAccessibility"
                defaultMessage="Accessibility"
              />
            </Link>
          </li>
        </ul>
        <LanguageSelector />
      </div>
    </>
  );
};

export default TopHeaderBar;

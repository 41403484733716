import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import {
  Container,
  Form,
  Message,
  Segment,
  Tab,
  Table,
} from 'semantic-ui-react';
import { useLocation, Link } from 'react-router-dom';
import { getBaseUrl, Helmet, flattenToAppURL } from '@plone/volto/helpers';
import { formatDate } from '@plone/volto/helpers/Utils/Date';
import { getContent } from '@plone/volto/actions';
import { Icon, Toolbar, Unauthorized } from '@plone/volto/components';
import { send, listMailings } from 'volto-dlr/actions/newsletter/newsletter';
import warningSVG from '@plone/volto/icons/warning.svg';
import NewsImageListingTemplate from 'volto-dlr/components/Blocks/ListingTemplate/newsListingImage';
import { Text } from 'slate';
import backSVG from '@plone/volto/icons/back.svg';
import { find } from 'lodash';
import { toast } from 'react-toastify';
import { Toast } from '@plone/volto/components';

const messages = defineMessages({
  NewsletterHistory: {
    id: 'NewsletterHistory',
    defaultMessage: 'History',
  },
  NewsletterDelivery: {
    id: 'NewsletterDelivery',
    defaultMessage: 'Newsletter Delivery',
  },
  SendNewsletter: {
    id: 'SendNewsletter',
    defaultMessage: 'Send Newsletter',
  },
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  topicNewsletterPath: {
    id: 'topicNewsletterPath',
    defaultMessage: '/en/service/newsletter/topic-newsletter',
  },
  topicNewsletterTitle: {
    id: 'topicNewsletterTitle',
    defaultMessage: 'Topic newsletter',
  },
  pressNewsletterPath: {
    id: 'pressNewsletterPath',
    defaultMessage: '/en/service/newsletter/press-newsletter',
  },
  pressNewsletterTitle: {
    id: 'pressNewsletterTitle',
    defaultMessage: 'Press newsletter',
  },
  EmailSubject: {
    id: 'EmailSubject',
    defaultMessage: 'E-mail Subject',
  },
  EmailBody: {
    id: 'EmailBody',
    defaultMessage: 'E-mail body (plaintext)',
  },
  TestSendRequired: {
    id: 'TestSendRequired',
    defaultMessage: 'You must do a test send first.',
  },
  TestSendSuccess: {
    id: 'TestSendSuccess',
    defaultMessage: 'Test newsletter has been sent, please check your inbox.',
  },
  PressRelease: {
    id: 'PressRelease',
    defaultMessage: 'DLR Press Release from {date}',
  },
  PressReleaseLink: {
    id: 'PressReleaseLink',
    defaultMessage: 'Find the press release with images and related links at:',
  },
  Contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  ArticleLink: {
    id: 'ArticleLink',
    defaultMessage: 'Find the full article with images and related links at:',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  NewsletterSent: {
    id: 'NewsletterSent',
    defaultMessage: 'This newsletter has been sent.',
  },
});

const NewsletterDelivery = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // get news item content
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    dispatch(getContent(getBaseUrl(pathname) + '?for_newsletter=1'));
  }, [dispatch, pathname]);
  const content = useSelector((state) => state.content.data);
  const actions = useSelector((state) => state.actions.actions);
  const newsletterAction = find(actions.object, {
    id: 'send-newsletter',
  });

  if (!newsletterAction) {
    return <Unauthorized />;
  }

  return (
    <Container id="page-newsletter-delivery">
      <Helmet title={intl.formatMessage(messages.NewsletterDelivery)} />
      <h1>{intl.formatMessage(messages.NewsletterDelivery)}</h1>
      <Segment loading={!content}>
        <NewsImageListingTemplate
          items={[
            {
              ...content,
              image_field: 'image',
              image_scales: {
                image: [
                  {
                    ...content.preview_image_link?.image_scales.image[0],
                    base_path: content.preview_image_link?.['@id'],
                  },
                ],
              },
            },
          ]}
        />
      </Segment>
      <Tab
        renderActiveOnly={false}
        panes={[
          {
            menuItem: intl.formatMessage(messages.NewsletterHistory),
            pane: (
              <Tab.Pane loading={!content}>
                <NewsletterHistory content={content} />
              </Tab.Pane>
            ),
          },
          {
            menuItem: intl.formatMessage(messages.SendNewsletter),
            pane: (
              <Tab.Pane loading={!content}>
                <NewsletterDeliveryForm {...props} content={content} />
              </Tab.Pane>
            ),
          },
        ]}
      />
      {__CLIENT__ &&
        createPortal(
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to={`${getBaseUrl(pathname)}`} className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />,
          document.getElementById('toolbar'),
        )}
    </Container>
  );
};

const NewsletterDeliveryForm = (props) => {
  const { content } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  // get selected newsletter
  const topicNewsletterPath = intl.formatMessage(messages.topicNewsletterPath);
  const pressNewsletterPath = intl.formatMessage(messages.pressNewsletterPath);
  const [newsletterPath, setNewsletterPath] = useState(topicNewsletterPath);
  const [newsletter, setNewsletter] = useState();
  const [formData, setFormData] = useState({
    subject: content.title,
    body: '',
  });
  const [topics, setTopics] = useState({});
  const setTopic = (list_id, value) =>
    setTopics({ ...topics, [list_id]: value });
  useEffect(() => {
    dispatch(getContent(newsletterPath, null, 'newsletter')).then((result) => {
      setNewsletter(result);
      setTopics({ [result.topics[0].list_id]: true });
      setFormData((formData) => ({
        ...formData,
        body: formatEmailBody(intl, content, result),
      }));
    });
  }, [dispatch, intl, newsletterPath, content]);
  const [testRecipient, setTestRecipient] = useState('');

  const [formState, setFormState] = useState({
    loading: false,
  });
  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.loading) return;
    if (!formState.successfulTest) {
      setFormState({
        ...formState,
        error: intl.formatMessage(messages.TestSendRequired),
      });
      return;
    }
    setFormState({ loading: true });
    dispatch(
      send(newsletterPath, {
        ...formData,
        based_on: content['@id'],
        list_ids: Object.keys(topics).filter((list_id) => topics[list_id]),
      }),
    )
      .then((result) => {
        setFormState({ result });
        toast.success(
          <Toast
            success
            title={intl.formatMessage(messages.success)}
            content={intl.formatMessage(messages.NewsletterSent)}
          />,
          { autoClose: false },
        );
      })
      .catch((error) => setFormState({ error }));
  };
  const handleTestSend = (event) => {
    event.preventDefault();
    dispatch(
      send(newsletterPath, {
        ...formData,
        based_on: content['@id'],
        list_ids: Object.keys(topics).filter((list_id) => topics[list_id]),
        send_test_to: testRecipient.split(','),
      }),
    )
      .then((result) => {
        setFormState({ successfulTest: true });
        toast.success(
          <Toast
            success
            title={intl.formatMessage(messages.success)}
            content={intl.formatMessage(messages.TestSendSuccess)}
          />,
        );
      })
      .catch((error) => setFormState({ error }));
  };

  if (content.review_state !== 'published') {
    return (
      <FormattedMessage
        id="NewsNotPublished"
        defaultMessage="This news item has not yet been published."
      />
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group grouped>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <FormattedMessage
            id="NewsletterType"
            defaultMessage="Newsletter Type"
          />
        </label>
        <Form.Radio
          label={intl.formatMessage(messages.topicNewsletterTitle)}
          checked={newsletterPath === topicNewsletterPath}
          onChange={() => setNewsletterPath(topicNewsletterPath)}
        />
        <Form.Radio
          label={intl.formatMessage(messages.pressNewsletterTitle)}
          checked={newsletterPath === pressNewsletterPath}
          onChange={() => setNewsletterPath(pressNewsletterPath)}
        />
      </Form.Group>
      {newsletter?.topics.length > 1 ? (
        <Form.Group widths="two">
          {newsletter?.topics.map((topic) => (
            <Form.Checkbox
              key={topic.list_id}
              label={topic.title}
              checked={topics[topic.list_id]}
              onChange={(e, data) => setTopic(topic.list_id, data.checked)}
            />
          ))}
        </Form.Group>
      ) : null}
      <Form.Group>
        <Form.Input
          name="subject"
          label={intl.formatMessage(messages.EmailSubject)}
          value={formData.subject}
          onChange={handleChange}
          width={16}
        />
      </Form.Group>
      <Form.Group>
        <Form.TextArea
          name="body"
          label={intl.formatMessage(messages.EmailBody)}
          value={formData.body}
          onChange={handleChange}
          width={16}
          rows="20"
        />
      </Form.Group>
      <hr />
      <Form.Group>
        <Form.Field width="3">
          <FormattedMessage
            id="SentTestEmailTo"
            defaultMessage="Send test e-mail to:"
          />
        </Form.Field>
        <Form.Input
          width="6"
          value={testRecipient}
          onChange={(e, data) => setTestRecipient(data.value)}
        />
        <Form.Button
          onClick={handleTestSend}
          disabled={testRecipient === ''}
          width="3"
        >
          <FormattedMessage
            id="NewsletterSendTest"
            defaultMessage="Send Test"
          />
        </Form.Button>
        <Form.Field>
          <p className="note">
            <FormattedMessage
              id="TestEmailHelp"
              defaultMessage="Note: Test emails can only be sent to email addresses which exist already as registered newsletter subscribers, otherwise an error message will occur."
            />
          </p>
        </Form.Field>
      </Form.Group>
      {formState.error && (
        <Message negative>
          <div className="icon-container-warning">
            {' '}
            <Icon name={warningSVG} size="50px" color="red" />
          </div>
          <Message.Header>
            <FormattedMessage id="Error" defaultMessage="Error" />
          </Message.Header>
          <Message.Content>
            {formState.error.response?.body?.message || formState.error}
          </Message.Content>
        </Message>
      )}
      <Form.Button
        primary
        name="submit"
        loading={formState.loading}
        disabled={!formState.successfulTest}
      >
        <FormattedMessage id="NewsletterSend" defaultMessage="Send" />
      </Form.Button>
    </Form>
  );
};

const serialize = (node) => {
  if (Text.isText(node)) {
    return node.text;
  }

  const children = node.children
    .map((n) => serialize(n))
    .join('')
    .replace(/[ ]+/g, ' ');

  switch (node.type) {
    case 'paragraph':
      return `${children}\n\n`;
    case 'li':
      return `- ${children}\n`;
    default:
      return children;
  }
};

const blockFormatters = {
  slate: (block) =>
    (block.value || [])
      .map((node) => serialize(node))
      .join('')
      .trim(),
  contactList: (block, intl) => {
    let result = '';
    block.hrefList.forEach((node) => {
      const contact = node?.href[0];
      if (contact.is_official_press_contact) {
        result += '\n\n';
        result +=
          [contact.first_name, contact.last_name].filter((el) => el).join(' ') +
          '\n';
        if (contact.position) {
          result += `${contact.position}\n`;
        }
        if (contact.company || contact.department) {
          result += '\n';
          if (contact.company) {
            result += `${contact.company}\n`;
          }
          if (contact.department) {
            result += `${contact.department}\n`;
          }
        }
        if (contact.phone || contact.email) {
          result += '\n';
          if (contact.phone) {
            result += `Tel: ${contact.phone}\n`;
          }
          if (contact.email) {
            result += `E-Mail: ${contact.email}\n`;
          }
        }
      }
    });
    if (result) {
      return `\n${intl.formatMessage(messages.Contact)}${result}\n`;
    } else {
      return '';
    }
  },
};

const formatEmailBody = (intl, content, newsletter) => {
  const isPress = newsletter.title.includes('Press');
  const body = [];
  const contentUrl = `https://www.dlr.de${flattenToAppURL(content['@id'])}`;

  if (newsletter.email_header) {
    body.push(newsletter.email_header);
  }

  if (isPress) {
    body.push(
      intl.formatMessage(messages.PressRelease, {
        date: formatDate({
          date: content.effective,
          locale: content.language.token,
          format: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          },
        }),
      }),
    );
  }

  body.push(
    `${content.head_title ? content.head_title + '\n' : ''}${content.title}\n`,
  );

  if (isPress) {
    body.push(
      `${intl.formatMessage(messages.PressReleaseLink)}\n${contentUrl}\n`,
    );
  }

  for (const blockId of content.blocks_layout.items.values()) {
    const block = content.blocks[blockId];
    const formatter = blockFormatters[block['@type']];
    if (formatter) {
      const text = formatter(block, intl);
      if (text) {
        body.push(text);
        if (!isPress) break;
      }
    }
  }

  if (!isPress) {
    body.push(`${intl.formatMessage(messages.ArticleLink)}\n${contentUrl}\n`);
  }

  if (newsletter.email_footer) {
    body.push(newsletter.email_footer);
  }

  return body.join('\n\n');
};

const NewsletterHistory = (props) => {
  const { content } = props;
  const dispatch = useDispatch();
  const [mailings, setMailings] = useState(null);
  useEffect(() => {
    if (content) {
      dispatch(listMailings(content['@id'])).then((result) =>
        setMailings(result.items),
      );
    }
  }, [dispatch, content]);

  return mailings === null ? (
    <FormattedMessage id="Loading" defaultMessage="Loading..." />
  ) : mailings.length ? (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage
              id="NewsletterDate"
              defaultMessage="Date/Time Sent"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="NewsletterType"
              defaultMessage="Newsletter Type"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="NewsletterTopics" defaultMessage="Topics" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="NewsletterSentBy" defaultMessage="Sent By" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {mailings.map((mailing, i) => (
        <Table.Row key={i}>
          <Table.Cell>
            {formatDate({
              date: mailing.sent_at,
              locale: content.language.token,
              includeTime: true,
            })}
          </Table.Cell>
          <Table.Cell>{mailing.newsletter.title}</Table.Cell>
          <Table.Cell>{mailing.topics.join(', ')}</Table.Cell>
          <Table.Cell>{mailing.sent_by}</Table.Cell>
        </Table.Row>
      ))}
    </Table>
  ) : (
    <FormattedMessage
      id="NoNewsletterMailings"
      defaultMessage="This content has not been sent as a newsletter yet."
    />
  );
};

export default NewsletterDelivery;

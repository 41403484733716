/**
 * Body audio block.
 * @module components/Blocks/Audio/Body
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Message } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { IfConfirm } from '@kitconcept/volto-dsgvo-banner/components';

/**
 * Body audio block class.
 * @class Body
 * @extends Component
 */
const Body = ({ data, isEditMode, image_field = 'preview_image_link' }) => {
  let placeholder = data.preview_image;
  const preview_image = data.image_scales?.[image_field]?.[0];
  if (!placeholder && preview_image) {
    placeholder = `${preview_image.base_path}/${
      preview_image?.scales?.larger?.download || preview_image?.download
    }`;
  }
  if (placeholder) {
    placeholder = flattenToAppURL(placeholder);
  }

  const embed = data.embed_code;

  let AudioProvider;
  if (embed.match('soundcl')) {
    AudioProvider = 'soundcloud';
  } else if (embed.match('podcaste')) {
    AudioProvider = 'podcaster';
  }

  return (
    <IfConfirm module={AudioProvider} data={data}>
      {AudioProvider === 'soundcloud' ? (
        <>
          <div
            className="soundcloud content"
            dangerouslySetInnerHTML={{ __html: data.embed_code }}
          />
        </>
      ) : (
        <>
          {AudioProvider === 'podcaster' ? (
            <div
              className="podcaster content"
              dangerouslySetInnerHTML={{ __html: data.embed_code }}
            />
          ) : (
            <>
              {isEditMode ? (
                <div>
                  <Message>
                    <center>
                      <FormattedMessage
                        id="Please enter a valid URL by deleting the block and adding a new audio block."
                        defaultMessage="Please enter a valid URL by deleting the block and adding a new audio block."
                      />
                    </center>
                  </Message>
                </div>
              ) : (
                <div className="invalidAudioFormat" />
              )}
            </>
          )}
        </>
      )}
    </IfConfirm>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Body.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Body;

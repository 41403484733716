import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import homeSVG from '@plone/volto/icons/home.svg';

const messages = defineMessages({
  root: {
    id: 'Root',
    defaultMessage: 'Root',
  },
});

const ContentsBreadcrumbsRootItem = () => {
  const intl = useIntl();

  return (
    <Icon
      name={homeSVG}
      size="18px"
      title={intl.formatMessage(messages.root)}
    />
  );
};

export default ContentsBreadcrumbsRootItem;

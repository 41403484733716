/**
 * OVERRIDE LinkView.jsx
 * REASON: Institute contenttype has a remote url which is not mandatory
 * FILE: https://github.com/plone/volto/blob/3e6406fd93afd4ead1deded3a8eae92e9341f2f4/src/components/theme/View/LinkView.jsx
 * FILE VERSION: Volto 16.0.0-alpha.35
 * DATE: 2022-09-22
 * DEVELOPER: @robgietema
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * Link View.
 * @module components/theme/View/LinkView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isInternalURL, flattenToAppURL } from '@plone/volto/helpers';
import { Container } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

/**
 * View container class.
 * @class View
 * @extends Component
 */
class LinkView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      remoteUrl: PropTypes.string,
    }),
    token: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    content: null,
    token: null,
  };

  componentDidMount() {
    if (!this.props.token) {
      const { remoteUrl } = this.props.content;
      // START CUSTOMIZATION
      if (remoteUrl) {
        // END CUSTOMIZATION
        if (isInternalURL(remoteUrl)) {
          this.props.history.replace(flattenToAppURL(remoteUrl));
        } else if (!__SERVER__) {
          window.location.href = flattenToAppURL(remoteUrl);
        }
        // START CUSTOMIZATION
      }
      // END CUSTOMIZATION
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { remoteUrl } = this.props.content;
    if (__SERVER__ && !this.props.token && remoteUrl) {
      return <Redirect to={remoteUrl} />;
    }
    return (
      <Container id="page-document">
        <h1 className="documentFirstHeading">{this.props.content.title}</h1>
        {this.props.content.description && (
          <p className="documentDescription">
            {this.props.content.description}
          </p>
        )}
        {remoteUrl && (
          <p>
            <FormattedMessage
              id="The link address is:"
              defaultMessage="The link address is:"
            />{' '}
            <UniversalLink href={remoteUrl}>
              {flattenToAppURL(remoteUrl)}
            </UniversalLink>
          </p>
        )}
      </Container>
    );
  }
}

export default LinkView;

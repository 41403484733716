/**
 * I just copied the UrlWidget and added the functionality to fetch
 * the embed url for video content type.
 * This widget design only for Video content type.
 */

/**
 * UrlWidget component.
 * @module components/manage/Widgets/UrlWidget
 */

import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Input, Button, Dimmer, Loader } from 'semantic-ui-react';

import { FormFieldWrapper, Icon } from '@plone/volto/components';
import { getVideoMetadata } from '../../actions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getBaseUrl } from '@plone/volto/helpers';
import { defineMessages } from 'react-intl';
import {
  addAppURL,
  isInternalURL,
  flattenToAppURL,
  URLUtils,
} from '@plone/volto/helpers';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

/** Widget to edit urls
 *
 * This is the default widget used for the `remoteUrl` field. You can also use
 * it by declaring a field like:
 *
 * ```jsx
 * {
 *  title: "URL",
 *  widget: 'url',
 * }
 * ```
 */

const messages = defineMessages({
  loading: {
    id: 'Fetching Embed Url.',
    defaultMessage: 'Fetching Embed URl.',
  },
  error: {
    id: 'Please provide a correct url.',
    defaultMessage: 'Please provide a correct url.',
  },
});
export const UrlWidget = (props) => {
  const {
    id,
    onChange,
    onBlur,
    onClick,
    minLength,
    maxLength,
    placeholder,
    isDisabled,
  } = props;
  const inputId = `field-${id}`;
  const [value, setValue] = useState(flattenToAppURL(props.value));
  const [isInvalid, setIsInvalid] = useState(false);

  //These line is added to original code of urlWidget.
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  /**
   * Clear handler
   * @method clear
   * @param {Object} value Value
   * @returns {undefined}
   */
  const clear = () => {
    setValue('');
    onChange(id, undefined);
  };

  const onChangeValue = (_value) => {
    let newValue = _value;
    if (newValue?.length > 0) {
      if (isInvalid && URLUtils.isUrl(URLUtils.normalizeUrl(newValue))) {
        setIsInvalid(false);
      }

      if (isInternalURL(newValue)) {
        newValue = flattenToAppURL(newValue);
      }
    }

    setValue(newValue);
    setError(false);

    newValue = isInternalURL(newValue) ? addAppURL(newValue) : newValue;

    if (!isInternalURL(newValue) && newValue.length > 0) {
      const checkedURL = URLUtils.checkAndNormalizeUrl(newValue);
      newValue = checkedURL.url;
      if (!checkedURL.isValid) {
        setIsInvalid(true);
      }
    }

    // This is the function responsible for fetching embed URL.
    if (props.formData['@type'] === 'Video') {
      setLoading(true);
      dispatch(getVideoMetadata(getBaseUrl(pathname), newValue))
        .then((value) => {
          setLoading(false);
          const response = value;
          let target_url = response.target_url;
          setValue(target_url);
          onChange(id, target_url === '' ? undefined : target_url);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });

      return;
    }
    onChange(id, newValue === '' ? undefined : newValue);
  };

  return (
    <FormFieldWrapper {...props} className="url wide">
      {isLoading && (
        <Dimmer active>
          <Loader indeterminate>
            {props.intl.formatMessage(messages.loading)}
          </Loader>
        </Dimmer>
      )}
      <div className="wrapper">
        <Input
          id={inputId}
          name={id}
          type="url"
          value={value || ''}
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={({ target }) => onChangeValue(target.value)}
          onBlur={({ target }) =>
            onBlur(id, target.value === '' ? undefined : target.value)
          }
          onClick={() => onClick()}
          minLength={minLength || null}
          maxLength={maxLength || null}
          error={isInvalid}
        />
        {value?.length > 0 ? (
          <Button.Group>
            <Button
              basic
              className="cancel"
              aria-label="clearUrlBrowser"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                clear();
              }}
            >
              <Icon name={clearSVG} size="30px" />
            </Button>
          </Button.Group>
        ) : (
          <Button.Group>
            <Button
              basic
              icon
              aria-label="openUrlBrowser"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.openObjectBrowser({
                  mode: 'link',
                  overlay: true,
                  onSelectItem: (url) => {
                    onChangeValue(url);
                  },
                });
              }}
            >
              <Icon name={navTreeSVG} size="24px" />
            </Button>
          </Button.Group>
        )}
      </div>
      {error && (
        <div class="ui red basic label form-error-label">
          {props.intl.formatMessage(messages.error)}
        </div>
      )}
    </FormFieldWrapper>
  );
};

/**
 * Property types
 * @property {Object} propTypes Property types.
 * @static
 */
UrlWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  openObjectBrowser: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
UrlWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  minLength: null,
  maxLength: null,
};

export default compose(withObjectBrowser)(UrlWidget);

import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { useIntl, FormattedMessage } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import {
  isInternalURL,
  flattenToAppURL,
  usePrevious,
} from '@plone/volto/helpers';
import { ImageSchema } from '@plone/volto/components/manage/Blocks/Image/schema';
import ImageInput from 'volto-dlr/components/Blocks/Image/ImageInput';
import { ImageListDataAdapter } from 'volto-dlr/components/Blocks/Image/adapter';

const ImageSidebar = (props) => {
  const { data, block, onChangeBlock, contentType, navRoot } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const schema = ImageSchema({ formData: data, intl });

  const previousAlign = usePrevious(data.align);

  // BEGIN CUSTOMIZATION
  React.useEffect(() => {
    // Default for size and reset size to `l` if left/right and has other size
    if (previousAlign !== data.align) {
      if (!(data.align === 'left' || data.align === 'right')) {
        if (data.size !== 'l') {
          onChangeBlock(block, {
            ...data,
            size: 'l',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  // END CUSTOMIZATION

  return (
    <>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Image" defaultMessage="Image" />
        </h2>
      </header>

      <Segment className="sidebar-metadata-container" secondary attached>
        {data.url ? (
          <>
            {data.url.split('/').slice(-1)[0]}
            {isInternalURL(data.url) && (
              <img
                src={`${flattenToAppURL(data.url)}/@@images/image/preview`}
                alt={data.alt}
                width="80%"
                height="auto"
              />
            )}
            {!isInternalURL(data.url) && (
              <img src={data.url} alt={data.alt} style={{ width: '50%' }} />
            )}
          </>
        ) : (
          <>
            <ImageInput {...props} />
          </>
        )}
      </Segment>
      <BlockDataForm
        schema={schema}
        title={schema.title}
        onChangeField={(id, value) => {
          ImageListDataAdapter({
            block,
            data,
            id,
            onChangeBlock,
            value,
            dispatch,
            intl,
          });
        }}
        formData={data}
        block={block}
        navRoot={navRoot}
        contentType={contentType}
      />
    </>
  );
};

ImageSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default ImageSidebar;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  DasDLRBlock: {
    id: 'Das DLR',
    defaultMessage: 'Das DLR',
  },
  videoURL: {
    id: 'VideoURL',
    defaultMessage: 'Video URL',
  },
  videoHeadtitle: {
    id: 'VideoHeadtitle',
    defaultMessage: 'Video Headtitle',
  },
  videoTitle: {
    id: 'VideoTitle',
    defaultMessage: 'Video Title',
  },
  videoButtonLink: {
    id: 'Video Button Link',
    defaultMessage: 'Video Button Link',
  },
  videoButtonText: {
    id: 'Video Button Text',
    defaultMessage: 'Video Button Text',
  },
  videoImage: {
    id: 'VideoImage',
    defaultMessage: 'Video Image',
  },
  headlineLeft: {
    id: 'HeadlineLeft',
    defaultMessage: 'Left Headline',
  },
  headlineRight: {
    id: 'HeadlineRight',
    defaultMessage: 'Right Headline',
  },
  topLeftTeaser: {
    id: 'topLeftTeaser',
    defaultMessage: 'Top Left Teaser',
  },
  bottomLeftTeaser: {
    id: 'bottomLeftTeaser',
    defaultMessage: 'Bottom Left Teaser',
  },
  teaserRight: {
    id: 'teaserRight',
    defaultMessage: 'Right Teaser',
  },
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  buttonText: {
    id: 'Button Text',
    defaultMessage: 'Button Text',
  },
});

export const DasDLRSchema = ({ props, intl }) => ({
  title: intl.formatMessage(messages.DasDLRBlock),
  block: 'dasDLR',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
    {
      id: 'video',
      title: 'Video',
      fields: [
        'video',
        'video_headtitle',
        'video_title',
        'video_image',
        'video_button_link',
        'video_button_text',
        'video_openLinkInNewTab',
      ],
    },
    {
      id: 'teaserTopLeft',
      title: intl.formatMessage(messages.topLeftTeaser),
      fields: [
        'headline_left',
        'href_top_left',
        'title_top_left',
        'description_top_left',
        'openLinkInNewTab_top_left',
        'button_text_top_left',
      ],
    },
    {
      id: 'teaserBottomLeft',
      title: intl.formatMessage(messages.bottomLeftTeaser),
      fields: [
        'href_bottom_left',
        'title_bottom_left',
        'description_bottom_left',
        'openLinkInNewTab_bottom_left',
        'button_text_bottom_left',
      ],
    },
    {
      id: 'teaserRight',
      title: intl.formatMessage(messages.teaserRight),
      fields: [
        'headline_right',
        'href_right',
        'title_right',
        'description_right',
        'preview_image_right',
        'openLinkInNewTab_right',
      ],
    },
  ],
  properties: {
    video: {
      title: intl.formatMessage(messages.videoURL),
    },
    video_headtitle: {
      title: intl.formatMessage(messages.videoHeadtitle),
    },
    video_title: {
      title: intl.formatMessage(messages.videoTitle),
    },
    video_image: {
      title: intl.formatMessage(messages.videoImage),
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
    },
    video_button_link: {
      title: intl.formatMessage(messages.videoButtonLink),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['href'],
      allowExternals: true,
    },
    video_button_text: {
      title: intl.formatMessage(messages.videoButtonText),
    },
    video_openLinkInNewTab: {
      title: intl.formatMessage(messages.openLinkInNewTab),
      type: 'boolean',
    },
    headline_left: {
      title: intl.formatMessage(messages.headlineLeft),
    },
    headline_right: {
      title: intl.formatMessage(messages.headlineRight),
    },
    href_top_left: {
      title: intl.formatMessage(messages.Source),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Description'],
      allowExternals: true,
    },
    title_top_left: {
      title: intl.formatMessage(messages.title),
    },
    description_top_left: {
      title: intl.formatMessage(messages.description),
      widget: 'textarea',
    },
    openLinkInNewTab_top_left: {
      title: intl.formatMessage(messages.openLinkInNewTab),
      type: 'boolean',
    },
    button_text_top_left: {
      title: intl.formatMessage(messages.buttonText),
    },
    href_bottom_left: {
      title: intl.formatMessage(messages.Source),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Description'],
      allowExternals: true,
    },
    title_bottom_left: {
      title: intl.formatMessage(messages.title),
    },
    description_bottom_left: {
      title: intl.formatMessage(messages.description),
      widget: 'textarea',
    },
    openLinkInNewTab_bottom_left: {
      title: intl.formatMessage(messages.openLinkInNewTab),
      type: 'boolean',
    },
    button_text_bottom_left: {
      title: intl.formatMessage(messages.buttonText),
    },
    href_right: {
      title: intl.formatMessage(messages.Source),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: [
        'Title',
        'Description',
        'hasPreviewImage',
        'image_field',
        'image_scales',
      ],
      allowExternals: true,
    },
    title_right: {
      title: intl.formatMessage(messages.title),
    },
    description_right: {
      title: intl.formatMessage(messages.description),
      widget: 'textarea',
    },
    preview_image_right: {
      title: intl.formatMessage(messages.imageOverride),
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
    },
    openLinkInNewTab_right: {
      title: intl.formatMessage(messages.openLinkInNewTab),
      type: 'boolean',
    },
  },
  required: [],
});

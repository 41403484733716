import React from 'react';
import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import { useSelector } from 'react-redux';
import { HighlightSchema } from './schema';

const HighlightData = (props) => {
  const { block, data, onChangeBlock, contentType } = props;
  const intl = useIntl();
  const schema = HighlightSchema({ ...props, intl });
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot.subsite);

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
      navRoot={iNavRootInfo}
      contentType={contentType}
    />
  );
};

export default HighlightData;

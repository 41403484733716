import React from 'react';
import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { withBlockExtensions, flattenToAppURL } from '@plone/volto/helpers';
import { ConditionalLink, FormattedDate } from '@plone/volto/components';
import { formatMonthRange } from 'volto-dlr/helpers';
import cx from 'classnames';

const messages = defineMessages({
  ChooseProject: {
    id: 'ChooseProject',
    defaultMessage: 'Please choose a project/mission.',
  },
  ProjectMission: {
    id: 'Project / Mission',
    defaultMessage: 'Project / Mission',
  },
  ProjectGoal: {
    id: 'ProjectGoal',
    defaultMessage: 'Goal',
  },
  Duration: {
    id: 'Duration',
    defaultMessage: 'Duration',
  },
  MissionStart: {
    id: 'MissionStart',
    defaultMessage: 'Mission start',
  },
  ProjectParticipants: {
    id: 'ProjectParticipants',
    defaultMessage: 'Project participants',
  },
  ProjectSponsors: {
    id: 'ProjectSponsors',
    defaultMessage: 'Project sponsors',
  },
  TypeOfFunding: {
    id: 'TypeOfFunding',
    defaultMessage: 'Type of funding',
  },
  ExternalWebsite: {
    id: 'ExternalWebsite',
    defaultMessage: 'External project website',
  },
});

const renderLogo = (item, imageField) => {
  const scale = item[imageField]?.scales?.thumb;
  if (scale) {
    return <img src={scale.download} width={100} height={100} alt="" />;
  }
  if (item[imageField]?.data) {
    // while adding a new image we have raw image data
    const value = item[imageField];
    const dataUrl = `data:${value['content-type']};base64,${value.data}`;
    return <img src={dataUrl} width={100} height={100} alt="" />;
  }
};

const Logos = (props) => {
  const { logos } = props;
  return (
    <div className="flex-logos">
      {logos.map((logo) => (
        <ConditionalLink to={logo.href} condition={logo.href}>
          <figure className="logo">
            {logo.img}
            <figcaption>{logo.text}</figcaption>
          </figure>
        </ConditionalLink>
      ))}
    </div>
  );
};

const unpackLogos = (item, name) => {
  if (!item) return [];
  const logos = [];
  let i = 0;
  while (item[`${name}_${i}_text`]) {
    let img = <div></div>;
    if (item[`${name}_${i}_logo`]) {
      img = renderLogo(item, `${name}_${i}_logo`);
    }
    logos.push({
      text: item[`${name}_${i}_text`],
      img,
      href: item[`${name}_${i}_href`],
    });
    i += 1;
  }
  return logos;
};

const ProjectFactsheetView = (props) => {
  const { className, data, properties: content, isEditMode } = props;
  const intl = useIntl();
  const lang = useSelector((state) => state.intl.locale);
  // We would ideally check @type == "Research Project"
  // rather than the presence of project_goal,
  // but it isn't there in the add form.
  const project =
    data.project ??
    (Object.keys(content).includes('project_goal') ? content : null);
  const participantLogos = unpackLogos(project, 'participant');
  const sponsorLogos = unpackLogos(project, 'sponsor');
  return isEditMode || data.headline || project ? (
    <div className={cx('block project-factsheet', className)}>
      <div className="block-container">
        {data.headline && <h2 className="headline">{data.headline}</h2>}
        {project ? (
          <table className="ui celled table slate-table-block">
            <tr>
              <th>{intl.formatMessage(messages.ProjectMission)}</th>
              <td>
                <Logos
                  logos={[
                    {
                      text: project.title,
                      img: renderLogo(project, 'logo'),
                      href: data.project
                        ? flattenToAppURL(data.href[0]['@id'])
                        : null,
                    },
                  ]}
                />
              </td>
            </tr>
            {project.project_goal && (
              <tr>
                <th>{intl.formatMessage(messages.ProjectGoal)}</th>
                <td>{project.project_goal}</td>
              </tr>
            )}
            {(project.start || project.end) && (
              <tr>
                <th>{intl.formatMessage(messages.Duration)}</th>
                <td>{formatMonthRange(lang, project.start, project.end)}</td>
              </tr>
            )}
            {project.mission_start_date && (
              <tr>
                <th>{intl.formatMessage(messages.MissionStart)}</th>
                <td>
                  <FormattedDate date={project.mission_start_date} />
                </td>
              </tr>
            )}
            {participantLogos.length ? (
              <tr>
                <th>{intl.formatMessage(messages.ProjectParticipants)}</th>
                <td>
                  <Logos logos={participantLogos} />
                </td>
              </tr>
            ) : null}
            {sponsorLogos.length ? (
              <tr>
                <th>{intl.formatMessage(messages.ProjectSponsors)}</th>
                <td>
                  <Logos logos={sponsorLogos} />
                </td>
              </tr>
            ) : null}
            {project.funding_type && (
              <tr>
                <th>{intl.formatMessage(messages.TypeOfFunding)}</th>
                <td>{project.funding_type.title}</td>
              </tr>
            )}
            {project.remoteUrl && (
              <tr>
                <th>{intl.formatMessage(messages.ExternalWebsite)}</th>
                <td>
                  <a href={project.remoteUrl} target="_blank" rel="noreferrer">
                    {project.remoteUrl}
                  </a>
                </td>
              </tr>
            )}
          </table>
        ) : isEditMode ? (
          intl.formatMessage(messages.ChooseProject)
        ) : null}
      </div>
    </div>
  ) : null;
};

export default withBlockExtensions(ProjectFactsheetView);

import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Message, Button, Dimmer } from 'semantic-ui-react';
import { Helmet, getParentUrl } from '@plone/volto/helpers';
import { getFormTicket, submitSubsiteContactForm } from 'volto-dlr/actions';
import { useLocation, useHistory } from 'react-router-dom';
import { getContent } from '@plone/volto/actions';
import cx from 'classnames';
import qs from 'query-string';

const messages = defineMessages({
  ContactForm: {
    id: 'Contact - Institute',
    defaultMessage: 'Contact - Institute',
  },
  h1: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  Name: {
    id: 'First Name',
    defaultMessage: 'First Name',
  },
  LastName: {
    id: 'Last Name',
    defaultMessage: 'Last Name',
  },
  Email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  Subject: {
    id: 'Subject (max. 160 characters)',
    defaultMessage: 'Subject (max. 160 characters)',
  },
  Message: {
    id: 'Message (max. 1000 characters)',
    defaultMessage: 'Message (max. 1000 characters)',
  },
  DataProtection: {
    id:
      'We store the information entered by you in order to provide you with service. It is only used for this purpose and is not transferred to third parties. The data is deleted as soon as it is no longer required for the purpose for which it was collected. The legal basis for processing of the data is set out in Art. 6 Paragraph 1 (f) GDPR.',
    defaultMessage:
      'We store the information entered by you in order to provide you with service. It is only used for this purpose and is not transferred to third parties. The data is deleted as soon as it is no longer required for the purpose for which it was collected. The legal basis for processing of the data is set out in Art. 6 Paragraph 1 (f) GDPR.',
  },
  FirstCheckbox: {
    id: 'I have carefully read, understood and accepted the privacy policy.',
    defaultMessage:
      'I have carefully read, understood and accepted the privacy policy.',
  },
  Submit: {
    id: 'Submit',
    defaultMessage: 'Submit',
  },
  messageSent: {
    id: 'Email sent',
    defaultMessage: 'Email sent',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  selectLabel: {
    id: 'Select category',
    defaultMessage: 'Select category',
  },
  confirmationH1: {
    id: 'Message sent',
    defaultMessage: 'Message sent',
  },
  confirmation: {
    id:
      'Thank you for your message, here with we confirm the receipt. We attach importance to your concern that will be processed soon. We request your patience, you will receive a response as soon as possible.',
    defaultMessage:
      'Thank you for your message, here with we confirm the receipt. We attach importance to your concern that will be processed soon. We request your patience, you will receive a response as soon as possible.',
  },
  upperParagraph: {
    id:
      "Thank you for your interest in DLR's website. We appreciate your questions and comments. Please be aware of the fact that replying to your mail by appropriate DLR staff may take some time because of the huge amount of E-Mail we get every day.",
    defaultMessage:
      "Thank you for your interest in DLR's website. We appreciate your questions and comments. Please be aware of the fact that replying to your mail by appropriate DLR staff may take some time because of the huge amount of E-Mail we get every day.",
  },
  mandatory: {
    id: 'All fields marked with * are mandatory.',
    defaultMessage: 'All fields marked with * are mandatory.',
  },
  category: {
    id: 'Category',
    defaultMessage: 'Category',
  },
  privacy: {
    id: 'privacy policy',
    defaultMessage: 'privacy policy',
  },
  categoryPlaceholder: {
    id: 'Please select a category for your inquiry',
    defaultMessage: 'Please select a category for your inquiry',
  },
});

const SubsiteContactForm = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const search = qs.parse(location.search);

  const iNavRootInfo = useSelector((state) => state.nearestInavRoot);
  const isSchoollab = iNavRootInfo?.subsite?.theme === 'school_lab';

  let history = useHistory();

  useEffect(() => {
    dispatch(getFormTicket(getParentUrl(pathname)));
    dispatch(getContent(getParentUrl(pathname)));
  }, [dispatch, pathname]);

  const content = useSelector((state) => state.content.data);
  const categories = content?.contact_form_categories?.items;

  const { loading, loaded, serverError, ticket } = useSelector((state) => ({
    ticket: state.contactform.ticket,
    loading: state.contactform.loading,
    loaded: state.contactform.loaded,
    serverError: state.contactform.error,
  }));

  // scroll to top after form submission
  useEffect(() => {
    if (loaded) {
      window.scrollTo(0, 0);
    }
  }, [loaded]);

  const [error, setError] = React.useState(true);
  const [state, setState] = React.useState({
    Category: '',
    Name: '',
    LastName: '',
    Email: '',
    Message: '',
    Subject: '',
    Checkbox: false,
  });

  const onChangeHandler = (event) => {
    if (event.target.name === 'Checkbox') {
      setState({ ...state, [event.target.name]: !state[event.target.name] });
      setError(false);
      return;
    }

    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const checkRequiredField = () => {
    if (state.Checkbox === false) {
      setError((error) => {
        return true;
      });
      return true;
    }

    return false;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (loading) return;
    const error = checkRequiredField();
    if (error) {
      return;
    }

    dispatch(
      submitSubsiteContactForm(content['@id'], {
        category: state.Category,
        ticket: ticket,
        subject: state.Subject,
        message: state.Message,
        name: state.Name,
        lastname: state.LastName,
        email: state.Email,
        origin: content['@id'],
      }),
    ).then((resp) => {
      // Reset the form after submission
      setState({
        Category: '',
        Name: '',
        LastName: '',
        Email: '',
        Message: '',
        Subject: '',
        Checkbox: false,
      });

      history.push({
        pathname,
        search: '?confirmation=success',
      });
    });
  };

  return (
    <div id="contact-form" className={cx({ 'schoollab-form': isSchoollab })}>
      <Container id="view">
        <Helmet title={intl.formatMessage(messages.ContactForm)} />
        <Dimmer active={loading} page></Dimmer>
        {search.confirmation === 'success' ? (
          <>
            <h1>{intl.formatMessage(messages.confirmationH1)}</h1>
            <p className="confirmation-message">
              {intl.formatMessage(messages.confirmation)}
            </p>
          </>
        ) : (
          <>
            {' '}
            <div>
              <h1>{intl.formatMessage(messages.h1)}</h1>
              <p className="upper-paragraph">
                {intl.formatMessage(messages.upperParagraph)}
              </p>
              <p className="upper-paragraph">
                {intl.formatMessage(messages.mandatory)}
              </p>
            </div>
            <div>
              <div className="form-container">
                <form onSubmit={onSubmitHandler}>
                  <div className="filter-group-select">
                    <label htmlFor="category">
                      {intl.formatMessage(messages.category)}*:
                    </label>
                    <select
                      required
                      id="category"
                      name="Category"
                      aria-label={intl.formatMessage(messages.selectLabel)}
                      onBlur={onChangeHandler}
                      onChange={onChangeHandler}
                      value={state.Category || null}
                      placeholder={intl.formatMessage(
                        messages.categoryPlaceholder,
                      )}
                    >
                      <option value="" disabled selected hidden>
                        {intl.formatMessage(messages.categoryPlaceholder)}
                      </option>
                      {categories?.map((category) => (
                        <option key={category['@id']} value={category.title}>
                          {category.title}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="subject">
                      {intl.formatMessage(messages.Subject)}{' '}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="subject"
                      name="Subject"
                      onChange={onChangeHandler}
                      value={state.Subject}
                      title={intl.formatMessage(messages.Subject)}
                      type="text"
                      size="160"
                      maxLength="160"
                    />
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="message">
                      {intl.formatMessage(messages.Message)}{' '}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <textarea
                      required
                      id="message"
                      name="Message"
                      value={state.Message}
                      onChange={onChangeHandler}
                      title={intl.formatMessage(messages.Message)}
                      cols="59"
                      rows="4"
                    ></textarea>
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {intl.formatMessage(messages.Name)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="name"
                      name="Name"
                      onChange={onChangeHandler}
                      value={state.Name}
                      title="Name"
                      type="text"
                      size="50"
                      maxLength="50"
                    ></input>
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="lastname">
                      {intl.formatMessage(messages.LastName)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="lastname"
                      name="LastName"
                      onChange={onChangeHandler}
                      value={state.LastName}
                      title="lastname"
                      type="text"
                      size="50"
                      maxLength="50"
                    ></input>
                  </div>

                  <div className="custom-input-group">
                    <label htmlFor="email">
                      {intl.formatMessage(messages.Email)}
                      <dfn title="Mandatory">*</dfn>
                    </label>
                    <input
                      required
                      id="email"
                      name="Email"
                      value={state.Email}
                      onChange={onChangeHandler}
                      title="E-Mail"
                      type="email"
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      size="60"
                      maxLength="60"
                    />
                  </div>

                  <div className="wrapper ie">
                    <p>
                      {intl.formatMessage(messages.DataProtection)}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          currentLang === 'de'
                            ? '/de/service/datenschutz'
                            : '/en/service/privacypolicy'
                        }
                      >
                        {intl.formatMessage(messages.privacy)}
                      </a>
                    </p>
                    <br />
                    <div className="checkbox-wrapper">
                      <input
                        id="checkbox"
                        name="Checkbox"
                        type="checkbox"
                        onChange={onChangeHandler}
                        required
                      />
                      <label
                        className={error.FirstCheckbox ? 'error' : ''}
                        htmlFor="checkbox"
                      >
                        {intl.formatMessage(messages.FirstCheckbox)}*
                      </label>
                    </div>
                    <br />
                  </div>
                  {serverError && (
                    <Message
                      icon="warning"
                      negative
                      header={intl.formatMessage(messages.error)}
                      content={serverError.response.body.message}
                    />
                  )}
                  <div className="button-container">
                    <Button
                      name="submit"
                      type="submit"
                      title={intl.formatMessage(messages.Submit)}
                      loading={loading}
                    >
                      {intl.formatMessage(messages.Submit)}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default SubsiteContactForm;

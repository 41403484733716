import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Message } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { getFormTicket } from 'volto-dlr/actions';
import {
  submitContactForm,
  resetContactForm,
} from '../../../actions/contactform/contactform';
import ContactDetail from './Detail';
import warningSVG from '@plone/volto/icons/warning.svg';

const messages = defineMessages({
  Salutation: {
    id: 'Salutation',
    defaultMessage: 'Salutation',
  },
  Divers: {
    id: 'Divers',
    defaultMessage: 'Divers',
  },
  Mrs: {
    id: 'Mrs',
    defaultMessage: 'Mrs',
  },
  Mr: {
    id: 'Mr',
    defaultMessage: 'Mr',
  },
  YourName: {
    id: 'YourName',
    defaultMessage: 'Your Name *',
  },
  YourEmail: {
    id: 'YourEmail',
    defaultMessage: 'Your E-mail *',
  },
  Subject: {
    id: 'ContactFormSubject',
    defaultMessage: 'Subject of Your Message (max. 160 characters) *',
  },
  Message: {
    id: 'ContactFormMessage',
    defaultMessage: 'Your Message to Us (max. 1000 characters) *',
  },
  DataProtection: {
    id:
      'We store the data entered by you in order to provide you with services. It will only be used for this purpose and will not be transferred to third parties. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
    defaultMessage:
      'We store the data entered by you in order to provide you with services. It will only be used for this purpose and will not be transferred to third parties. The data will be deleted as soon as it is no longer required for the purpose for which it was collected. Insofar as the data subject has given consent for the processing of personal data, Art. 6 Abs. 1 lit. a GDPR serves as the legal basis. The legal basis for processing the data that is transmitted in the course of sending an email is Art. 6 Para. 1 lit. f GDPR. If the aim of the email contact is to conclude a contract, the additional legal basis for processing is Art. 6 Para. 1 lit. b GDPR.',
  },
  PrivacyConsent: {
    id:
      'I have read the data protection regulations carefully, I have understood the procedure described and hereby consent to the processing of my data for the purpose of contacting me.',
    defaultMessage:
      'I have read the data protection regulations carefully, I have understood the procedure described and hereby consent to the processing of my data for the purpose of contacting me.',
  },
  AgeConsent: {
    id:
      'Our offer is generally aimed at persons aged 16 years or older. Individuals under the age of 16 are not permitted to submit personal data to us without the consent of their parents or legal guardian.',
    defaultMessage:
      'Our offer is generally aimed at persons aged 16 years or older. Individuals under the age of 16 are not permitted to submit personal data to us without the consent of their parents or legal guardian.',
  },
  SendMessage: {
    id: 'Send Message',
    defaultMessage: 'Send Message',
  },
  Cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  CloseWindow: {
    id: 'CloseWindow',
    defaultMessage: 'Close Window',
  },
  Error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  Timeout: {
    id: 'Timed out',
    defaultMessage: 'Timed out',
  },
});

const ContactForm = (props) => {
  const intl = useIntl();
  const { contact, context } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    // Get form ticket after component is mounted
    dispatch(getFormTicket(contact['@id']));
    // Reset state when component is unmounted
    return () => dispatch(resetContactForm());
  }, [contact, dispatch]);

  const { ticket, loading, loaded, serverError, lang } = useSelector(
    (state) => ({
      ticket: state.contactform.ticket,
      loading: state.contactform.loading,
      loaded: state.contactform.loaded,
      serverError: state.contactform.error,
      lang: state.intl.locale,
    }),
  );

  const [error, setError] = React.useState({});
  const [state, setState] = React.useState({
    validated: false,
    name: '',
    email: '',
    subject: '',
    message: '',
    privacy_consent: false,
    age_consent: false,
  });
  const onChangeHandler = (event, data) => {
    if (data === undefined) {
      data = event.currentTarget;
    }
    if (data.name === 'privacy_consent' || data.name === 'age_consent') {
      setState({ ...state, [data.name]: !state[data.name] });
      setError({ ...error, [data.name]: false });
      return;
    }
    setState({
      ...state,
      [data.name]: data.value,
    });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (loading) return;
    const form = event.currentTarget;
    let hasError = false;
    if (!form.checkValidity()) {
      form.reportValidity();
      setState({ ...state, validated: true });
      hasError = true;
    }
    if (!state.privacy_consent) {
      setError((error) => {
        return { ...error, privacy_consent: true };
      });
      hasError = true;
    }
    if (!state.age_consent) {
      setError((error) => {
        return { ...error, age_consent: true };
      });
      hasError = true;
    }
    if (hasError) return true;

    dispatch(
      submitContactForm(contact['@id'], {
        ticket: ticket,
        subject: state.subject,
        message: state.message,
        salutation: state.salutation,
        name: state.name,
        email: state.email,
        origin: context['@id'],
      }),
    );
  };

  let salutationOptions = [
    {
      value: '',
      text: !state.salutation ? intl.formatMessage(messages.Salutation) : '',
    },
    { value: 'Frau', text: intl.formatMessage(messages.Mrs) },
    { value: 'Herr', text: intl.formatMessage(messages.Mr) },
  ];
  if (lang === 'de') {
    salutationOptions = [
      ...salutationOptions.slice(0, 1),
      {
        value: 'Divers',
        text: intl.formatMessage(messages.Divers),
      },
      ...salutationOptions.slice(1),
    ];
  }

  const onSuccessKeyDown = (event) => {
    if (event.key === 'Escape') {
      props.onCancel();
    }
  };

  const handleKeyDown = (event) => {
    if (
      state.name ||
      state.email ||
      state.salutation ||
      state.message ||
      state.subject
    ) {
      return;
    }
    if (event.key === 'Escape') {
      props.onCancel();
    }
  };

  const focusElement = useCallback((Element) => {
    if (Element) {
      Element.focus();
    }
  }, []);

  return loaded ? (
    <div
      className="contact-form-overlay"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onKeyDown={onSuccessKeyDown}
      ref={focusElement}
      role="presentation"
    >
      <fieldset>
        <legend className="sent">
          <FormattedMessage
            id="MessageSent"
            defaultMessage='Your message has been successfully delivered to "{name}"'
            values={{
              name: `${contact.first_name ?? ''} ${contact.last_name ?? ''}`,
            }}
          />
        </legend>
        <Button onClick={() => props.onCancel()}>
          {intl.formatMessage(messages.CloseWindow)}
        </Button>
      </fieldset>
    </div>
  ) : (
    <Form
      noValidate
      className={'contact-form-overlay' + (state.validated ? ' validated' : '')}
      onSubmit={onSubmitHandler}
      onKeyDown={handleKeyDown}
    >
      <h2 id="contact-form-modal-heading">
        <FormattedMessage
          id="ContactFormHeader"
          defaultMessage="Contact: Write a Message"
        />
      </h2>
      <fieldset>
        <legend className="message">
          <FormattedMessage
            id="ContactFormTitle"
            defaultMessage={'Your Message about "{title}"'}
            values={{ title: context.title }}
          />
        </legend>
        <Form.Input
          required
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus="autoFocus"
          name="subject"
          onChange={onChangeHandler}
          value={state.subject}
          placeholder={intl.formatMessage(messages.Subject)}
          type="text"
          maxLength="160"
        />
        <Form.TextArea
          required
          id="message"
          name="message"
          value={state.message}
          onChange={onChangeHandler}
          placeholder={intl.formatMessage(messages.Message)}
          maxLength="1000"
        />
      </fieldset>
      <fieldset>
        <legend>
          <FormattedMessage
            id="Your Contact Info"
            defaultMessage="Your Contact Info"
          />
        </legend>
        <Form.Group>
          <Form.Field width={3}>
            <select
              id="salutation"
              name="salutation"
              aria-label={intl.formatMessage(messages.Salutation)}
              onBlur={onChangeHandler}
              onChange={onChangeHandler}
              value={state.salutation}
            >
              {salutationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </Form.Field>
          <Form.Input
            required
            id="name"
            name="name"
            onChange={onChangeHandler}
            value={state.name}
            placeholder={intl.formatMessage(messages.YourName)}
            type="text"
            maxLength="100"
            width={9}
          />
        </Form.Group>
        <Form.Input
          required
          id="email"
          name="email"
          value={state.email}
          onChange={onChangeHandler}
          placeholder={intl.formatMessage(messages.YourEmail)}
          type="email"
          maxLength="100"
          pattern=".+@.+\..+"
        />
        <p className="note">
          <FormattedMessage
            id="Mandatory"
            defaultMessage="Note: Required information is marked with a *"
          />
        </p>
      </fieldset>
      <hr />
      <fieldset>
        <legend>
          <FormattedMessage
            id="Addressee"
            defaultMessage="Your Message is Addressed to"
          />
        </legend>
        <div className="contact-detail">
          <ContactDetail contact={contact} />
        </div>
      </fieldset>
      <hr />
      <fieldset>
        <legend className="privacy">
          <FormattedMessage
            id="Our Privacy Policy"
            defaultMessage="Our Privacy Policy"
          />
        </legend>
        <p>
          {intl.formatMessage(messages.DataProtection)}{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href={
              lang === 'de'
                ? '/de/service/datenschutz'
                : '/en/service/privacypolicy'
            }
          >
            <FormattedMessage
              id="PrivacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </a>
        </p>
        <Form.Checkbox
          name="privacy_consent"
          onChange={onChangeHandler}
          aria-labelledby="privacy-consent-label"
          label={
            <label id="privacy-consent-label">
              {intl.formatMessage(messages.PrivacyConsent) + ' *'}
            </label>
          }
          error={error.privacy_consent}
        />
        <Form.Checkbox
          name="age_consent"
          onChange={onChangeHandler}
          aria-labelledby="age-consent-label"
          label={
            <label id="age-consent-label">
              {intl.formatMessage(messages.AgeConsent) + ' *'}
            </label>
          }
          error={error.age_consent}
        />
      </fieldset>
      <hr />
      <fieldset>
        {serverError && (
          <Message negative>
            <div className="icon-container-warning">
              {' '}
              <Icon name={warningSVG} size="50px" color="red" />
            </div>
            <Message.Header>
              {intl.formatMessage(messages.Error)}
            </Message.Header>
            <Message.Content>
              {serverError.response?.body?.message ??
                intl.formatMessage(messages.Timeout)}
            </Message.Content>
          </Message>
        )}
        <Button primary name="submit" loading={loading}>
          {intl.formatMessage(messages.SendMessage)}
        </Button>
        <Button name="cancel" onClick={() => props.onCancel()}>
          {intl.formatMessage(messages.Cancel)}
        </Button>
      </fieldset>
    </Form>
  );
};

export default ContactForm;

/**
 * OVERRIDE View.jsx
 * REASON: Add separators above and below the blockquote
 * FILE: https://github.com/kitconcept/volto-quote-block/blob/1.0.0-alpha.6/src/components/View.jsx
 * FILE VERSION: volto-quote-block 1.0.0-alpha.6
 * DATE: 2024-02-05
 * DEVELOPER: @jonaspiterek
 */

import React from 'react';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';
import { DetachedTextBlockEditor } from '@plone/volto-slate/blocks/Text/DetachedTextBlockEditor';
import { TextBlockView } from '@plone/volto-slate/blocks/Text';

const View = (props) => {
  const { className, data, isEditMode, intl } = props;

  const customSlateSettings = {
    ...props,
    slateSettings: {
      ...config.settings.slate,
      toolbarButtons: config.settings.slate.toolbarButtons.filter(
        (index) => index - config.settings.slate.toolbarButtons,
      ),
    },
  };

  return (
    <div className={cx('block quote', className)}>
      <div className="inner-wrapper">
        {/* BEGIN CUSTOMIZATION */}
        {!data.image || data.image.length <= 0 ? (
          <div className="separator-wrapper">
            <div className="line"></div>
          </div>
        ) : (
          config.blocks?.blocksConfig?.quote?.showImageField && (
            <div className="top-wrapper">
              <div className="line"></div>
              <div className="image-wrapper">
                <img
                  src={`${flattenToAppURL(data.image?.[0]?.['@id'])}/${
                    data.image?.[0]?.image_scales?.image[0]?.scales?.preview
                      ?.download
                  }`}
                  alt=""
                  className="image"
                  loading="lazy"
                />
              </div>
            </div>
          )
        )}
        {/* END CUSTOMIZATION */}
        <blockquote
          className={data.plaintext?.length < 1 && 'isEmpty'}
          cite={data.cite}
          lang={
            data.quotationLanguage !== intl.locale && data.quotationLanguage
          }
        >
          {!isEditMode ? (
            <TextBlockView {...props} />
          ) : (
            <DetachedTextBlockEditor {...customSlateSettings} />
          )}
          <footer>
            {data.author && data.additional_information
              ? data.author + ', '
              : data.author}
            {data.additional_information && data.cite ? (
              <cite>{data.additional_information}</cite>
            ) : (
              data.additional_information && (
                <span>{data.additional_information}</span>
              )
            )}
          </footer>
        </blockquote>
        {/* BEGIN CUSTOMIZATION */}
        <div className="separator-wrapper">
          <div className="line"></div>
        </div>
        {/* END CUSTOMIZATION */}
      </div>
    </div>
  );
};

export default View;

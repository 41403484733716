/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { find, join, map, split } from 'lodash';

import { Helmet, langmap, flattenToAppURL } from '@plone/volto/helpers';
import { changeLanguage } from '@plone/volto/actions';
import { normalizeLanguageName } from '@plone/volto/helpers';
import { isCmsUi } from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { useIntl } from 'react-intl';
import { getQuerystring } from '@plone/volto/actions';

const LanguageSelector = ({ onClickAction, fullLabel = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.intl.locale);
  const querystring = useSelector((state) => state.querystring);
  const error = useSelector((state) => state.content.get.error);
  const { items: translations, root: root_translations } = useSelector(
    (state) =>
      state.content.data?.['@components']?.translations ||
      state.content.subrequests?.navRoot?.data?.['@components']?.translations ||
      state.solrsearch?.translations ||
      {},
  );
  const searchQuery = useSelector((state) => state.solrsearch?.query);
  const pathname = useLocation().pathname;
  const isCmsPath = isCmsUi(pathname);

  const { settings } = config;

  // Updating querystring indexer when changing the language. #1360
  React.useEffect(() => {
    if (querystring?.loaded) {
      dispatch(getQuerystring());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang, dispatch]);

  const getTarget = (location, translation, lang, root_translations) => {
    if (lang === currentLang) return false;

    const switchLanguage = () => {
      const langFileName = normalizeLanguageName(lang);
      import('@root/../locales/' + langFileName + '.json').then((locale) => {
        dispatch(changeLanguage(lang, locale.default));
      });
    };
    const translationNotFound = {
      location: {
        pathname: `${
          flattenToAppURL(root_translations?.[lang]) || lang
        }/translation-not-found`,
        state: {
          language: lang,
        },
      },
    };

    if (matchPath(pathname, { path: '/de/kontakt-formular' })) {
      return {
        location: '/en/contact-form',
        action: switchLanguage,
      };
    }

    if (matchPath(pathname, { path: '/en/contact-form' })) {
      return {
        location: '/de/kontakt-formular',
        action: switchLanguage,
      };
    }

    if (matchPath(pathname, { path: '/en/schoollab/booking-form' })) {
      return {
        location: '/de/schoollab/booking-form',
        action: switchLanguage,
      };
    }

    if (matchPath(pathname, { path: '/de/schoollab/booking-form' })) {
      return {
        location: '/en/schoollab/booking-form',
        action: switchLanguage,
      };
    }

    if (matchPath(pathname, { path: '/de/**/kontakt-formular' })) {
      if (root_translations) {
        return {
          location: `${flattenToAppURL(root_translations[lang])}/contact-form`,
          action: switchLanguage,
        };
      } else {
        return translationNotFound;
      }
    }

    if (matchPath(pathname, { path: '/en/**/contact-form' })) {
      return {
        location: `${flattenToAppURL(
          root_translations[lang],
        )}/kontakt-formular`,
        action: switchLanguage,
      };
    }

    if (matchPath(pathname, { path: '/de/magazin-abo' })) {
      return {
        location: '/en/magazine-subscription',
        action: switchLanguage,
      };
    }

    if (matchPath(pathname, { path: '/en/magazine-subscription' })) {
      return {
        location: '/de/magazin-abo',
        action: switchLanguage,
      };
    }
    if (pathname.endsWith('/contents')) {
      if (translation) {
        return { location: flattenToAppURL(translation['@id'] + '/contents') };
      } else {
        return translationNotFound;
      }
    }

    if (pathname.endsWith('/links-to-item')) {
      if (translation) {
        return {
          location: flattenToAppURL(translation['@id'] + '/links-to-item'),
          action: switchLanguage,
        };
      } else {
        return translationNotFound;
      }
    }
    if (matchPath(pathname, { path: '/en/**/aliases' })) {
      if (translation) {
        return {
          location: flattenToAppURL(translation['@id'] + '/aliases'),
          action: switchLanguage,
        };
      } else {
        return translationNotFound;
      }
    }
    if (matchPath(pathname, { path: '/de/**/aliases' })) {
      if (translation) {
        return {
          location: flattenToAppURL(translation['@id'] + '/aliases'),
          action: switchLanguage,
        };
      } else {
        return translationNotFound;
      }
    }
    if (matchPath(pathname, { path: '/**/newsletter-delivery' })) {
      if (translation) {
        return {
          location: flattenToAppURL(
            translation['@id'] + '/newsletter-delivery',
          ),
          action: switchLanguage,
        };
      } else {
        return translationNotFound;
      }
    }
    if (pathname.endsWith('/@@search')) {
      // Language buttons in case of search:
      // - translation exists: search action on translated page + language switch (needed)
      // - translation missing: search action on root page + language switch (needed)
      //
      // Note that if the translation is missing then the localized search will use a
      // different base folder - which is inevitable.
      //
      const newLocation = translation
        ? flattenToAppURL(translation['@id']) + '/@@search?' + searchQuery
        : flattenToAppURL(root_translations?.[lang]) +
          '/@@search?' +
          searchQuery;
      return {
        location: newLocation,
        action: switchLanguage,
      };
    } else if (isCmsPath) {
      // Language buttons on cms pathes (non content objects):
      // just switch the language as needed.
      // Example: `/passwordreset`
      return {
        // If the language is in the path (some non content routes have it)
        // then we MUST replace it too with the current language.
        location: pathname.replace(/^\/((en)|(de))\//, `/${lang}/`),
        // location: pathname,
        action: switchLanguage,
      };
    } else {
      // Language buttons in normal case:
      // - translation exists: translated page
      // - translation missing: TranslationNotFound
      if (translation) {
        return { location: flattenToAppURL(translation['@id']) };
      } else if (error && error.status === 404) {
        let newPath = split(pathname, '/');
        if (newPath.length > 2) {
          newPath[1] = lang;
        }
        newPath = join(newPath, '/');
        return {
          location: {
            pathname: newPath,
            state: {
              language: lang,
            },
          },
        };
      } else {
        return translationNotFound;
      }
    }
  };

  return settings.isMultilingual ? (
    <div className="language-selector">
      {map(settings.supportedLanguages, (lang) => {
        const translation = find(translations, { language: lang });
        const target = getTarget(
          pathname,
          translation,
          lang,
          root_translations,
        );
        return target ? (
          <Link
            aria-label={
              intl.locale === 'en'
                ? 'Webseiten-Sprache ändern von Englisch auf Deutsch'
                : 'Change website language from German to English'
            }
            className={cx({ selected: lang === currentLang })}
            to={target.location}
            title={langmap[lang].nativeName}
            onClick={(e) => {
              // Support for Optional Chaining was added to ESLint in v7.5.0.
              // eslint-disable-next-line no-unused-expressions
              target.action?.();
              // eslint-disable-next-line no-unused-expressions
              onClickAction?.(e);
            }}
            key={`language-selector-${lang}`}
          >
            {fullLabel ? langmap[lang].nativeName : lang}
          </Link>
        ) : (
          <span
            className={cx({ selected: lang === currentLang })}
            key={`language-selector-${lang}`}
          >
            {fullLabel ? langmap[lang].nativeName : lang}
          </span>
        );
      })}
    </div>
  ) : (
    <Helmet>
      <html lang={settings.defaultLanguage} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;

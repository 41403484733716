import React from 'react';
import ImageInput from '../Image/ImageInput';
import { Img } from '@kitconcept/volto-blocks/components';
import { softHyphenSupport } from 'volto-dlr/helpers';

const View = (props) => {
  const { data, isEditMode } = props;

  // add original image to scales
  const imageInfo = data.image_scales?.image?.[0];
  const scales = imageInfo?.scales || {};
  if (imageInfo && scales) {
    const { download, width, height } = imageInfo;
    scales.original = { download, width, height };
  }

  return (
    <div className="block banner">
      {data.url ? (
        <div className="image-container">
          <Img
            src={data.url}
            scales={scales}
            blurhash={imageInfo?.blurhash}
            blurhashOptions={{ style: { width: '100%', height: '300px' } }}
            alt={data.alt}
            className="image"
          />
          <div className="text">
            <div>
              <p>{softHyphenSupport(data?.text)}</p>
              <p>{softHyphenSupport(data?.additionalText)}</p>
            </div>
          </div>
        </div>
      ) : (
        isEditMode && <ImageInput {...props} />
      )}
    </div>
  );
};

export default View;

import React from 'react';
import { expandToBackendURL, flattenToAppURL } from '@plone/volto/helpers';

import { Grid, Button } from 'semantic-ui-react';

import { FormattedDate } from '@plone/volto/components';
import { UniversalLink } from '@plone/volto/components';
import { PreviewImg } from 'volto-dlr/helpers';
import Caption from 'volto-dlr/components/Image/Caption';
import { FormattedMessage, injectIntl } from 'react-intl';

const EventMetadataView = (props) => {
  const content = props.properties;
  let dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  if (content.whole_day) {
    dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'Europe/Berlin',
    };
  }

  return (
    <div className="metadata-container block-view">
      {content.description && <p>{content.description}</p>}
      {content.preview_image_link && (
        <figure>
          <PreviewImg
            previewImage={content.preview_image_link}
            imageField="image"
            targetWidth="default"
          />
          <Caption
            title={content.preview_image_link?.title}
            description={content.preview_image_link?.description}
            credit={content.preview_image_link?.credit}
            downloadFilename={content.preview_image_link?.title}
            downloadHref={
              content.preview_image_link?.allow_image_download &&
              flattenToAppURL(
                `${content.preview_image_link['@id']}/@@images/image/preview`,
              )
            }
          />
        </figure>
      )}

      <Grid stackable className="details-container">
        <Grid.Row columns={2}>
          <Grid.Column>
            <div>
              <div className="event-title">
                <span className="event-heading">
                  <FormattedMessage id="Start" defaultMessage="Start" />
                </span>
                <div className="event-detail">
                  {' '}
                  <FormattedDate
                    date={content?.start}
                    format={dateOptions}
                  />{' '}
                  {!content.whole_day && props.intl.locale === 'de'
                    ? ' Uhr'
                    : ''}
                </div>
                <div className="separator"></div>
              </div>

              {content.end &&
                content.end !== content.start &&
                !content.open_end && (
                  <div className="event-title">
                    <span className="event-heading">
                      {' '}
                      <FormattedMessage id="End" defaultMessage="End" />
                    </span>
                    <div className="event-detail">
                      {' '}
                      <FormattedDate
                        date={content?.end}
                        format={dateOptions}
                      />{' '}
                      {!content.whole_day && props.intl.locale === 'de'
                        ? ' Uhr'
                        : ''}
                    </div>
                    <div className="separator"></div>
                  </div>
                )}

              {content?.location && (
                <div className="event-title">
                  <span className="event-heading">
                    <FormattedMessage id="Location" defaultMessage="Location" />
                  </span>
                  <div className="event-detail">{content?.location}</div>
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column className="second-column-container"></Grid.Column>{' '}
          {/* We will fill this after MVP */}
          {content['@id'] && (
            <div className="event-button">
              <a
                className="ics-download"
                target="_blank"
                rel="noreferrer"
                href={`${expandToBackendURL(content['@id'])}/ics_view`}
              >
                <Button className="event-btn">
                  <FormattedMessage
                    id="ICS-Download"
                    defaultMessage="ICS Download"
                  />
                </Button>
              </a>

              {content.event_url && (
                <UniversalLink className="event-url" href={content.event_url}>
                  <Button className="event-btn">
                    <FormattedMessage
                      id="Register now"
                      defaultMessage="Register now"
                    />
                  </Button>
                </UniversalLink>
              )}
            </div>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default injectIntl(EventMetadataView);

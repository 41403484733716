import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ProjectFactsheet: {
    id: 'ProjectFactsheet',
    defaultMessage: 'Project Factsheet',
  },
  ProjectMission: {
    id: 'Project / Mission',
    defaultMessage: 'Project / Mission',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
});

const ProjectFactsheetSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.ProjectFactsheet),
    block: 'project',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['headline', 'href'],
      },
    ],
    properties: {
      headline: {
        title: intl.formatMessage(messages.headline),
      },
      href: {
        title: intl.formatMessage(messages.ProjectMission),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: false,
        widgetOptions: {
          pattern_options: {
            maximumSelectionSize: 1,
            selectableTypes: ['Research Project'],
          },
        },
      },
    },
    required: [],
  };
};

export default ProjectFactsheetSchema;

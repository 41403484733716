import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { BlockDataForm } from '@plone/volto/components';
import { getContent } from '@plone/volto/actions';
import { DasDLRSchema } from './schema';

const DasDLRData = (props) => {
  const { block, data, onChangeBlock, contentType, navRoot } = props;
  const intl = useIntl();
  const schema = DasDLRSchema({ ...props, intl });

  const dispatch = useDispatch();
  const teaserTopLeftHref = data.href_top_left?.[0];
  const teaserBottomLeftHref = data.href_bottom_left?.[0];
  const teaserRightHref = data.href_right?.[0];

  useEffect(() => {
    if (teaserTopLeftHref) {
      dispatch(getContent(teaserTopLeftHref['@id'], null, block)).then(
        (resp) => {
          onChangeBlock(block, {
            ...data,
            ...(!data.title_top_left && { title_top_left: resp.title }),
            ...(!data.description_top_left && {
              description_top_left: resp.description,
            }),
          });
        },
      );
    } else {
      onChangeBlock(block, {
        ...data,
        title_top_left: '',
        description_top_left: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaserTopLeftHref]);

  useEffect(() => {
    if (teaserBottomLeftHref) {
      dispatch(getContent(teaserBottomLeftHref['@id'], null, block)).then(
        (resp) => {
          onChangeBlock(block, {
            ...data,
            ...(!data.title_bottom_left && { title_bottom_left: resp.title }),
            ...(!data.description_bottom_left && {
              description_bottom_left: resp.description,
            }),
          });
        },
      );
    } else {
      onChangeBlock(block, {
        ...data,
        title_bottom_left: '',
        description_bottom_left: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaserBottomLeftHref]);

  useEffect(() => {
    if (teaserRightHref) {
      dispatch(getContent(teaserRightHref['@id'], null, block)).then((resp) => {
        onChangeBlock(block, {
          ...data,
          ...(!data.title_right && { title_right: resp.title }),
          ...(!data.description_right && {
            description_right: resp.description,
          }),
        });
      });
    } else {
      onChangeBlock(block, {
        ...data,
        title_right: '',
        description_right: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaserRightHref]);

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
      navRoot={navRoot}
      contentType={contentType}
    />
  );
};

export default DasDLRData;

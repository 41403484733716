import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';
import { isValidUrl } from './schema';

const messages = defineMessages({
  IncorrectUrl: {
    id: 'Only url starts with dlr.de url is allowed',
    defaultMessage: 'Only url starts with dlr.de url is allowed',
  },
});

const IframeView = (props) => {
  const { data, className } = props;
  const intl = useIntl();
  return (
    <div className={cx('block iframe', className)}>
      <div className={cx('iframe-container', `align-${data.alignment}`)}>
        {data.src && isValidUrl(data.src) && (
          <iframe
            src={data.src}
            title={data.title}
            width={data.width}
            height={data.height}
          ></iframe>
        )}
        {data.src && !isValidUrl(data.src) && (
          <p>{intl.formatMessage(messages.IncorrectUrl)}</p>
        )}
      </div>
    </div>
  );
};

export default IframeView;

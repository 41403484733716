/**
 * Edit audio block.
 * @module components/manage/Blocks/Title/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Input, Message } from 'semantic-ui-react';
import cx from 'classnames';
import { isEqual, isEmpty } from 'lodash';

import { Icon, SidebarPortal } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import Audio from 'volto-dlr/components/Blocks/Audio/Body';
import AudioSidebar from 'volto-dlr/components/Blocks/Audio/AudioSidebar';
import { flattenToAppURL, withBlockExtensions } from '@plone/volto/helpers';
import { compose } from 'redux';
import Caption from 'volto-dlr/components/Image/Caption';

const messages = defineMessages({
  AudioBlockInputPlaceholder: {
    id: 'Browse the site',
    defaultMessage: 'Browse the site',
  },
});

/**
 * Edit audio block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onSubmitUrl = this.onSubmitUrl.bind(this);
    this.onKeyDownVariantMenuForm = this.onKeyDownVariantMenuForm.bind(this);
    this.state = {
      url: '',
    };
  }

  /**
   * Change url handler
   * @method onChangeUrl
   * @param {Object} target Target object
   * @returns {undefined}
   */
  onChangeUrl({ target }) {
    this.setState({
      url: target.value,
    });
  }

  /**
   * @param {*} nextProps
   * @returns {boolean}
   * @memberof Edit
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.selected ||
      nextProps.selected ||
      !isEqual(this.props.data, nextProps.data)
    );
  }

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @returns {undefined}
   */
  onSubmitUrl() {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      url: this.state.url,
      target_url: null,
    });
  }

  resetSubmitUrl = () => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      url: '',
      target_url: null,
    });
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  }

  openObjectBrowser() {
    let currentPath =
      this.props.intl.locale === 'de'
        ? '/de/medien/audios'
        : '/en/media/audios';
    const iNavRootInfo = this.props.iNavRootInfo;
    const subsite = iNavRootInfo.subsite;
    const isSubsite = !isEmpty(subsite);
    if (isSubsite) {
      currentPath =
        this.props.intl.locale === 'de'
          ? `${subsite['@id']}/medien/audios`
          : `${subsite['@id']}/media/audios`;
    }
    this.props.openObjectBrowser({
      mode: 'link',
      selectableTypes: ['Audio'],
      currentPath: currentPath,
      onSelectItem: (url, item) => {
        this.props.onChangeBlock(this.props.block, {
          ...this.props.data,
          url: flattenToAppURL(url),
          title: item.Title,
          description: item.Description,
          embed_code: item.getRemoteUrl,
          credit: { data: item.credit },
          image_scales: item.image_scales,
        });
      },
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { data } = this.props;
    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.AudioBlockInputPlaceholder);
    return (
      <div
        className={cx(
          'block audio align',
          {
            selected: this.props.selected,
            center: !Boolean(this.props.data.align),
          },
          this.props.data.align,
        )}
      >
        {data.url ? (
          <figure className="audio-inner">
            <Audio data={data} />
            <Caption
              title={data.title}
              description={data.description}
              credit={data.credit?.data}
              shows_people={data.shows_people}
            />
          </figure>
        ) : (
          <div
            className={cx('audio-inner', {
              'full-width': this.props.data.align === 'full',
            })}
          >
            <Message>
              <center>
                <div className="toolbar-inner">
                  <Button.Group>
                    <Button
                      basic
                      icon
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.openObjectBrowser();
                      }}
                    >
                      <Icon name={navTreeSVG} size="24px" />
                    </Button>
                  </Button.Group>
                  <Input
                    onKeyDown={this.onKeyDownVariantMenuForm}
                    placeholder={placeholder}
                    value={this.state.url}
                    readonly
                    onClick={(e) => {
                      // Prevents propagation to the Dropzone and the opening
                      // of the upload browser dialog
                      e.stopPropagation();
                      this.openObjectBrowser();
                    }}
                  />
                  {this.state.url && (
                    <Button.Group>
                      <Button
                        basic
                        className="cancel"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ url: '' });
                        }}
                      >
                        <Icon name={clearSVG} size="30px" />
                      </Button>
                    </Button.Group>
                  )}
                </div>
              </center>
            </Message>
          </div>
        )}
        <SidebarPortal selected={this.props.selected}>
          <AudioSidebar {...this.props} resetSubmitUrl={this.resetSubmitUrl} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withBlockExtensions,
  connect((state, ownProps) => ({
    iNavRootInfo: state.nearestInavRoot,
  })),
)(Edit);

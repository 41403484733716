/**
 * OVERRIDE Volto Search.jsx
 * REASON: Add Solr term highlighting
 * FILE: https://github.com/plone/volto/blob/master/src/components/theme/Search/Search.jsx
 * FILE VERSION: Volto 14.0.0-alpha.40
 * PULL REQUEST: https://github.com/kitconcept/fzj-internet/pull/789
 * TICKET: https://jugit.fz-juelich.de/fzj-internet/dlr/-/issues/10
 * DATE: 2021-12-10
 * DEVELOPER: @tisto
 * CHANGELOG:
 *  - Add Solr term highlighting (2021-12-10) @tisto
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * OVERRIDE Volto Search.jsx
 * REASON: Add Solr search page
 * FILE: https://github.com/plone/volto/blob/master/src/components/theme/Search/Search.jsx
 * FILE VERSION: Volto 14.0.0-alpha.40
 * PULL REQUEST: https://github.com/kitconcept/fzj-internet/pull/869
 * TICKET: https://jugit.fz-juelich.de/fzj-internet/dlr/-/issues/20
 * DATE: 2021-12-27
 * DEVELOPER: @reebalazs
 * CHANGELOG:
 *  - Add solr route, fix search page issues, add content type customizations #869 @reebalazs
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * Search component.
 * @module components/theme/Search/Search
 */

// START CUSTOMIZATION
import React, { Component, createElement, createRef, forwardRef } from 'react';
// END CUSTOMIZATION
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// START CUSTOMIZATION
// END CUSTOMIZATION
import { asyncConnect, getBaseUrl } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
// START CUSTOMIZATION
import {
  Container,
  Segment,
  Pagination,
  Button,
  Dimmer,
  Loader,
  Checkbox,
} from 'semantic-ui-react';
import { injectIntl, useIntl, defineMessages } from 'react-intl';
// END CUSTOMIZATION
import qs from 'query-string';

import config from '@plone/volto/registry';
import { Helmet } from '@plone/volto/helpers';
import { Toolbar, Icon } from '@plone/volto/components';

// START CUSTOMIZATION
import clearSVG from '@plone/volto/icons/clear.svg';

// These imports and the legacySearchProps are only for the legacy search
import { searchContent, resetContent, getContent } from '@plone/volto/actions';
import { DefaultResultItem } from './resultItems';
import { SelectSorting } from './SelectSorting';
import { SearchResultInfo } from './SearchResultInfo';

import solrConfig from '@package/solr.json';

const messages = defineMessages({
  TypeSearchWords: {
    id: 'Search...',
    defaultMessage: 'Search...',
  },
  clear: {
    id: 'Clear',
    defaultMessage: 'Clear',
  },
  results: {
    // Ergebnisse
    id: 'results',
    defaultMessage: 'results',
  },
  searchLocalizedLabel: {
    // Suchergebnisse eingrenzen:
    id: 'searchLocalizedLabel',
    defaultMessage: 'Narrow down search results:',
  },
  searchGlobalized: {
    // Alle Webauftritte auf DLR.de (keine Eingrenzung)
    id: 'searchGlobalized',
    defaultMessage: 'All websites on DLR.de (no narrowing)',
  },
  searchLocalized: {
    // Nur diese Website durchsuchen
    id: 'searchLocalized',
    defaultMessage: 'Only search this website',
  },
  searchSubsiteLocalized: {
    // Nur das DLR Hauptportal durchsuchen
    id: 'searchSubsiteLocalized',
    defaultMessage: 'Search only DLR main portal',
  },
});

// XXX for some reason formatMessage is missing from this.props.intl.
// Until we figure this out, just acquire it directly from hook.
// This should not be necessary.. @reebalazs
const TranslatedInput = forwardRef(
  ({ forwardRef, placeholder, className, value, onChange }, ref) => {
    const intl = useIntl();
    return (
      <input
        ref={ref}
        placeholder={intl.formatMessage(placeholder)}
        className={className}
        value={value}
        onChange={onChange}
      />
    );
  },
);

const SearchTabs = ({ groupSelect, setGroupSelect, groupCounts }) => {
  const locale = useIntl().locale;
  groupCounts = groupCounts || [];
  return (
    <div className="searchTabs ui top attached tabular menu">
      {solrConfig.searchTabs.map((item, index) => {
        const isActive = index === groupSelect;
        const hasResults = groupCounts[index];
        return (
          <span
            onClick={() => hasResults && setGroupSelect(index)}
            onKeyDown={() => hasResults && setGroupSelect(index)}
            role="button"
            tabIndex={index}
            key={index}
            className={
              'searchTab item' +
              (isActive ? ' active' : ' inactive') +
              (hasResults ? ' results' : ' noresults')
            }
          >
            <span>
              {item.label[locale] || item.label['en']}
              <span
                className={
                  'searchCounter ui circular label ' +
                  (isActive ? ' blue' : 'white')
                }
              >
                {hasResults ? groupCounts[index] : '0'}
              </span>
            </span>
          </span>
        );
      })}
    </div>
  );
};

// Return the path prefix, or undefined, if we are on the root.
// We consider `/` and the language specific root folders `/en`, `/de` as roots
const getPathPrefix = (location) => {
  const pathPrefix = location.pathname.replace(/\/[^/]*$/, '') + '/';
  return pathPrefix.match(/^\/([^/]+\/)?$/) ? undefined : pathPrefix;
};

// XXX for some reason formatMessage is missing from this.props.intl.
// Until we figure this out, just acquire it directly from hook.
// This should not be necessary.. @reebalazs
const LocalCheckbox = ({ onChange, checked, allowSubsite }) => {
  const intl = useIntl();
  const messageLocalized = allowSubsite
    ? messages.searchSubsiteLocalized
    : messages.searchLocalized;
  return (
    <div className="search-localized">
      {intl.formatMessage(messages.searchLocalizedLabel)}
      <Checkbox
        radio
        label={intl.formatMessage(messageLocalized)}
        onChange={(e, data) => onChange(data.checked)}
        checked={checked}
      />
      <Checkbox
        radio
        label={intl.formatMessage(messages.searchGlobalized)}
        onChange={(e, data) => onChange(!data.checked)}
        checked={!checked}
      />
    </div>
  );
};
// END CUSTOMIZATION

/**
 * Search class.
 * @class SearchComponent
 * @extends Component
 */
class Search extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContent: PropTypes.func.isRequired,
    // START CUSTOMIZATION
    searchAction: PropTypes.func,
    copyContentForSolrAction: PropTypes.func,
    getSearchReducer: PropTypes.func,
    contentTypeSearchResultViews: PropTypes.object,
    contentTypeSearchResultDefaultView: PropTypes.func,
    // END CUSTOMIZATION
    searchableText: PropTypes.string,
    subject: PropTypes.string,
    path: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    // START CUSTOMIZATION
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    total: PropTypes.number,
    batching: PropTypes.shape({
      '@id': PropTypes.string,
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }),
    pathname: PropTypes.string.isRequired,
    // END CUSTOMIZATION
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    items: [],
    // START CUSTOMIZATION
    loaded: false,
    loading: false,
    total: 0,
    batching: null,
    // END CUSTOMIZATION
    searchableText: null,
    subject: null,
    path: null,
  };

  constructor(props) {
    super(props);
    // START CUSTOMIZATION
    this.state = {
      currentPage: 1,
      isClient: false,
      active: 'relevance',
      searchword: '',
      groupSelect: 0,
      allowLocal: false,
      allowSubsite: false,
      local: true,
    };
    this.inputRef = createRef();
    // END CUSTOMIZATION
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.doSearch();
    // START CUSTOMIZATION
    const location = this.props.history.location;
    const qoptions = qs.parse(location.search);
    const allowLocal = (qoptions.allow_local || '').toLowerCase() === 'true';
    const allowSubsite = // allow_local and allow_subsite are mutually exclusive
      !allowLocal && (qoptions.allow_subsite || '').toLowerCase() === 'true';
    const local = allowSubsite
      ? (qoptions.filter_subsite || '').toLowerCase() !== 'false'
      : (qoptions.local || '').toLowerCase() !== 'false';
    this.setState({
      isClient: true,
      searchword: this.props.searchableText || '',
      active: qoptions.sort_on || 'relevance',
      groupSelect: parseInt(qoptions.group_select) || 0,
      allowLocal,
      allowSubsite,
      local,
    });
    // put focus to the search input field
    this.inputRef.current.focus();
    // If we are possibly having a local search, we have to fetch the content.
    // This is needed to make sure that the translation tabs work correctly.
    // Explanation:
    // - In the first case we are executing the search in the same context
    //   as the content object from where the search was routed. In this case we
    //   already have the correct translations. - This is always the case if
    //   the localized search is not allowed.
    // - In the second case the localized search is enabled, and we are on
    //   the context of the subtree (for example, institute) in which we search
    //   localized. However we might have been routed here from an object that
    //   is inside the current subtree. In this case the translations don't apply
    //   to the current context. For this reason we have to re-fetch the
    //   content information, including the translations, for the current object.

    const copyAndReset = () => {
      // reset previous content, will clear the content-xxx class
      // from the body tag.
      // However before that, we save the translation data that we need for
      // switching between the languages.
      // Also save the options for allowing the same search query populated
      // when the user switches between the languages.
      this.props.copyContentForSolr(
        this.props.contentData,
        qs.stringify(qoptions),
      );
      this.props.resetContent();
    };

    if (allowLocal || allowSubsite) {
      this.props.resetContent();
      this.props
        .getContent(getBaseUrl(this.props.pathname), this.props.versionId)
        // Make sure copy and reset after the content has fetched
        .then(copyAndReset);
    } else {
      copyAndReset();
    }
    // END CUSTOMIZATION
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search !== nextProps.location.search) {
      this.doSearch();
    }
  };

  /**
   * Search based on the given searchableText, subject and path.
   * @method doSearch
   * @param {string} searchableText The searchable text string
   * @param {string} subject The subject (tag)
   * @param {string} path The path to restrict the search to
   * @returns {undefined}
   */

  getSearchOptions = () => {
    const location = this.props.history.location;
    const qoptions = qs.parse(this.props.history.location.search);
    return {
      ...qoptions,
      path_prefix: getPathPrefix(location),
      lang: this.props.intl.locale,
      // newest_boost is now always ON
      newest_boost: `true`,
    };
  };

  doSearch = () => {
    const options = this.getSearchOptions();
    this.setState({ currentPage: 1, active: options.sort_on || 'relevance' });
    this.props.searchContent('', options);
  };

  handleQueryPaginationChange = (e, { activePage }) => {
    const { settings } = config;
    window.scrollTo(0, 0);
    this.setState({ currentPage: activePage }, () => {
      this.props.searchContent('', {
        ...this.getSearchOptions(),
        b_start: (this.state.currentPage - 1) * settings.defaultPageSize,
      });
    });
  };

  // START CUSTOMIZATION
  onResetSearch = () => {
    const qoptions = qs.parse(this.props.history.location.search);
    const options = {
      ...qoptions,
      SearchableText: '',
    };
    delete options.sort_on;
    delete options.sort_order;
    let searchParams = qs.stringify(options);
    this.setState(
      { currentPage: 1, active: 'relevance', searchword: '' },
      () => {
        // eslint-disable-next-line no-restricted-globals
        this.props.history.replace({
          search: searchParams,
        });
        this.props.copyContentForSolr(null, searchParams);
      },
    );
  };
  // END CUSTOMIZATION

  onSortChange = (selectedOption, sort_order) => {
    // START CUSTOMIZATION
    const qoptions = qs.parse(this.props.history.location.search);
    const options = {
      ...qoptions,
      lang: this.props.intl.locale,
    };
    // END CUSTOMIZATION
    options.sort_on = selectedOption;
    options.sort_order = sort_order || 'ascending';
    if (selectedOption === 'relevance') {
      delete options.sort_on;
      delete options.sort_order;
    }
    let searchParams = qs.stringify(options);
    this.setState({ currentPage: 1, active: selectedOption }, () => {
      // eslint-disable-next-line no-restricted-globals
      this.props.history.replace({
        search: searchParams,
      });
      this.props.copyContentForSolr(null, searchParams);
    });
  };

  // START CUSTOMIZATION
  setGroupSelect = (groupSelect) => {
    const qoptions = qs.parse(this.props.history.location.search);
    const options = {
      ...qoptions,
      group_select: groupSelect,
    };
    let searchParams = qs.stringify(options);
    this.setState({ currentPage: 1, groupSelect }, () => {
      // eslint-disable-next-line no-restricted-globals
      this.props.history.replace({
        search: searchParams,
      });
      this.props.copyContentForSolr(null, searchParams);
    });
  };

  setLocal = (local) => {
    const qoptions = qs.parse(this.props.history.location.search);
    const options = {
      ...qoptions,
      local: local,
    };
    if (this.state.allowSubsite) {
      // In case of subsite search we set the subsite filter
      options.filter_subsite = local;
      delete options.local;
    } else {
      options.local = local;
      delete options.filter_subsite;
    }
    let searchParams = qs.stringify(options);
    this.setState(
      {
        currentPage: 1,
        local,
      },
      () => {
        // eslint-disable-next-line no-restricted-globals
        this.props.history.replace({
          search: searchParams,
        });
        this.props.copyContentForSolr(null, searchParams);
      },
    );
  };
  // END CUSTOMIZATION

  // START CUSTOMIZATION
  onSubmit = (event) => {
    const searchParams = qs.stringify({
      SearchableText: this.state.searchword,
      group_select: this.state.groupSelect,
      allow_local: this.state.allowLocal || undefined,
      allow_subsite: this.state.allowSubsite || undefined,
      local: this.state.allowLocal ? this.state.local : undefined,
      filter_subsite: this.state.allowSubsite ? this.state.local : undefined,
    });
    this.props.history.push({
      pathname: this.props.pathname,
      search: searchParams,
    });
    this.props.copyContentForSolr(null, searchParams);
    event.preventDefault();
  };
  // END CUSTOMIZATION

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { settings } = config;
    // START CUSTOMIZATION
    // The next line is only needed for the legacy search component.
    const resultTypeMapper = (contentType) =>
      this.props.contentTypeSearchResultViews[contentType] ||
      this.props.contentTypeSearchResultDefaultView;
    const showPagination = this.props.total > settings.defaultPageSize;
    // END CUSTOMIZATION
    return (
      <Segment basic id="page-search" className="header-wrapper">
        <Helmet title="Search" />
        <Container>
          {/* START CUSTOMIZATION */}
          <div className="search-input">
            <form onSubmit={this.onSubmit}>
              <div className="search-input-wrapper">
                <TranslatedInput
                  ref={this.inputRef}
                  placeholder={messages.TypeSearchWords}
                  className="searchinput"
                  value={this.state.searchword}
                  onChange={(e) =>
                    this.setState({ searchword: e.target.value })
                  }
                />
                {this.state.searchword ? (
                  <Icon
                    className="clear-icon"
                    name={clearSVG}
                    size="24px"
                    onClick={(e) => {
                      this.onResetSearch();
                    }}
                  />
                ) : null}
              </div>
              <Button primary onClick={this.onSubmit}>
                <FormattedMessage id="Search" defaultMessage="Search" />{' '}
              </Button>
            </form>
          </div>
          {this.state.allowSubsite ||
          (this.state.allowLocal &&
            getPathPrefix(this.props.history.location) !== undefined) ? (
            <LocalCheckbox
              allowSubsite={this.state.allowSubsite}
              onChange={(checked) => this.setLocal(checked)}
              checked={this.state.local}
            />
          ) : null}
          <SearchResultInfo
            searchableText={this.props.searchableText}
            total={this.props.total}
          />
          {this.props.total ? (
            <SearchTabs
              groupSelect={this.state.groupSelect}
              setGroupSelect={(groupSelect) => this.setGroupSelect(groupSelect)}
              groupCounts={this.props.groupCounts}
            />
          ) : null}
          {/* END CUSTOMIZATION */}
          <article id="content">
            <header>
              {/* START CUSTOMIZATION */}
              {this.props.total > 0 ? (
                <div className="sorting_bar">
                  <SelectSorting
                    value={this.state.active}
                    onChange={(selectedOption, order) => {
                      this.onSortChange(selectedOption, order);
                    }}
                  />
                  {/* END CUSTOMIZATION */}
                </div>
              ) : null}
            </header>
            <section id="content-core">
              {/* START CUSTOMIZATION */}
              <div>
                <Dimmer active={this.props.loading} inverted>
                  <Loader indeterminate size="small">
                    <FormattedMessage id="loading" defaultMessage="Loading" />
                  </Loader>
                </Dimmer>
              </div>
              {this.props.items?.map((item, index) => (
                <div key={'' + index + '-' + item['@id']}>
                  {createElement(resultTypeMapper(item['@type']), {
                    key: item['@id'],
                    item,
                    groupSelect: this.state.groupSelect,
                  })}
                </div>
              ))}
              {showPagination && (
                <div className="search-footer">
                  {/* END CUSTOMIZATION */}
                  <div className="pagination-wrapper">
                    <Pagination
                      className="desktop-pagination"
                      activePage={this.state.currentPage}
                      totalPages={Math.ceil(
                        /* START CUSTOMIZATION */
                        this.props.total / settings.defaultPageSize,
                        /* END CUSTOMIZATION */
                      )}
                      onPageChange={this.handleQueryPaginationChange}
                      firstItem={null}
                      lastItem={null}
                      prevItem={{
                        content: (
                          <FormattedMessage
                            id="Previous Page"
                            defaultMessage="Previous Page"
                          />
                        ),
                        icon: true,
                        /* START CUSTOMIZATION */
                        'aria-disabled': this.state.currentPage === 1,
                        className:
                          this.state.currentPage === 1 ? 'disabled' : null,
                        /* END CUSTOMIZATION */
                      }}
                      nextItem={{
                        content: (
                          <FormattedMessage
                            id="Next Page"
                            defaultMessage="Next Page"
                          />
                        ),
                        icon: true,
                        /* START CUSTOMIZATION */
                        'aria-disabled':
                          this.state.currentPage * settings.defaultPageSize >=
                          this.props.total,
                        className:
                          this.state.currentPage * settings.defaultPageSize >=
                          this.props.total
                            ? 'disabled'
                            : null,
                        /* END CUSTOMIZATION */
                      }}
                    />
                    <Pagination
                      className="mobile-pagination"
                      activePage={this.state.currentPage}
                      boundaryRange={1}
                      siblingRange={0}
                      totalPages={Math.ceil(
                        /* START CUSTOMIZATION */
                        this.props.total / settings.defaultPageSize,
                        /* END CUSTOMIZATION */
                      )}
                      onPageChange={this.handleQueryPaginationChange}
                      firstItem={null}
                      lastItem={null}
                      prevItem={undefined}
                      nextItem={undefined}
                    />
                  </div>
                </div>
              )}
            </section>
          </article>
        </Container>
        {this.state.isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              hideDefaultViewButtons
              inner={<span />}
            />
          </Portal>
        )}
      </Segment>
    );
  }
}

// START CUSTOMIZATION
// The xxxWithDefault functions are only to support the legacy search component.
const searchActionWithDefault = (searchAction) =>
  searchAction !== undefined ? searchAction : searchContent;
const getSearchReducerWithDefault = (state, { getSearchReducer }) =>
  getSearchReducer !== undefined ? getSearchReducer(state) : state.search;
const contentTypeSearchResultViewsWithDefault = (
  contentTypeSearchResultViews,
) =>
  contentTypeSearchResultViews !== undefined
    ? contentTypeSearchResultViews
    : {};
const contentTypeSearchResultDefaultViewWithDefault = (
  contentTypeSearchResultDefaultView,
) =>
  contentTypeSearchResultDefaultView !== undefined
    ? contentTypeSearchResultDefaultView
    : DefaultResultItem;

export const __test__ = connect(
  (state, props) => {
    const {
      items,
      total,
      loaded,
      loading,
      batching,
    } = getSearchReducerWithDefault(state, props);
    return {
      items,
      total,
      loaded,
      loading,
      batching,
      intl: state.intl,
      searchableText: qs.parse(props.history.location.search).SearchableText,
      pathname: props.history.location.pathname,
      contentTypeSearchResultViews: contentTypeSearchResultViewsWithDefault(
        props.contentTypeSearchResultViews,
      ),
      contentTypeSearchResultDefaultView: contentTypeSearchResultDefaultViewWithDefault(
        props.contentTypeSearchResultDefaultView,
      ),
      contentData: state.content?.data,
    };
  },
  (dispatch, { searchAction, copyContentForSolrAction }) => ({
    searchContent: (...args) =>
      dispatch(searchActionWithDefault(searchAction)(...args)),
    resetContent: (...args) => dispatch(resetContent(...args)),
    copyContentForSolr: (...args) =>
      copyContentForSolrAction && dispatch(copyContentForSolrAction(...args)),
  }),
)(Search);

export default compose(
  injectIntl,
  connect(
    (state, props) => {
      const {
        items,
        groupCounts,
        total,
        loaded,
        loading,
        batching,
      } = getSearchReducerWithDefault(state, props);
      return {
        items,
        groupCounts,
        total,
        loaded,
        loading,
        batching,
        intl: state.intl,
        searchableText: qs.parse(props.history.location.search).SearchableText,
        pathname: props.history.location.pathname,
        contentTypeSearchResultViews: contentTypeSearchResultViewsWithDefault(
          props.contentTypeSearchResultViews,
        ),
        contentTypeSearchResultDefaultView: contentTypeSearchResultDefaultViewWithDefault(
          props.contentTypeSearchResultDefaultView,
        ),
        contentData: state.content?.data,
        versionId:
          qs.parse(props.location.search) &&
          qs.parse(props.location.search).version,
      };
    },
    (dispatch, { searchAction, copyContentForSolrAction }) => ({
      searchContent: (...args) =>
        dispatch(searchActionWithDefault(searchAction)(...args)),
      resetContent: (...args) => dispatch(resetContent(...args)),
      getContent: (...args) => dispatch(getContent(...args)),
      copyContentForSolr: (...args) =>
        copyContentForSolrAction && dispatch(copyContentForSolrAction(...args)),
    }),
  ),
  asyncConnect([
    {
      key: 'solrsearch',
      promise: ({
        location,
        store: { dispatch },
        searchAction,
        intl: { locale },
      }) =>
        dispatch(
          searchActionWithDefault(searchAction)({
            ...qs.parse(location.search),
            path_prefix: getPathPrefix(location),
            lang: locale,
            // newest_boost is now always ON
            newest_boost: `true`,
          }),
        ),
    },
  ]),
)(Search);
// END CUSTOMIZATION

import React from 'react';
import { useSelector } from 'react-redux';
import { BodyClass } from '@plone/volto/helpers';
import { isEmpty } from 'lodash';
import config from '@plone/volto/registry';

const SubsiteTheming = () => {
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot);
  const isSubsite = !isEmpty(iNavRootInfo.subsite);
  const hasTheme = !isEmpty(iNavRootInfo.subsite.theme);
  const theme = iNavRootInfo.subsite.theme;

  return (
    <>
      {isSubsite ? (
        <>
          <BodyClass className={isSubsite ? 'is-subsite' : null}></BodyClass>
          <BodyClass
            className={hasTheme ? `theme--${theme}` : null}
          ></BodyClass>

          {hasTheme && (
            <>
              <style>{`:root {--custom-theme-color: ${config.settings.dlrSubsitesThemes[theme].color};}`}</style>
              {config.settings.dlrSubsitesThemes[theme].inverseFontColor && (
                <style>{`:root {--custom-theme-inverse-font-color: ${config.settings.dlrSubsitesThemes[theme].inverseFontColor};}`}</style>
              )}
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default SubsiteTheming;

import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Doormat = (props) => {
  // The navigation is fetched by the sections which is also always present, so no need
  // to fetch it again
  const navigation = useSelector((state) => state.navigation);
  const result = navigation?.items;

  return (
    <div className="doormat">
      <Grid stackable columns={props.columns}>
        {result &&
          result.map((section) => (
            <Grid.Column key={section.url}>
              <h2>{section.nav_title || section.title}</h2>
              <ul>
                {section.items &&
                  section.items.map((item) => (
                    <li key={item.url}>
                      <Link to={item.url}>{item.nav_title || item.title}</Link>
                    </li>
                  ))}
              </ul>
            </Grid.Column>
          ))}
      </Grid>
    </div>
  );
};

export default Doormat;

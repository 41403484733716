/**
 * OVERRIDE Logo.jsx
 * REASON: Make logo multi lingual
 * FILE: https://github.com/plone/volto/blob/ac2dc1ff18b0b4d5eeadce65cf0eaa6dd89d32f9/src/components/theme/Logo/Logo.jsx
 * FILE VERSION: Volto 16.0.0-alpha.31
 * DATE: 2022-09-14
 * DEVELOPER: @robgietema @iRohitSingh
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */

import { defineMessages, useIntl } from 'react-intl';
import { Image } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
// START CUSTOMIZATION
import LogoDe from './Logo-de.svg';
import LogoEn from './Logo-en.svg';
import LogoSimple from './LogoSimple.svg';
// END CUSTOMIZATION

const messages = defineMessages({
  plonesite: {
    id: 'Plone Site',
    defaultMessage: 'Plone Site',
  },
  portalLinkText: {
    id: 'German Aerospace Center (DLR) - Home',
    defaultMessage: 'German Aerospace Center (DLR) - Home',
  },
});

/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const Logo = ({ iNavRootInfo, isSubsite }) => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const intl = useIntl();
  // START CUSTOMIZATION
  const Logo = {
    de: LogoDe,
    en: LogoEn,
  };
  const logoWidth = isSubsite ? 82 : 310;
  const logoHeight = {
    de: 70,
    en: 93,
  };

  // END CUSTOMIZATION
  return (
    <UniversalLink
      href={
        isSubsite
          ? iNavRootInfo.subsite['@id']
          : settings.isMultilingual
          ? `/${lang}`
          : '/'
      }
      aria-label={
        isSubsite
          ? iNavRootInfo.subsite.name
          : intl.formatMessage(messages.portalLinkText)
      }
    >
      <Image
        // START CUSTOMIZATION
        src={isSubsite ? LogoSimple : Logo[lang]}
        width={logoWidth}
        height={logoHeight[lang]}
        loading="eager"
        decoding="sync"
        alt="DLR Logo"
        // END CUSTOMIZATION
      />
      <div className="logo-title">
        <div className="logo-textline-1">
          {iNavRootInfo.subsite.logo_textline_1}
        </div>
        <div className="logo-textline-2">
          {iNavRootInfo.subsite.logo_textline_2}
        </div>
      </div>
    </UniversalLink>
  );
};

export default Logo;

const buildUrl = (payload) => {
  // make sure external routes on localhost or plone-redaktion get redirected to www.dlr.de
  return `https://www.dlr.de${payload.location.pathname}`;
};

export const externalRoutes = [
  {
    title: 'DLR - Institut für Aerodynamik und Strömungstechnik',
    match: '/as',
  },
  { title: 'DLR - Institut für Planetenforschung', match: '/pf' },
  { title: 'DLR - Institut für Verkehrsforschung', match: '/vf' },
  { title: 'DLR - Institut für Softwaretechnologie', match: '/sc' },
  { title: 'DLR - Institut für Verkehrssystemtechnik', match: '/ts' },
  { title: 'DLR - Institut für Flugsystemtechnik', match: '/ft' },
  { title: 'DLR - Institut für Antriebstechnik', match: '/at' },
  { title: 'DLR - Institut für Aeroelastik', match: '/ae' },
  { title: 'DLR - Institut für Materialphysik im Weltraum', match: '/mp' },
  { title: 'DLR - Institut für Werkstoff-Forschung', match: '/wf' },
  { title: 'DLR - Institut für Luft- und Raumfahrtmedizin', match: '/me' },
  { title: 'DLR - Institut für Kommunikation und Navigation', match: '/kn' },
  { title: 'DLR - Institut für Verbrennungstechnik', match: '/vt' },
  {
    title: 'DLR - Institut für Hochfrequenztechnik und Radarsysteme',
    match: '/hr',
  },
  { title: 'DLR - Institut für Physik der Atmosphäre', match: '/pa' },
  {
    title: 'DLR - Institut für Bauweisen- und Konstruktionsforschung',
    match: '/bt',
  },
  { title: 'DLR - Raumflugbetrieb und Astronautentraining', match: '/rb' },
  { title: 'DLR - Institut für Technische Physik', match: '/tp' },
  { title: 'DLR - Institut für Fahrzeugkonzepte', match: '/fk' },
  { title: 'DLR - Institut für Technische Thermodynamik', match: '/tt' },
  { title: 'DLR - Projektträger im DLR', match: '/pt' },
  { title: 'DLR - Institut für Raumfahrtsysteme', match: '/irs' },
  { title: 'DLR - Institut für Optische Sensorsysteme', match: '/os' },
  {
    title: 'DLR - Projektträger Luftfahrtforschung und -technologie',
    match: '/pt-lf',
  },
  { title: 'DLR - Flugexperimente', match: '/fx' },
  { title: 'DLR - Institut für Raumfahrtantriebe', match: '/ra' },
  { title: 'Institut für Luftverkehr (LV)', match: '/lv' },
  { title: 'DLR - Earth Observation Center', match: '/eoc' },
  { title: 'DLR - DLR_next', match: '/next' },
  { title: 'DLR - Institut für Solarforschung', match: '/sf' },
  { title: 'DLR - Zentrum für Leichtbauproduktionstechnologie', match: '/zlp' },
  {
    title: 'DLR - Institut für Systemdynamik und Regelungstechnik',
    match: '/sr',
  },
  {
    title: 'DLR - Institut für den Test und Simulation für Gasturbinen',
    match: '/sg',
  },
  {
    title: 'DLR - Institut für den Schutz maritimer Infrastrukturen',
    match: '/mi',
  },
  {
    title: 'DLR - Institut für Softwaremethoden zur Produkt-Virtualisierung',
    match: '/sp',
  },
  { title: 'DLR - Institut für Datenwissenschaften ', match: '/dw' },
  { title: 'DLR - Institut für vernetzte Energiesysteme', match: '/ve' },
  {
    title: 'DLR - Institut für Systemarchitekturen in der Luftfahrt',
    match: '/sl',
  },
  {
    title: 'DLR - Institut für Instandhaltung und Modifikation',
    match: '/mo',
  },
  {
    title: 'DLR - Nationales Erprobungszentrum für unbemannte Luftfahrtsysteme',
    match: '/ux',
  },
  {
    title: 'DLR - Institut für CO2-arme Industrieprozesse',
    match: '/di',
  },
  {
    title: 'DLR - Institut für Satellitengeodäsie und Inertialsensorik',
    match: '/si',
  },
  {
    title: 'DLR - Institut für Solar-Terrestrische Physik',
    match: '/so',
  },
  { title: 'DLR - Galileo-Kompetenzzentrum', match: '/gk' },
  { title: 'DLR - Institut für Quantentechnologien', match: '/qt' },
  {
    title: 'DLR - Institut für den Schutz terrestrischer Infrastrukturen ',
    match: '/pi',
  },
  { title: 'DLR - Windenergiesysteme', match: '/wx' },
  { title: 'DLR - Institut für Maritime Energiesysteme', match: '/ms' },
  {
    title: 'DLR - Institut für Systems Engineering für zukünftige Mobilität',
    match: '/se',
  },
  { title: 'DLR - Institut für Future Fuels', match: '/ff' },
  { title: 'DLR - Technologien für Kleinflugzeuge', match: '/kf' },
  {
    title: 'DLR - Institut für Elektrifizierte Luftfahrtantriebe',
    match: '/el',
  },
  {
    title: 'DLR - Kompetenzzentrum für Unbemannte Luftfahrtsysteme',
    match: '/uc',
  },
  { title: 'DLR - Institut für KI-Sicherheit', match: '/ki' },
  {
    title: 'DLR - Kompetenzzentrum für Reaktionsschnelle Satellitenverbringung',
    match: '/rs',
  },
  { title: 'DLR - Institut für Systemleichtbau', match: '/sy' },
].map((route) => ({ ...route, url: buildUrl }));

/* Custom widget which replaces &shy; with a soft-hyphen
https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/2967
*/

import { TextWidget } from '@plone/volto/components';

const TitleWidget = (props) => {
  let { value, onChange } = props;
  value = (value || '').replaceAll('\u00ad', '&shy;');
  const onChangeWithReplacement = (id, value) => {
    onChange(
      id,
      value === undefined ? undefined : value.replaceAll('&shy;', '\u00ad'),
    );
  };
  return (
    <TextWidget {...props} value={value} onChange={onChangeWithReplacement} />
  );
};

export default TitleWidget;

import React from 'react';
import { useCookies } from 'react-cookie';
import PlaceholderImage from './video-placeholder.png';
import CookieConsentButtons from 'volto-dlr/components/CookieConsent/CookieConsentButtons';

const IfConfirm = ({ children, module, data, extraPlaceholder }) => {
  const [cookies] = useCookies();
  const previewImageObject = data.image_scales?.preview_image_link
    ? data.image_scales?.preview_image_link?.[0]
    : data.image_scales?.image?.[0];
  const placeholder = data?.image_scales ? (
    <img
      classname="placeholder-image"
      src={`${previewImageObject?.base_path}/${
        previewImageObject?.scales?.larger
          ? previewImageObject?.scales?.larger?.download
          : previewImageObject?.download
      }`}
      alt={data.title ?? 'placeholder'}
    />
  ) : (
    <img src={PlaceholderImage} alt={data.title ?? 'placeholder'} />
  );

  // We bail out if module is undefined, while this is most likely
  // a bug in the caller, there is nothing we can do here,
  // but we want to avoid a traceback.
  // See https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/1384
  if (!module || !!Number(cookies[`confirm_${module}`])) {
    return <>{children}</>;
  } else {
    return (
      <div className="cookie-consent-overlay">
        {placeholder}
        {extraPlaceholder}
        <CookieConsentButtons module={module} />
      </div>
    );
  }
};

export default IfConfirm;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { EffectiveDate } from '@kitconcept/volto-blocks/components';
import { formatMonthRange } from 'volto-dlr/helpers';

/**
 * Kicker (line above title) block component.
 * @class View
 * @extends Component
 */
const Kicker = ({ properties }) => {
  const lang = useSelector((state) => state.intl.locale);
  const type = properties['@type'];
  const headerSegments = [];
  if (type === 'News Item') {
    headerSegments.push(<EffectiveDate item={properties} />);
  }
  if (type === 'Research Project') {
    if (properties.start || properties.end) {
      headerSegments.push(
        <span>{formatMonthRange(lang, properties.start, properties.end)}</span>,
      );
    }
    if (properties.project_title) {
      headerSegments.push(<span>{properties.project_title}</span>);
    }
  }
  if (properties.head_title) {
    headerSegments.push(<span>{properties.head_title}</span>);
  }
  return headerSegments.length ? (
    <header className="head-title">
      {headerSegments.map((s, i) => (
        <React.Fragment key={i}>
          {s}
          {i + 1 < headerSegments.length ? ' | ' : ''}
        </React.Fragment>
      ))}
    </header>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Kicker.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Kicker;

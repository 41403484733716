/**
 * OVERRIDE ContentMetadataTags.jsx
 * REASON: The title of the tabs in edit mode should be different
 * FILE: https://github.com/plone/volto/blob/d3dac560d0919c5c255e26333a9872c61e417381/src/components/theme/ContentMetadataTags/ContentMetadataTags.jsx
 * FILE VERSION: Volto 16.0.0-alpha.29
 * DATE: 2022-09-06
 * DEVELOPER: @sneridagh
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
import React from 'react';
import { toPublicURL, Helmet } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';

const ContentMetadataTags = (props) => {
  const {
    opengraph_title,
    opengraph_description,
    seo_title,
    seo_description,
    seo_canonical_url,
    seo_noindex,
    title,
    description,
  } = props.content;
  const token = useSelector((state) => state.userSession.token);

  const getContentImageInfo = () => {
    const { contentMetadataTagsImageField } = config.settings;
    const image = props.content[contentMetadataTagsImageField];
    const preview_image = image?.image_scales?.image?.[0];

    const contentImageInfo = {
      contentHasImage: false,
      url: null,
      height: null,
      width: null,
    };

    if (preview_image) {
      contentImageInfo.contentHasImage = true;
      const scale = preview_image?.scales?.larger || preview_image;
      contentImageInfo.url = `${image['@id']}/${scale.download}`;
      contentImageInfo.height = scale.height;
      contentImageInfo.width = scale.width;
    }
    // END CUSTOMIZATION

    return contentImageInfo;
  };

  const contentImageInfo = getContentImageInfo();

  // START CUSTOMIZATION
  const editing_title = token ? `Plone CMS: ${seo_title || title}` : '';
  // END CUSTOMIZATION

  return (
    <>
      <Helmet>
        <title>
          {/* START CUSTOMIZATION */}
          {(editing_title || seo_title || title)?.replace(/\u00AD/g, '')}
          {/* END CUSTOMIZATION */}
        </title>
        <meta name="description" content={seo_description || description} />
        <meta
          property="og:title"
          content={opengraph_title || seo_title || title}
        />
        <meta
          property="og:url"
          content={seo_canonical_url || toPublicURL(props.content['@id'])}
        />
        {seo_noindex && <meta name="robots" content="noindex" />}
        {contentImageInfo.contentHasImage && (
          <meta
            property="og:image"
            content={toPublicURL(contentImageInfo.url)}
          />
        )}
        {contentImageInfo.contentHasImage && (
          <meta property="og:image:width" content={contentImageInfo.width} />
        )}
        {contentImageInfo.contentHasImage && (
          <meta property="og:image:height" content={contentImageInfo.height} />
        )}
        {(opengraph_description || seo_description || description) && (
          <meta
            property="og:description"
            content={opengraph_description || seo_description || description}
          />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta property="twitter:site" content={'@DLR_de'} /> */}
        <meta
          property="twitter:url"
          content={seo_canonical_url || toPublicURL(props.content['@id'])}
        />
        <meta
          property="twitter:title"
          content={opengraph_title || seo_title || title}
        />
        <meta
          property="twitter:description"
          content={seo_description || description}
        />
        <meta property="twitter:domain" content={config.settings.publicURL} />
      </Helmet>
    </>
  );
};

export default ContentMetadataTags;

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  AnchorBlock: {
    id: 'Anchor',
    defaultMessage: 'Anchor',
  },
  name: {
    id: 'ID',
    defaultMessage: 'ID',
  },
  description: {
    id: 'Only alphanumeric characters without blanks are allowed.',
    defaultMessage: 'Only alphanumeric characters without blanks are allowed.',
  },
});

export const AnchorBlockSchema = (props) => {
  const { intl } = props;
  return {
    title: props.intl.formatMessage(messages.AnchorBlock),
    block: 'anchorBlock',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['name'],
      },
    ],

    properties: {
      name: {
        title: intl.formatMessage(messages.name),
        widget: 'anchor',
        description: intl.formatMessage(messages.description),
      },
    },
    required: [],
  };
};

/** OVERRIDE View.jsx
 * REASON: the full-width variation go off the screen at some breakpoints.
 * FILE: https://github.com/plone/volto/blob/bd500cd42ba24aeb437560e8ced2334596996dfd/src/components/manage/Blocks/Maps/View.jsx
 * PULL REQUEST: https://github.com/kitconcept/dlr-internet/pull/255
 * TICKET: https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/11
 * DATE: 2022-08-01
 * DEVELOPER: @iRohitSingh
 * CHANGELOG:
 *  - Fix full-width Maps block @iRohitSingh
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
/**
/**
 * View map block.
 * @module components/manage/Blocks/Maps/View
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { withBlockExtensions } from '@plone/volto/helpers';
import { IfConfirm } from '@kitconcept/volto-dsgvo-banner/components';

/**
 * View image block class.
 * @class View
 * @extends Component
 */

const View = ({ data, intl }) => {
  if (!data.url) return null;
  let mapType = data.url?.includes('google.com/maps')
    ? 'gmaps'
    : data.url?.includes('openstreetmap.org')
    ? 'osm'
    : undefined;

  return (
    <div
      className={cx(
        'block maps align',
        {
          center: !Boolean(data.align),
        },
        data.align,
      )}
    >
      {/*  START CUSTOMIZATION */}
      <div className={cx('maps-inner')}>
        <IfConfirm module={mapType} data={data}>
          {/* END CUSTOMIZATION */}
          <iframe
            title={data.title}
            src={data.url}
            className="google-map"
            frameBorder="0"
            allowFullScreen
          />
        </IfConfirm>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(injectIntl, withBlockExtensions)(View);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Message } from 'semantic-ui-react';
import { Icon, UniversalLink } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import {
  unsubscribe,
  legacyUnsubscribe,
} from 'volto-dlr/actions/newsletter/newsletter';
import warningSVG from '@plone/volto/icons/warning.svg';

const messages = defineMessages({
  newsletterHref: {
    id: 'newsletterHref',
    defaultMessage: '/en/service/newsletter',
  },
  newsletterLinkText: {
    id: 'newsletterLinkText',
    defaultMessage: 'here',
  },
  socialHref: {
    id: 'socialHref',
    defaultMessage: '/en/latest/social-media',
  },
  socialLinkText: {
    id: 'socialLinkText',
    defaultMessage: 'social media channels',
  },
  MissingSubscriptionID: {
    id: 'MissingSubscriptionID',
    defaultMessage: 'Subscription ID not found.',
  },
  ConfirmNewsletterUnsubscribe: {
    id: 'ConfirmNewsletterUnsubscribe',
    defaultMessage:
      'You would like to unsubscribe from our {newsletter}? Then simply confirm by clicking OK.',
  },
  ConfirmUnsubscribe: {
    id: 'ConfirmUnsubscribe',
    defaultMessage:
      'You would like to unsubscribe from our newsletter. Then simply confirm by clicking OK.',
  },
});

const NewsletterUnsubscribeForm = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;

  useEffect(() => {
    dispatch(getContent(getBaseUrl(pathname)));
  }, [dispatch, pathname]);

  const content = useSelector((state) => state.content.data);

  const [state, setState] = React.useState({
    loading: false,
    success: false,
    serverError: null,
  });

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (state.loading) return;
    setState((state) => {
      return { ...state, loading: true, serverError: null };
    });

    const params = new URLSearchParams(window.location.search);
    if (params.get('s')) {
      dispatch(unsubscribe(content['@id'], params.get('s')))
        .then(() => setState({ success: true, loading: false }))
        .catch((error) => setState({ serverError: error, loading: false }));
    } else if (params.get('u')) {
      // handle old unsubscribe links from CXXL
      dispatch(legacyUnsubscribe(params.get('u')))
        .then(() => setState({ success: true, loading: false }))
        .catch((error) => setState({ serverError: error, loading: false }));
    } else {
      setState({
        serverError: {
          response: {
            body: {
              message: intl.formatMessage(messages.MissingSubscriptionID),
            },
          },
        },
      });
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    history.push({ pathname: '/' });
  };

  return (
    <div
      className="contact-form-overlay"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      role="presentation"
    >
      <h1>
        <FormattedMessage
          id="NewsletterUnsubscribe"
          defaultMessage="DLR newsletter cancellation"
        />
      </h1>
      {state.success ? (
        <fieldset>
          <legend className="sent">
            <FormattedMessage
              id="UnsubscribedMessage"
              defaultMessage="Your cancellation was successful."
            />
          </legend>
          <p>
            <FormattedMessage
              id="ContinueNews"
              defaultMessage="Would you like to continue receiving news from DLR? How about simply following us on our {social_link}."
              values={{
                newsletter_link: (
                  <UniversalLink
                    href={intl.formatMessage(messages.newsletterHref)}
                  >
                    {intl.formatMessage(messages.newsletterLinkText)}
                  </UniversalLink>
                ),
                social_link: (
                  <UniversalLink href={intl.formatMessage(messages.socialHref)}>
                    {intl.formatMessage(messages.socialLinkText)}
                  </UniversalLink>
                ),
              }}
            />
          </p>
        </fieldset>
      ) : (
        <Form
          noValidate
          className="contact-form-overlay"
          onSubmit={onSubmitHandler}
        >
          <fieldset>
            <Form.Field>
              {intl.formatMessage(
                content?.['@type'] === 'Newsletter'
                  ? messages.ConfirmNewsletterUnsubscribe
                  : messages.ConfirmUnsubscribe,
                { newsletter: content?.title },
              )}
            </Form.Field>
            <Form.Group>
              <Form.Button primary name="submit" loading={state.loading}>
                OK
              </Form.Button>
              <Button loading={state.loading} onClick={handleCancel}>
                <FormattedMessage id="Cancel" defaultMessage="Cancel" />
              </Button>
            </Form.Group>
            {state.serverError && (
              <Message negative>
                <div className="icon-container-warning">
                  {' '}
                  <Icon name={warningSVG} size="50px" color="red" />
                </div>
                <Message.Header>
                  <FormattedMessage id="Error" defaultMessage="Error" />
                </Message.Header>
                <Message.Content>
                  {state.serverError.response?.body?.message}
                </Message.Content>
              </Message>
            )}
          </fieldset>
        </Form>
      )}
    </div>
  );
};

export default NewsletterUnsubscribeForm;

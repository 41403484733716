import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { EffectiveDate } from '@kitconcept/volto-blocks/components';
import { Grid } from 'semantic-ui-react';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { PreviewImg } from 'volto-dlr/helpers';

const NewsGridListingTemplate = ({ items, linkMore, isEditMode }) => {
  let link = null;
  let href = linkMore?.href || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink
        to={flattenToAppURL(href)}
        condition={!isEditMode}
        className="ui button"
      >
        {linkMore?.title || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkMore?.title || href}</a>;
  }

  return (
    <>
      <div className="news-grid-listing-template">
        <div className="items">
          <Grid stackable columns={2}>
            {items.map((item) => (
              <Grid.Column key={item.UID}>
                <div className="event-item">
                  <div className="content">
                    <div className="listing-item " key={item['@id']}>
                      <div className="text">
                        <ConditionalLink
                          to={flattenToAppURL(item['@id'])}
                          condition={!isEditMode}
                        >
                          <div className="grid-image-wrapper">
                            <PreviewImg
                              previewImage={item}
                              alt={item.title}
                              blurhashOptions={{
                                style: {
                                  width: '100%',
                                  height: 'auto',
                                  alignSelf: 'center',
                                },
                              }}
                              targetWidth="half"
                            />
                          </div>
                          <div className="listing-body">
                            <div className="head-title">
                              <EffectiveDate item={item} />{' '}
                            </div>
                            <h2>{item.title ? item.title : item.id}</h2>
                            <p className="description">{item?.description}</p>
                          </div>
                        </ConditionalLink>
                        {item?.subjects && item.subjects?.length >= 1 ? (
                          <div className="tags">
                            {item.subjects.map((item, i) => {
                              return (
                                <ConditionalLink
                                  to={`/search?Subject=${item}`}
                                  condition={!isEditMode}
                                >
                                  {item}
                                </ConditionalLink>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            ))}
          </Grid>
        </div>

        {link && <div className="block-footer">{link}</div>}
      </div>
    </>
  );
};
NewsGridListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default NewsGridListingTemplate;

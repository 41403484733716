import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, Container, Segment, Dropdown } from 'semantic-ui-react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { getBreadcrumbs } from '@plone/volto/actions';
import { hasApiExpander } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';
import homeSVG from '@plone/volto/icons/home.svg';
import tripleDotSVG from '../../../icons/triple-dot.svg';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
  closeSearch: {
    id: 'Close search',
    defaultMessage: 'Close search',
  },
});

const BackFromSearch = ({ pathname }) => {
  return (
    <Container className="back-row">
      <Link to={pathname}>
        <span className="back">
          <i>
            <Icon name={clearSVG} size="20px" />
          </i>
          <FormattedMessage {...messages.closeSearch} />
        </span>
      </Link>
    </Container>
  );
};

const SimpleBreadcrumbs = ({ items, isSearchPage }) => {
  return (
    <div className="simple-breadcrumb">
      {items.map((item, index, items) => [
        <Breadcrumb.Divider key={`divider-${item.url}`}>
          &gt;
        </Breadcrumb.Divider>,
        isSearchPage || index < items.length - 1 ? (
          <Link key={item.url} to={item.url} className="section">
            {item.title}
          </Link>
        ) : (
          <Breadcrumb.Section key={item.url} active>
            {item.title}
          </Breadcrumb.Section>
        ),
      ])}
    </div>
  );
};

const MobileBreadcrumbs = ({ items, isSearchPage }) => {
  return items.length > 2 ? (
    [
      <Breadcrumb.Divider>&gt;</Breadcrumb.Divider>,
      <>
        <Dropdown
          pointing="top left"
          icon={false}
          trigger={
            <div>
              {' '}
              <Icon
                style={{ paddingTop: '3px' }}
                name={tripleDotSVG}
                size="20px"
              />
            </div>
          }
        >
          <Dropdown.Menu>
            {items.slice(0, items.length - 2).map((item, index, items) => [
              <Dropdown.Item>
                <Link key={item.url} to={item.url} className="section dropdown">
                  <span> {item.title}</span>
                  <Breadcrumb.Divider>&gt;</Breadcrumb.Divider>
                </Link>
              </Dropdown.Item>,
            ])}
          </Dropdown.Menu>
        </Dropdown>
      </>,
      items.slice(items.length - 2, items.length).map((item, index, items) => [
        <Breadcrumb.Divider key={`divider-${item.url}`}>
          &gt;
        </Breadcrumb.Divider>,
        index < items.length - 1 ? (
          <Link key={item.url} to={item.url} className="section">
            {item.title}
          </Link>
        ) : (
          <Breadcrumb.Section key={item.url} active>
            {item.title}
          </Breadcrumb.Section>
        ),
      ]),
    ]
  ) : (
    <SimpleBreadcrumbs items={items} isSearchPage={isSearchPage} />
  );
};

const Breadcrumbs = ({ pathname }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // pathname is resetted for non-content routes already,
  // so to test for a search page we need to reclaim the original.
  const unmodifiedPathname = useLocation().pathname;
  const isSearchPage = unmodifiedPathname.match(/(.*\/)(@@)+search$/);
  if (isSearchPage) {
    // Despite being a non-content route, we want the full url for search pages.
    pathname = isSearchPage[1];
  }

  useEffect(() => {
    if (!hasApiExpander('breadcrumbs', pathname)) {
      dispatch(getBreadcrumbs(pathname));
    }
  }, [dispatch, pathname]);

  const items = useSelector((state) => state.breadcrumbs.items);
  const root = useSelector((state) => state.breadcrumbs.root);

  return (
    <Segment
      role="navigation"
      aria-label={intl.formatMessage(messages.breadcrumbs)}
      className="breadcrumbs"
      secondary
      vertical
    >
      {' '}
      {isSearchPage ? (
        <BackFromSearch pathname={pathname} />
      ) : (
        <Container>
          <Breadcrumb className="mobile-breadcrumb">
            <Link
              to={root || '/'}
              className="section"
              title={intl.formatMessage(messages.home)}
            >
              <Icon name={homeSVG} size="18px" />
            </Link>
            <MobileBreadcrumbs items={items} isSearchPage={isSearchPage} />
          </Breadcrumb>
          <Breadcrumb className="desktop-breadcrumb">
            <Link
              to={root || '/'}
              className="section"
              title={intl.formatMessage(messages.home)}
            >
              {intl.formatMessage(messages.home)}
            </Link>

            <SimpleBreadcrumbs items={items} isSearchPage={isSearchPage} />
          </Breadcrumb>
        </Container>
      )}
    </Segment>
  );
};

export default Breadcrumbs;

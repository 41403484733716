import React from 'react';
import { AudioBlockSchema } from 'volto-dlr/components/Blocks/Audio/schema';
import { BlockDataForm } from '@plone/volto/components';
import { Segment } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import audioSVG from '@plone/volto/icons/audio.svg';

const messages = defineMessages({
  Audio: {
    id: 'Audio',
    defaultMessage: 'Audio',
  },
  NoAudio: {
    id: 'No Audio selected',
    defaultMessage: 'No Audio selected',
  },
});

const AudioSidebar = (props) => {
  const { data, block, onChangeBlock, contentType, navRoot } = props;
  const intl = useIntl();
  const schema = AudioBlockSchema({ ...props, intl });

  return (
    <>
      {!data.url ? (
        <Segment className="sidebar-metadata-container" secondary>
          {props.intl.formatMessage(messages.NoAudio)}
          <Icon name={audioSVG} size="100px" color="#b8c6c8" />
        </Segment>
      ) : (
        <BlockDataForm
          schema={schema}
          title={intl.formatMessage(messages.Audio)}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
          navRoot={navRoot}
          contentType={contentType}
        />
      )}
    </>
  );
};

export default AudioSidebar;

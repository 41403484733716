import React from 'react';
import PropTypes from 'prop-types';
import { BlockDataForm } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ContactListSchema } from './schema';

const ContactListData = (props) => {
  const { block, blocksConfig, data, onChangeBlock, contentType } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const schema = ContactListSchema({ ...props, intl });
  const dataAdapter = blocksConfig[data['@type']].dataAdapter;
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot.subsite);

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        dataAdapter({
          block,
          data,
          id,
          onChangeBlock,
          value,
          dispatch,
        });
      }}
      block={block}
      formData={data}
      onChangeBlock={onChangeBlock}
      navRoot={iNavRootInfo}
      contentType={contentType}
    />
  );
};

ContactListData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default ContactListData;

import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Container } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '@plone/volto/actions';
import {
  resetBreadcrumbs,
  resetNavigation,
} from 'volto-dlr/actions/content/resets';
import { normalizeLanguageName } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isEmpty } from 'lodash';
import languages from '@plone/volto/constants/Languages';

const TranslationNotFound = (props) => {
  const language = props.location?.state?.language;
  const { root: root_translations } = useSelector(
    (state) => state.content.data?.['@components']?.translations || {},
  );
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot);
  const isSubsite = !isEmpty(iNavRootInfo.subsite);

  const dispatch = useDispatch();
  const currentNavRootURL =
    flattenToAppURL(root_translations?.[language]) || `/${language}`;
  // debugger;
  let history = useHistory();

  useEffect(() => {
    // Note, historically we also switched to config.settings.defaultLanguage here but we can't find the reason why.
    // However this is removed because it does not let us switch the language of
    // this page from the language switch links.
    if (language !== undefined) {
      const langFileName = normalizeLanguageName(language);
      import('@root/../locales/' + langFileName + '.json').then((locale) => {
        dispatch(changeLanguage(language, locale.default));
      });
    }
    dispatch(resetNavigation());
    dispatch(resetBreadcrumbs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="translation-not-found">
      <h1 className="documentFirstHeading">
        <FormattedMessage
          id="No English content found"
          defaultMessage="No English content found"
        />
      </h1>
      <p>
        <FormattedMessage
          id="Unfortunately, this content is not available in English. You have the following options:"
          defaultMessage="Unfortunately, this content is not available in English. You have the following options:"
        />
      </p>
      <p>
        <Button as={'a'} onClick={() => history.goBack()}>
          <FormattedMessage id="Navigate back" defaultMessage="Navigate back" />
        </Button>
      </p>
      <p>
        <Link to={currentNavRootURL}>
          <FormattedMessage
            id="Navigate to the {language} site root"
            defaultMessage="Navigate to the {language} site root"
            values={{ language: <strong>{languages[language]}</strong> }}
          />
        </Link>
      </p>
      <p>
        <Link
          to={
            !isSubsite
              ? `/${language}/search`
              : `${currentNavRootURL}/@@search/?allow_local=true`
          }
        >
          <FormattedMessage
            id="Navigate to the {language} language search page"
            defaultMessage="Navigate to the {language} language search page"
            values={{ language: <strong>{languages[language]}</strong> }}
          />
        </Link>
      </p>
    </Container>
  );
};

export default TranslationNotFound;

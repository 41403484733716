import { compact, isEmpty } from 'lodash';
import { difference, replaceItemOfArray } from '@plone/volto/helpers';

export const ImageSliderDataAdapter = ({
  block,
  data,
  id,
  onChangeBlock,
  value,
}) => {
  let dataSaved = {
    ...data,
    [id]: value,
  };

  // This code is for sync the value coming from the selected content
  // into the block's data (if the title and description are empty)
  if (id === 'slides' && !isEmpty(value)) {
    const diff = difference(value, data.slides);
    // If we are moving two items in the array, the changeset is > 1
    // then we do not do any sync
    const isReordering = compact(diff).length > 1;
    if (!isReordering) {
      const index = diff.findIndex((val) => val);
      if (diff[index]?.href?.[0]) {
        dataSaved = {
          ...dataSaved,
          slides: replaceItemOfArray(value, index, {
            ...value[index],
            title: diff[index].href[0].Title,
            description: diff[index].href[0].Description,
          }),
        };
      }
    }
  }
  onChangeBlock(block, dataSaved);
};

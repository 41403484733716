import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import ContactDetail from './Detail';
import ContactForm from './Form';

const messages = defineMessages({
  Contact: {
    id: 'contactButtonLabel',
    defaultMessage: 'Contact',
  },
});

const ContactBody = (props) => {
  const intl = useIntl();
  const contact =
    props.data.href?.length > 0 ? props.data.href?.[0] : props.data;
  const { content } = props;

  const [open, setOpen] = useState(false);

  return contact ? (
    <div className="contact-detail">
      <ContactDetail contact={contact} />
      {contact.has_email ? (
        <Modal
          className="contact-form-modal"
          closeIcon
          closeOnEscape={false}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          trigger={
            <Button className="button">
              {intl.formatMessage(messages.Contact)}
            </Button>
          }
          size="fullscreen"
          centered={false}
          role="dialog"
          aria-labelledby="contact-form-modal-heading"
        >
          {open && (
            <Modal.Content scrolling>
              <ContactForm
                contact={contact}
                context={content}
                onCancel={() => setOpen(false)}
              ></ContactForm>
            </Modal.Content>
          )}
        </Modal>
      ) : null}
    </div>
  ) : (
    ''
  );
};

export default ContactBody;
